import axios from 'axios';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import cogoToast from 'cogo-toast';
import { AsyncDispatch } from '@redux/types';
import classNames from 'classnames';

import BackButton from '@components/BackButton';
import { createGoGymSubscription } from '@redux/modules/subscriptions/actions';
import { DownloadButton } from '@components/DownloadButton';
import { getGymsList } from '@redux/modules/gyms/selectors';
import { fetchGymsList } from '@redux/modules/gyms/actions';
import Button from '@components/Button';
import Modal from '@components/Modal';
import { useAddGoGymSubscription } from '@containers/Dashboard/AddGoGymSubscription/useAddGoGymSubscription';

import Form, { AddGoGymSubscriptionFormData } from './Form';
import styles from './styles.module.css';

export const AddGoGymSubscription: React.FC = () => {
    const dispatch = useDispatch<AsyncDispatch>();
    const history = useHistory();
    const gymsList = useSelector(getGymsList);

    const { onUploadFile } = useAddGoGymSubscription();

    const subscriptionIconFileRef = useRef<null>(null);
    const subscriptionAdvantagesIconFileRef = useRef({});
    const [cancelModalControls, setCancelModalControls] = useState({
        active: false,
    });

    useEffect(() => {
        dispatch(fetchGymsList());
    }, [dispatch]);

    const initialValues: AddGoGymSubscriptionFormData = useMemo(() => {
        return {
            active: false,
            activeForProlongation: false,
            isAdmin: false,
            title: '',
            titleDescription: '',
            description: '',
            iconUrl: '',
            additionalDescription: '',
            pricePerMonth: '',
            gyms: [],
            gymsExclusionList: [],
            minPerMinPrice: '',
            maxPerMinPrice: '',
            workoutType: '',
            advantages: [],
        };
    }, []);

    const onSubmit = useCallback(
        (values) => {
            const iconFiles = (
                subscriptionIconFileRef.current || { files: new FileList() }
            ).files;
            const advantagesIconFiles =
                subscriptionAdvantagesIconFileRef.current;
            const advantagesIconFilesValues: any[] = Object.values(
                advantagesIconFiles
            ).filter((input) => input);

            const iconsFormData = new FormData();

            if (iconFiles?.length) {
                for (let k = 0; k < iconFiles.length; ++k) {
                    iconsFormData.append('icons', iconFiles[k]);
                }
            }

            if (advantagesIconFilesValues.length) {
                for (let i = 0; i < advantagesIconFilesValues.length; ++i) {
                    for (
                        let j = 0;
                        j < advantagesIconFilesValues[i].files.length;
                        ++j
                    ) {
                        iconsFormData.append(
                            'advantagesIcons',
                            advantagesIconFilesValues[i].files[j]
                        );
                    }
                }
            }

            dispatch(
                createGoGymSubscription({
                    values,
                    iconsFormData,
                })
            )
                .then(() => {
                    cogoToast.success('Подписка создана', {
                        position: 'top-right',
                        hideAfter: 5,
                    });
                    history.push('/dashboard/gogym-subscriptions');
                })
                .catch(() => {
                    cogoToast.error('Ошибка создания подписки', {
                        position: 'top-right',
                        hideAfter: 4,
                    });
                });
        },
        [dispatch, history, subscriptionIconFileRef]
    );

    const onCancel = useCallback(() => {
        setCancelModalControls({
            active: true,
        });
    }, [dispatch]);

    const onConfirmCancel = useCallback(() => {
        setCancelModalControls({
            active: false,
        });
        history.push('/dashboard/gogym-subscriptions');
    }, [history]);

    const onConfirmAbandonCancel = useCallback(() => {
        setCancelModalControls({
            active: false,
        });
    }, []);

    const onDownloadInstruction = useCallback(() => {
        const fileName = 'create_update_subscription_instruction.pdf';
        axios
            .get(`https://api.gogym.fit/s3-gogym/${fileName}`, {
                responseType: 'arraybuffer',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/pdf',
                },
            })
            .then((response) => {
                const url = window.URL.createObjectURL(
                    new Blob([response.data])
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                link.remove();
                window.URL.revokeObjectURL(url);
            })
            .catch(() => {
                cogoToast.error('Ошибка при загрузке инструкции', {
                    position: 'top-right',
                    hideAfter: 4,
                });
            });
    }, []);

    const workoutTypesOptions = [
        {
            label: 'Поминутная тренировка',
            value: 'PER_MIN_WORKOUT',
        },
    ];

    const gymOptions = gymsList.reduce<{ label: string; value: string }[]>(
        (acc, gym) => {
            if (gym.hasGroupWorkouts) {
                acc.push({
                    label: gym.title,
                    value: gym._id,
                });
            }
            return acc;
        },
        []
    );

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <div className={styles.headerColumn}>
                    <BackButton
                        title="К списку подписок"
                        className={styles.backBtn}
                        onClick={onCancel}
                    />
                </div>
                <div className={styles.headerColumn}>
                    <DownloadButton
                        title="Инструкция по заведению подписки"
                        onClick={onDownloadInstruction}
                    />
                </div>
            </div>

            <Form
                initialValues={initialValues}
                subscriptionIconFileRef={subscriptionIconFileRef}
                subscriptionAdvantagesIconFileRef={
                    subscriptionAdvantagesIconFileRef
                }
                onUploadFile={onUploadFile}
                onSubmit={onSubmit}
                onCancel={onCancel}
                gymOptions={gymOptions}
                workoutTypesOptions={workoutTypesOptions}
            />
            <Modal
                active={cancelModalControls.active}
                setActive={(prev) =>
                    setCancelModalControls((prevState) => ({
                        ...prevState,
                        active: prev,
                    }))
                }
            >
                <div className={styles.modalContainer}>
                    <h2>Внимание!</h2>
                    <p>
                        Создание подписки не завершено. После закрытия <br />
                        страницы все введенные данные будут утеряны.
                    </p>
                    <div className={styles.modalActionControls}>
                        <Button
                            className={styles.modalActionBtn}
                            onClick={onConfirmCancel}
                            buttonType={'primary'}
                            type={'button'}
                        >
                            Все равно закрыть
                        </Button>
                        <Button
                            className={classNames(
                                styles.modalActionBtn,
                                styles.modalActionBtnSecondary
                            )}
                            onClick={onConfirmAbandonCancel}
                            buttonType={'primary'}
                            type={'button'}
                        >
                            Вернуться к редактированию
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default AddGoGymSubscription;
