import { Booking } from './booking';
import { Membership } from './membership';

export interface Report {
    _id: string;
    gymId: string;
    gymTitle: string;
    managerId: string;
    year: number;
    month: number;
    date: Date;
    bookings: Array<Booking>;
    memberships: Array<Membership>;
    totalPrice: number;
    totalAmount: number;
    totalAmountMemberships: number;
    goGymFee: number;
    status: string;
    paid: boolean;
}

export interface PartnerReport {
    _id: string;
    managerId: string;
    file: string;
    gymsId: string[];
    year: number;
    month: number;
    totalAmount: number;
}

export enum ReportStatus {
    NewReport = 'Новый отчет',
    ReportConfirmed = 'Отчет подтвержден',
    BillPaid = 'Счет оплачен',
}

export const monthNames = [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь',
];

export const getYearsOptions = (withNextYear = false) => {
    const options = [
        { title: '2021', value: 2021 },
        { title: '2022', value: 2022 },
        { title: '2023', value: 2023 },
        { title: '2024', value: 2024 },
    ];

    const nextYear = new Date().getFullYear() + 1;
    if (withNextYear && !options.find(({ value }) => value === nextYear)) {
        options.push({
            title: String(nextYear),
            value: nextYear,
        });
    }

    return options;
};

export const MonthsOptions = [
    { title: 'Январь', value: 0 },
    { title: 'Февраль', value: 1 },
    { title: 'Март', value: 2 },
    { title: 'Апрель', value: 3 },
    { title: 'Май', value: 4 },
    { title: 'Июнь', value: 5 },
    { title: 'Июль', value: 6 },
    { title: 'Август', value: 7 },
    { title: 'Сентябрь', value: 8 },
    { title: 'Октябрь', value: 9 },
    { title: 'Ноябрь', value: 10 },
    { title: 'Декабрь', value: 11 },
];

export const ReportTypeOptions = [
    { title: 'Тренировки', value: 'BOOKING' },
    { title: 'Абонементы', value: 'MEMBERSHIP' },
];
