import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cogoToast from 'cogo-toast';
import { useHistory } from 'react-router-dom';
import classnames from 'classnames';

import { fetchReportsBookings } from '@redux/modules/reports/actions';
import Box from '@components/Box';
import { getBookingsReports } from '@redux/modules/reports/selectors';
import Loader from '@components/Loader';
import { AsyncDispatch } from '@redux/types';
import ManagerFilterForm from '@containers/Dashboard/ManagerFilterForm';
import styles from './styles.module.css';
import { fetchManagersList } from '@redux/modules/managers/actions';
import {
    getManagersList,
    getSelectedManagerId,
} from '@redux/modules/managers/selectors';
import BoxButton from '@components/BoxButton';
import { getUser } from '@redux/modules/auth/selectors';
import { UserRole } from '@t/user';
import { getGymsList } from '@redux/modules/gyms/selectors';

import { ReportsListTable } from './ReportListTable';

const subjectText =
    'Запрос к бухгалтерии [Наименование организации] [ИНН организации]';

const InfoText = React.memo(() => (
    <div className={styles.reportListBookingsInfoTextContainer}>
        <div>Уважаемые партнёры</div>
        <div>
            Для получения закрывающих документов, актов сверки, платежных
            поручений и иной документации необходимо отправить запрос на почту
            info@gogym.fit.{' '}
            <a
                className={styles.reportListBookingsLink}
                href={`mailto:info@gogym.fit?subject=${subjectText}`}
            >
                В теме письма просьба указать: «Запрос к бухгалтерии
                [Наименование организации] [ИНН организации]»
            </a>
        </div>
    </div>
));

export const ReportListBookings: React.FC = () => {
    const dispatch = useDispatch<AsyncDispatch>();
    const history = useHistory();
    const reports = useSelector(getBookingsReports);
    const managersList = useSelector(getManagersList);
    const selectedManagerId = useSelector(getSelectedManagerId);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const user = useSelector(getUser);
    const isSuperAdmin = user?.role === UserRole.SuperAdmin;
    const hasMemberships =
        useSelector(getGymsList).filter(
            (gym) => !gym.isHidden && gym.memberships && gym.memberships.length
        ).length > 0;

    useEffect(() => {
        if (!reports.length) setIsLoading(true);

        if (!managersList.length && isSuperAdmin) dispatch(fetchManagersList());
        dispatch(fetchReportsBookings(selectedManagerId))
            .catch(() => {
                cogoToast.error('Ошибка при загрузке отчетов', {
                    position: 'top-right',
                    hideAfter: 4,
                });
            })
            .finally(() => setIsLoading(false));
    }, [dispatch, selectedManagerId]);

    const managers = managersList.map((manager) => ({
        label: manager.email,
        value: manager._id,
    }));

    const goToGoReportsBookings = useCallback(
        () => history.push('/dashboard/reports-bookings'),
        [history]
    );

    const goToGoReportsMemberships = useCallback(
        () => history.push('/dashboard/reports-memberships'),
        [history]
    );

    return (
        <div>
            <div
                className={classnames(
                    styles.reportListBookingsHeaderContainer,
                    {
                        [styles.reportListBookingsHeaderContainerEmpty]: !isSuperAdmin,
                    }
                )}
            >
                <div className={styles.reportListBookingsHeader}>
                    {isSuperAdmin && <ManagerFilterForm managers={managers} />}
                </div>
                <InfoText />
            </div>
            <h1>Отчеты</h1>

            <BoxButton
                icon=""
                title="Тренировки"
                onClick={goToGoReportsBookings}
                className={styles.actionNav}
            />

            {hasMemberships && (
                <BoxButton
                    icon=""
                    title="Абонементы"
                    onClick={goToGoReportsMemberships}
                />
            )}

            {isLoading && <Loader />}

            {!isLoading && reports.length > 0 && (
                <Box>
                    <div className={styles.tableWrapper}>
                        <ReportsListTable reports={reports} />
                    </div>
                </Box>
            )}

            {!isLoading && !reports.length && (
                <div className={styles.noReports}>Отчетов не найдено</div>
            )}
        </div>
    );
};

export default ReportListBookings;
