import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { FormStateMap } from 'redux-form';

import { GymState } from '@redux/modules/gyms/types';
import { BookingState } from '@redux/modules/bookings/types';
import { AuthState } from '@redux/modules/auth/types';
import { ReservationsState } from '@redux/modules/reservations/types';
import { FilterState } from '@redux/modules/dashboard/types';
import { GroupWorkoutsState } from '@redux/modules/group-workouts/types';
import {
    GymMembershipState,
    MembershipState,
} from '@redux/modules/memberships/types';
import { ManagerState } from '@redux/modules/managers/types';
import { LegalEntityState } from '@redux/modules/legal-entity/types';
import { ReportsState } from '@redux/modules/reports/types';
import { AppUserState } from '@redux/modules/app-users/types';
import { CouponState } from '@redux/modules/coupons/types';
import { ContactsState } from '@redux/modules/contacts/types';
import { TagCategorieState, TagState } from '@redux/modules/tags/types';
import {
    SubscriptionState,
    UserSubscriptionState,
} from './modules/subscriptions/types';
import { ConfigState } from './modules/config/types';

export enum FormName {
    AccountingReport = 'accountingReport',
    PartnerActionsForm = 'partnerActionsForm',
    BookingsReport = 'bookingsReport',
    MembershipsReport = 'membershipsReport',
}

export type ApplicationState = {
    auth: AuthState;
    gyms: GymState;
    bookings: BookingState;
    memberships: MembershipState;
    gymMemberships: GymMembershipState;
    form: FormStateMap & Record<FormName, any>;
    reservations: ReservationsState;
    dashboard: FilterState;
    groupWorkouts: GroupWorkoutsState;
    managers: ManagerState;
    legalentitys: LegalEntityState;
    reports: ReportsState;
    appUsers: AppUserState;
    coupons: CouponState;
    contacts: ContactsState;
    tags: TagState;
    tagCategories: TagCategorieState;
    subscriptions: SubscriptionState;
    userSubscriptions: UserSubscriptionState;
    config: ConfigState;
};

export type ReduxAction<P = any> = Action<string> & { payload?: P };

export interface AsyncActionCreator<P = any, R = any> {
    (params: P): ThunkAction<Promise<R>, ApplicationState, any, ReduxAction>;
}

export type AsyncDispatch = ThunkDispatch<ApplicationState, any, ReduxAction>;

export type Awaited<T> = T extends PromiseLike<infer U> ? U : T;
