import axios from 'axios';
import qs from 'qs';
import moment from 'moment';
import cogoToast from 'cogo-toast';

import { FormData as UpdateGymFormData } from '@containers/Dashboard/GymDetails/Form';
import { GroupWorkoutFormData as UpdateGroupWorkoutFormData } from '@containers/Dashboard/GroupWorkoutForm';
import { SpecialPriceFormData } from '@containers/Dashboard/GymList/AddSpecialPrice/Form';
import { API_URL, B2B_API_URL } from '@config';
import { City, GroupWorkout, Gym, TableTentType, WeekDay } from '@t/gym';
import { AsyncActionCreator, AsyncDispatch, ReduxAction } from '@redux/types';

import { signOut } from '../auth/actions';
import {
    FETCH_GYMS_ERROR,
    FETCH_GYMS_REQUEST,
    FETCH_GYMS_SUCCESS,
    CREATE_GYM_REQUEST,
    CREATE_GYM_ERROR,
    CREATE_GYM_SUCCESS,
    DELETE_GYM_REQUEST,
    DELETE_GYM_ERROR,
    DELETE_GYM_SUCCESS,
    FETCH_GYM_REQUEST,
    FETCH_GYM_SUCCESS,
    FETCH_GYM_ERROR,
    UPDATE_GYM_REQUEST,
    UPDATE_GYM_ERROR,
    UPDATE_GYM_SUCCESS,
    UPDATE_MANAGER_GYM_REQUEST,
    UPDATE_MANAGER_GYM_ERROR,
    UPDATE_MANAGER_GYM_SUCCESS,
    CREATE_GROUP_WORKOUT_REQUEST,
    CREATE_GROUP_WORKOUT_SUCCESS,
    CREATE_GROUP_WORKOUT_ERROR,
    UPDATE_GROUP_WORKOUT_REQUEST,
    UPDATE_GROUP_WORKOUT_SUCCESS,
    UPDATE_GROUP_WORKOUT_ERROR,
    CANCEL_GROUP_WORKOUT_REQUEST,
    CANCEL_GROUP_WORKOUT_SUCCESS,
    CANCEL_GROUP_WORKOUT_ERROR,
    SELECT_GYM,
    FETCH_RESERVATION_STATUS_REQUEST,
    FETCH_RESERVATION_STATUS_SUCCESS,
    FETCH_RESERVATION_STATUS_ERROR,
    UPLOAD_IMAGE_ERROR,
    DELETE_GYM_IMAGE_REQUEST,
    DELETE_GYM_IMAGE_SUCCESS,
    DELETE_GYM_IMAGE_ERROR,
    UPLOAD_IMAGE_SUCCESS,
    UPDATE_GYM_IMAGES_REQUEST,
    UPDATE_GYM_IMAGES_SUCCESS,
    UPDATE_GYM_IMAGES_ERROR,
    FETCH_HIDDEN_GYMS_REQUEST,
    FETCH_HIDDEN_GYMS_SUCCESS,
    FETCH_HIDDEN_GYMS_ERROR,
    FETCH_HIDDEN_GYM_REQUEST,
    FETCH_HIDDEN_GYM_SUCCESS,
    FETCH_HIDDEN_GYM_ERROR,
    RESTORE_GYM_REQUEST,
    RESTORE_GYM_SUCCESS,
    RESTORE_GYM_ERROR,
    UPDATE_LEGALENTITY_GYM_REQUEST,
    UPDATE_LEGALENTITY_GYM_SUCCESS,
    UPDATE_LEGALENTITY_GYM_ERROR,
    FETCH_GYMS_LIST_REQUEST,
    FETCH_GYMS_LIST_SUCCESS,
    FETCH_GYMS_LIST_ERROR,
    FETCH_SCROLL_GYMS_REQUEST,
    FETCH_SCROLL_GYMS_SUCCESS,
    FETCH_SCROLL_GYMS_ERROR,
    SEARCH_GYMS_REQUEST,
    SEARCH_GYMS_SUCCESS,
    SEARCH_GYMS_ERROR,
    ADD_SPECIAL_PRICE_REQUEST,
    ADD_SPECIAL_PRICE_SUCCESS,
    ADD_SPECIAL_PRICE_ERROR,
    REMOVE_SPECIAL_PRICE_REQUEST,
    REMOVE_SPECIAL_PRICE_SUCCESS,
    REMOVE_SPECIAL_PRICE_ERROR,
    FETCH_GYMS_SPECIAL_PRICE_REQUEST,
    FETCH_GYMS_SPECIAL_PRICE_SUCCESS,
    FETCH_GYMS_SPECIAL_PRICE_ERROR,
    FILTER_GYMS_SPECIAL_PRICE_SUCCESS,
    FETCH_TABLETENT_REQUEST,
    FETCH_TABLETENT_SUCCESS,
    FETCH_TABLETENT_ERROR,
    FETCH_CITIES_REQUEST,
    FETCH_CITIES_SUCCESS,
    FETCH_CITIES_ERROR,
    GeoObjectCollectionYandex,
    UPLOAD_ICON_SUCCESS,
    UPLOAD_ICON_ERROR,
    DELETE_GYM_ICON_REQUEST,
    DELETE_GYM_ICON_SUCCESS,
    DELETE_GYM_ICON_ERROR,
    SEND_EMAILS_REQUEST,
    SEND_EMAILS_REQUEST_SUCCESS,
    SEND_EMAILS_REQUEST_ERROR,
} from './types';
import { AddGymFormValues } from 'interfaces/AddGymFormValues';

export const fetchGymsList: AsyncActionCreator<void, Gym[]> = () => async (
    dispatch,
    getState
) => {
    dispatch({
        type: FETCH_GYMS_LIST_REQUEST,
    });

    const {
        auth: { token },
    } = getState();

    try {
        const { data: gymsList } = await axios.get(`${B2B_API_URL}/gyms/list`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        dispatch({
            type: FETCH_GYMS_LIST_SUCCESS,
            payload: { gymsList },
        });

        return gymsList;
    } catch (err) {
        dispatch(signOut());
        if (err instanceof Error) {
            dispatch({
                type: FETCH_GYMS_LIST_ERROR,
            });

            throw err;
        }
    }
};

export const fetchGyms: AsyncActionCreator<number, Gym[]> = (
    gymCount: number
) => async (dispatch, getState) => {
    dispatch({
        type: FETCH_GYMS_REQUEST,
    });

    const {
        auth: { token },
    } = getState();

    try {
        const { data: gyms } = await axios.get(`${B2B_API_URL}/gyms`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {
                gymCount,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: 'repeat' });
            },
        });

        dispatch({
            type: FETCH_GYMS_SUCCESS,
            payload: {
                gyms,
            },
        });

        return gyms;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: FETCH_GYMS_ERROR,
            });

            throw err;
        }
    }
};

export const fetchScrollGyms: AsyncActionCreator<number, Gym[]> = (
    gymCount: number
) => async (dispatch, getState) => {
    dispatch({
        type: FETCH_SCROLL_GYMS_REQUEST,
    });

    const {
        auth: { token },
    } = getState();

    try {
        const { data: gyms } = await axios.get(`${B2B_API_URL}/gyms`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {
                gymCount,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: 'repeat' });
            },
        });

        dispatch({
            type: FETCH_SCROLL_GYMS_SUCCESS,
            payload: {
                gyms,
            },
        });

        return gyms;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: FETCH_SCROLL_GYMS_ERROR,
            });

            throw err;
        }
    }
};

export const fetchHiddenGyms: AsyncActionCreator<void, Gym[]> = () => async (
    dispatch,
    getState
) => {
    dispatch({
        type: FETCH_HIDDEN_GYMS_REQUEST,
    });

    const {
        auth: { token },
    } = getState();

    try {
        const { data: items } = await axios.get(`${B2B_API_URL}/gyms/hidden`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        dispatch({
            type: FETCH_HIDDEN_GYMS_SUCCESS,
            payload: {
                items,
            },
        });

        return items;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: FETCH_HIDDEN_GYMS_ERROR,
            });

            throw err;
        }
    }
};

export const fetchReservationsStatuses: AsyncActionCreator<
    string | null | void,
    { groupWorkoutId: string; dateStart: Date; reservedSlots: number }[]
> = (selectedGymId?: string | null | void) => async (dispatch, getState) => {
    dispatch({
        type: FETCH_RESERVATION_STATUS_REQUEST,
    });

    const {
        auth: { token },
    } = getState();

    try {
        const { data: reservationStatusesList } = await axios.get(
            `${B2B_API_URL}/group-workouts/reservation-status`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    selectedGymId: selectedGymId,
                },
                paramsSerializer: (params) => {
                    return qs.stringify(params, { arrayFormat: 'repeat' });
                },
            }
        );

        dispatch({
            type: FETCH_RESERVATION_STATUS_SUCCESS,
            payload: reservationStatusesList,
        });

        return reservationStatusesList;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: FETCH_RESERVATION_STATUS_ERROR,
            });

            throw err;
        }
    }
};

export const fetchGym: AsyncActionCreator<string, Gym> = (id: string) => async (
    dispatch,
    getState
) => {
    dispatch({
        type: FETCH_GYM_REQUEST,
    });

    const {
        auth: { token },
    } = getState();

    try {
        const { data: gym } = await axios.get(`${B2B_API_URL}/gyms/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        dispatch({
            type: FETCH_GYM_SUCCESS,
            payload: gym,
        });

        return gym;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: FETCH_GYM_ERROR,
            });

            throw err;
        }
    }
};

export const fetchHiddenGym: AsyncActionCreator<string, Gym> = (
    id: string
) => async (dispatch, getState) => {
    dispatch({
        type: FETCH_HIDDEN_GYM_REQUEST,
    });

    const {
        auth: { token },
    } = getState();

    try {
        const { data: gym } = await axios.get(
            `${B2B_API_URL}/gyms/hidden/${id}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        dispatch({
            type: FETCH_HIDDEN_GYM_SUCCESS,
            payload: gym,
        });

        return gym;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: FETCH_HIDDEN_GYM_ERROR,
            });

            throw err;
        }
    }
};

export const restoreGym: AsyncActionCreator<string> = (id) => async (
    dispatch,
    getState
) => {
    dispatch({
        type: RESTORE_GYM_REQUEST,
    });

    const {
        auth: { token },
    } = getState();

    try {
        const { data: gym } = await axios.get(
            `${B2B_API_URL}/gyms/restore/${id}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        dispatch({
            type: RESTORE_GYM_SUCCESS,
            payload: { gym },
        });

        return gym;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: RESTORE_GYM_ERROR,
            });

            throw err;
        }
    }
};

export const createGym: AsyncActionCreator<AddGymFormValues, Gym> = (
    values
) => async (dispatch, getState) => {
    dispatch({
        type: CREATE_GYM_REQUEST,
    });

    const {
        auth: { token },
    } = getState();
    const newValues = {
        ...values,
        city: values?.city?.title,
        cityId: values?.city?.id,
    };

    if (newValues.specialPrices) {
        for (let i = 0; i < newValues.specialPrices.length; ++i) {
            if (!newValues.specialPrices[i].weekDay) {
                newValues.specialPrices[i].weekDay = WeekDay.DAILY;
            }
        }
    }

    try {
        const { data: gym } = await axios.post(
            `${B2B_API_URL}/gyms`,
            newValues,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        dispatch({
            type: CREATE_GYM_SUCCESS,
            payload: gym,
        });

        return gym;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: CREATE_GYM_ERROR,
            });

            throw err;
        }
    }
};

export const deleteGym: AsyncActionCreator<string> = (id) => async (
    dispatch,
    getState
) => {
    dispatch({
        type: DELETE_GYM_REQUEST,
    });

    const {
        auth: { token },
    } = getState();

    try {
        const { data: gym } = await axios.delete(`${B2B_API_URL}/gyms/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        dispatch({
            type: DELETE_GYM_SUCCESS,
            payload: { gym },
        });

        return gym;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: DELETE_GYM_ERROR,
            });

            throw err;
        }
    }
};

export const updateGym: AsyncActionCreator<
    { id: string; values: UpdateGymFormData },
    Gym
> = ({ id, values }) => async (dispatch, getState) => {
    dispatch({
        type: UPDATE_GYM_REQUEST,
    });

    const {
        auth: { token },
    } = getState();

    const newValues = {
        ...values,
        singleWorkoutPriceInfo: {
            ...values.singleWorkoutPriceInfo,
            weekDayOffPeakPrice: !values.hasWeekDayPrimeTime
                ? null
                : values.singleWorkoutPriceInfo.weekDayOffPeakPrice,
            weekDayOffPeakStartTime: !values.hasWeekDayPrimeTime
                ? null
                : values.singleWorkoutPriceInfo.weekDayOffPeakStartTime,
            weekDayOffPeakFinishTime: !values.hasWeekDayPrimeTime
                ? null
                : values.singleWorkoutPriceInfo.weekDayOffPeakFinishTime,
            weekendOffPeakPrice: !values.hasWeekendPrimeTime
                ? null
                : values.singleWorkoutPriceInfo.weekendOffPeakPrice,
            weekendOffPeakStartTime: !values.hasWeekendPrimeTime
                ? null
                : values.singleWorkoutPriceInfo.weekendOffPeakStartTime,
            weekendOffPeakFinishTime: !values.hasWeekendPrimeTime
                ? null
                : values.singleWorkoutPriceInfo.weekendOffPeakFinishTime,
        },
        city: values?.city?.title,
        cityId: values?.city?.id,
        subwayRoutes: values.subwayRoutes.filter(
            (subwayRoute) => subwayRoute.station && subwayRoute.duration
        ),
    };

    if (newValues.specialPrices) {
        for (let i = 0; i < newValues.specialPrices.length; ++i) {
            if (!newValues.specialPrices[i].weekDay) {
                newValues.specialPrices[i].weekDay = WeekDay.DAILY;
            }
        }
    }

    try {
        const { data: gym } = await axios.put(
            `${B2B_API_URL}/gyms/${id}`,
            newValues,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        dispatch({
            type: UPDATE_GYM_SUCCESS,
            payload: gym,
        });

        return gym;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: UPDATE_GYM_ERROR,
            });

            throw err;
        }
    }
};

export const updateGymImages: AsyncActionCreator<
    { id: string; values: UpdateGymFormData },
    Gym
> = ({ id, values }) => async (dispatch, getState) => {
    dispatch({
        type: UPDATE_GYM_IMAGES_REQUEST,
    });

    const {
        auth: { token },
    } = getState();

    try {
        const { data: gym } = await axios.post(
            `${API_URL}/dashboard/update-gym-images/${id}`,
            values,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        dispatch({
            type: UPDATE_GYM_IMAGES_SUCCESS,
            payload: gym,
        });

        return gym;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: UPDATE_GYM_IMAGES_ERROR,
            });

            throw err;
        }
    }
};

export const createGroupWorkout: AsyncActionCreator<
    {
        values: any;
        tags: string[];
    },
    GroupWorkout
> = ({ values, tags }) => async (dispatch, getState) => {
    dispatch({
        type: CREATE_GROUP_WORKOUT_REQUEST,
    });

    const {
        auth: { token },
    } = getState();
    const newValues = { ...values, tags };

    if (!('weekDaySchedule' in values)) {
        newValues.weekDaySchedule = [];
        newValues.isRecurring = false;
    }

    try {
        const {
            data: {
                groupWorkout: groupWorkout,
                weekDaySchedules: weekDaySchedules,
            },
        } = await axios.post(`${B2B_API_URL}/group-workouts`, newValues, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        dispatch({
            type: CREATE_GROUP_WORKOUT_SUCCESS,
            payload: {
                groupWorkout: groupWorkout,
                weekDaySchedules: weekDaySchedules,
            },
        });

        return groupWorkout;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: CREATE_GROUP_WORKOUT_ERROR,
            });

            throw err;
        }
    }
};

export const updateGroupWorkout: AsyncActionCreator<
    { id: string; values: UpdateGroupWorkoutFormData; tags: string[] },
    GroupWorkout
> = ({ id, values, tags }) => async (dispatch, getState) => {
    dispatch({
        type: UPDATE_GROUP_WORKOUT_REQUEST,
    });

    const {
        auth: { token },
    } = getState();

    const newValues = { ...values, tags };
    try {
        const { data: groupWorkout } = await axios.put(
            `${B2B_API_URL}/group-workouts/${id}`,
            newValues,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        dispatch({
            type: UPDATE_GROUP_WORKOUT_SUCCESS,
            payload: { groupWorkout },
        });

        return groupWorkout;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: UPDATE_GROUP_WORKOUT_ERROR,
            });

            throw err;
        }
    }
};

export const cancelGroupWorkout: AsyncActionCreator<
    {
        id: string;
        cancelReason: string;
        dateStart: Date;
        cancelParticularEvent?: boolean;
    },
    GroupWorkout
> = ({ id, cancelReason, dateStart, cancelParticularEvent }) => async (
    dispatch,
    getState
) => {
    dispatch({
        type: CANCEL_GROUP_WORKOUT_REQUEST,
    });

    const {
        auth: { token },
    } = getState();

    try {
        const { data: groupWorkout } = await axios.put(
            `${B2B_API_URL}/group-workouts/${id}`,
            {
                cancelReason,
                dateStart: new Date(
                    new Date(dateStart).getTime() -
                        new Date(dateStart).getTimezoneOffset() * 60000
                ),
                cancelParticularEvent,
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        dispatch({
            type: CANCEL_GROUP_WORKOUT_SUCCESS,
            payload: { groupWorkout },
        });

        return groupWorkout;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: CANCEL_GROUP_WORKOUT_ERROR,
            });

            throw err;
        }
    }
};

export const selectGym = (
    values: string | null
): ReduxAction<string | null> => ({
    type: SELECT_GYM,
    payload: values,
});

export const uploadImage: AsyncActionCreator<
    { files: File[]; gymId: string },
    string
> = ({ files, gymId }) => async (dispatch, getState) => {
    const {
        auth: { token },
    } = getState();
    const bodyFormData = new FormData();

    for (let k = 0; k < files.length; ++k) {
        bodyFormData.append('images', files[k]);
    }

    try {
        const {
            data: { gym },
        } = await axios.post(
            `${B2B_API_URL}/gyms/images-upload/${gymId}`,
            bodyFormData,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                },
            }
        );

        dispatch({
            type: UPLOAD_IMAGE_SUCCESS,
            payload: {
                gym,
            },
        });
        return gym;
    } catch (err) {
        dispatch({
            type: UPLOAD_IMAGE_ERROR,
        });

        throw err;
    }
};

export const deleteImage: AsyncActionCreator<
    { gymId: string; key: string },
    string[]
> = ({ gymId, key }) => async (dispatch, getState) => {
    dispatch({
        type: DELETE_GYM_IMAGE_REQUEST,
    });

    const {
        auth: { token },
    } = getState();
    try {
        const {
            data: { gym },
        } = await axios.post(
            `${B2B_API_URL}/gyms/images-delete/${gymId}`,
            { key },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        dispatch({
            type: DELETE_GYM_IMAGE_SUCCESS,
            payload: {
                gym,
            },
        });

        return gym;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: DELETE_GYM_IMAGE_ERROR,
            });

            throw err;
        }
    }
};

export const uploadGymDocs: AsyncActionCreator<
    { files: FileList; gymId: string },
    string
> = ({ files, gymId }) => async (dispatch, getState) => {
    const {
        auth: { token },
    } = getState();
    const bodyFormData = new FormData();

    for (let k = 0; k < files.length; ++k) {
        bodyFormData.append('files', files[k]);
    }

    try {
        const {
            data: { gym },
        } = await axios.post(
            `${B2B_API_URL}/gyms/docs-upload/${gymId}`,
            bodyFormData,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                },
            }
        );
        dispatch({
            type: UPLOAD_IMAGE_SUCCESS,
            payload: {
                gym,
            },
        });
        return gym;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: UPLOAD_IMAGE_ERROR,
            });

            throw err;
        }
    }
};

export const deleteGymDocs: AsyncActionCreator<
    { gymId: string; key: string },
    string[]
> = ({ gymId, key }) => async (dispatch, getState) => {
    dispatch({
        type: DELETE_GYM_IMAGE_REQUEST,
    });

    const {
        auth: { token },
    } = getState();
    try {
        const {
            data: { gym },
        } = await axios.post(
            `${B2B_API_URL}/gyms/docs-delete/${gymId}`,
            { key },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        dispatch({
            type: DELETE_GYM_IMAGE_SUCCESS,
            payload: {
                gym,
            },
        });

        return gym;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: DELETE_GYM_IMAGE_ERROR,
            });

            throw err;
        }
    }
};

export const uploadGymIcon: AsyncActionCreator<
    { files: File[]; gymId: string },
    string
> = ({ files, gymId }) => async (dispatch, getState) => {
    const {
        auth: { token },
    } = getState();

    const bodyFormData = new FormData();

    for (let k = 0; k < files.length; ++k) {
        bodyFormData.append('icons', files[k]);
    }

    try {
        const { data: gym } = await axios.post(
            `${B2B_API_URL}/gyms/icon-upload/${gymId}`,
            bodyFormData,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                },
            }
        );

        dispatch({
            type: UPLOAD_ICON_SUCCESS,
            payload: gym,
        });

        return gym;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: UPLOAD_ICON_ERROR,
            });

            throw err;
        }
    }
};

export const sendEmailNotifications: AsyncActionCreator<{
    config: { gymId: string; type: 'old' | 'new' };
}> = ({ config }) => async (dispatch, getState) => {
    const {
        auth: { token },
    } = getState();

    try {
        dispatch({
            type: SEND_EMAILS_REQUEST,
        });

        await axios.get(`${B2B_API_URL}/gyms/notifications`, {
            params: config,
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data',
            },
        });

        dispatch({
            type: SEND_EMAILS_REQUEST_SUCCESS,
        });

        cogoToast.success('Рассылка успешно запустилась', {
            position: 'top-right',
            hideAfter: 5,
        });
    } catch (err) {
        dispatch({
            type: SEND_EMAILS_REQUEST_ERROR,
        });

        cogoToast.error('Не удалось запустить рассылку', {
            position: 'top-right',
            hideAfter: 4,
        });

        throw err;
    }
};

export const deleteGymIcon: AsyncActionCreator<
    { gymId: string; key: string },
    string[]
> = ({ gymId, key }) => async (dispatch, getState) => {
    dispatch({
        type: DELETE_GYM_ICON_REQUEST,
    });

    const {
        auth: { token },
    } = getState();
    try {
        const { data: gym } = await axios.post(
            `${B2B_API_URL}/gyms/icon-delete/${gymId}`,
            { key },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        dispatch({
            type: DELETE_GYM_ICON_SUCCESS,
            payload: gym,
        });

        return gym;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: DELETE_GYM_ICON_ERROR,
            });

            throw err;
        }
    }
};

export const updateManagerGym: AsyncActionCreator<
    { id: string; managersIds: string[] },
    Gym
> = ({ id, managersIds }) => async (dispatch, getState) => {
    dispatch({
        type: UPDATE_MANAGER_GYM_REQUEST,
    });

    const {
        auth: { token },
    } = getState();

    try {
        const { data: gym } = await axios.post(
            `${API_URL}/dashboard/update-manager-gym/${id}`,
            { managersIds },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        dispatch({
            type: UPDATE_MANAGER_GYM_SUCCESS,
            payload: gym,
        });

        return gym;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: UPDATE_MANAGER_GYM_ERROR,
            });

            throw err;
        }
    }
};

export const updateLegalEntityGym: AsyncActionCreator<
    { id: string; legalEntityId: string[] },
    Gym
> = ({ id, legalEntityId }) => async (dispatch, getState) => {
    dispatch({
        type: UPDATE_LEGALENTITY_GYM_REQUEST,
    });

    const {
        auth: { token },
    } = getState();

    try {
        const { data: gym } = await axios.post(
            `${API_URL}/dashboard/update-legalentity-gym/${id}`,
            { legalEntityId },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        dispatch({
            type: UPDATE_LEGALENTITY_GYM_SUCCESS,
            payload: gym,
        });

        return gym;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: UPDATE_LEGALENTITY_GYM_ERROR,
            });

            throw err;
        }
    }
};

export const searchGyms: AsyncActionCreator<{ value: string }, Gym[]> = ({
    value,
}) => async (dispatch, getState) => {
    dispatch({
        type: SEARCH_GYMS_REQUEST,
    });

    const {
        auth: { token },
    } = getState();

    try {
        const { data: gyms } = await axios.post(
            `${B2B_API_URL}/gyms/search`,
            { value },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        dispatch({
            type: SEARCH_GYMS_SUCCESS,
            payload: { gyms },
        });
        return gyms;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: SEARCH_GYMS_ERROR,
            });

            throw err;
        }
    }
};

export const addSpecialPrice: AsyncActionCreator<
    { values: SpecialPriceFormData; gymsId: string[] },
    Gym[]
> = ({ values, gymsId }) => async (dispatch, getState) => {
    dispatch({
        type: ADD_SPECIAL_PRICE_REQUEST,
    });

    const {
        auth: { token },
    } = getState();

    const newValues = {
        ...values,
        gymsId,
        actionStart: moment(values.actionStart)
            .add(1, 'day')
            .utcOffset(0)
            .startOf('day')
            .toISOString(),
        actionEnd: moment(values.actionEnd)
            .add(1, 'day')
            .utcOffset(0)
            .endOf('day')
            .toISOString(),
    };

    try {
        const { data: gyms } = await axios.post(
            `${B2B_API_URL}/gyms/special-price`,
            newValues,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        dispatch({
            type: ADD_SPECIAL_PRICE_SUCCESS,
            payload: gyms,
        });

        return gyms;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: ADD_SPECIAL_PRICE_ERROR,
            });

            throw err;
        }
    }
};

export const removeSpecialPrice: AsyncActionCreator<string, Gym> = (
    id
) => async (dispatch, getState) => {
    dispatch({
        type: REMOVE_SPECIAL_PRICE_REQUEST,
    });

    const {
        auth: { token },
    } = getState();

    try {
        const { data: gym } = await axios.delete(
            `${B2B_API_URL}/gyms/special-price/${id}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        dispatch({
            type: REMOVE_SPECIAL_PRICE_SUCCESS,
            payload: gym,
        });

        return gym;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: REMOVE_SPECIAL_PRICE_ERROR,
            });

            throw err;
        }
    }
};

export const fetchGymsWithSpecialPrice: AsyncActionCreator<
    void,
    Gym[]
> = () => async (dispatch, getState) => {
    dispatch({
        type: FETCH_GYMS_SPECIAL_PRICE_REQUEST,
    });

    const {
        auth: { token },
    } = getState();

    try {
        const { data: gyms } = await axios.get(
            `${B2B_API_URL}/gyms/special-price`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        dispatch({
            type: FETCH_GYMS_SPECIAL_PRICE_SUCCESS,
            payload: gyms,
        });

        return gyms;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: FETCH_GYMS_SPECIAL_PRICE_ERROR,
            });

            throw err;
        }
    }
};

export const filterGymsWithSpecialPrice = (gyms: Gym[]) => (
    dispatch: AsyncDispatch
): void => {
    dispatch({
        type: FILTER_GYMS_SPECIAL_PRICE_SUCCESS,
        payload: gyms,
    });
};

export const fetchTabletent: AsyncActionCreator<
    {
        params: {
            id: string;
        };
        query: {
            type: TableTentType;
        };
    },
    { base64: string }
> = (config) => async (dispatch, getState) => {
    dispatch({
        type: FETCH_TABLETENT_REQUEST,
    });

    const {
        auth: { token },
    } = getState();

    try {
        const { params, query } = config;

        const { data: tabletent } = await axios.get(
            `${B2B_API_URL}/gyms/tabletent/${params.id}`,
            {
                params: query,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        dispatch({
            type: FETCH_TABLETENT_SUCCESS,
            payload: tabletent,
        });

        return tabletent;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: FETCH_TABLETENT_ERROR,
            });

            throw err;
        }
    }
};

export const fetchCities: AsyncActionCreator<string, City[]> = (
    search: string
) => async (dispatch) => {
    dispatch({
        type: FETCH_CITIES_REQUEST,
    });

    try {
        const { data: cities } = await axios.get(`${API_URL}/cities/all`, {
            params: {
                search,
            },
        });

        dispatch({
            type: FETCH_CITIES_SUCCESS,
            payload: { cities },
        });

        return cities;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: FETCH_CITIES_ERROR,
            });

            throw err;
        }
    }
};

export const fetchSuggestionsByAddress: AsyncActionCreator<
    string,
    GeoObjectCollectionYandex
> = (address) => async () => {
    try {
        const {
            data: { response },
        } = await axios.get(
            `https://geocode-maps.yandex.ru/1.x/?format=json&apikey=${process.env.REACT_APP_YANDEX_API_KEY}&geocode=${address}`
        );

        return response;
    } catch (err) {
        if (err instanceof Error) {
            throw err;
        }
    }
};

export const fetchAddressByLocation: AsyncActionCreator<
    string[],
    GeoObjectCollectionYandex
> = (location) => async () => {
    try {
        const {
            data: { response },
        } = await axios.get(
            `https://geocode-maps.yandex.ru/1.x/?format=json&apikey=${
                process.env.REACT_APP_YANDEX_API_KEY
            }&geocode=${location.join('%2C')}`
        );

        return response;
    } catch (err) {
        if (err instanceof Error) {
            throw err;
        }
    }
};
