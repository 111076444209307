import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import cogoToast from 'cogo-toast';

import { AsyncDispatch } from '@redux/types';
import { handleCreateLegalEntity } from '@redux/modules/legal-entity/actions';
import BackButton from '@components/BackButton';

import Form from './Form';
import styles from './styles.module.css';

export const AddLegalEntity: React.FC = () => {
    const dispatch = useDispatch<AsyncDispatch>();
    const history = useHistory();
    const onSubmit = useCallback(
        (values) => {
            dispatch(handleCreateLegalEntity(values))
                .then((legalEntity) => {
                    if (legalEntity) {
                        cogoToast.success('Юридическое лицо создано', {
                            position: 'top-right',
                            hideAfter: 5,
                        });
                        history.push('/dashboard/add-partner-gym');
                    }
                })
                .catch(() => {
                    cogoToast.error('Ошибка при создании юридического лица', {
                        position: 'top-right',
                        hideAfter: 4,
                    });
                });
        },
        [dispatch, history]
    );

    return (
        <div className={styles.container}>
            <h1>Зарегистрировать нового партнера</h1>

            <BackButton title="К форме менеджера" className={styles.backBtn} />

            <Form onSubmit={onSubmit} />
        </div>
    );
};

export default AddLegalEntity;
