import * as React from 'react';

import ActionsModal from '@components/Modal';
import Form from '@components/Form';
import { Field } from '@components/Field';
import SelectInput from '@components/SelectInput';
import Button from '@components/Button';
import { getYearsOptions, MonthsOptions } from '@t/report';

import styles from './styles.module.css';
import { change } from 'redux-form';
import { ApplicationState, FormName, ReduxAction } from '@redux/types';
import { useDispatch } from 'react-redux';
import {
    generatePartnerReport,
    confirmPartnerReport,
    deletePartnerReport,
} from '@redux/modules/reports/actions';
import { usePartnersForm } from 'hooks/usePartnersReportForm';
import { ThunkAction } from 'redux-thunk';

enum PartnerReportsFieldName {
    ActionName = 'actionName',
    ActionType = 'type',
    ActionYear = 'year',
    ActionMonth = 'month',
}

export enum PartnerReportActionNameValue {
    CreatePartnerReport = 'create_partner_report',
    ConfirmPartnerReport = 'confirm_partner_report',
    DeletePartnerReport = 'delete_partner_report',
}

interface TField {
    name: PartnerReportsFieldName;
    label: string;
    placeholder: string;
    emptyTitle: string;
    options: any[];
}
const fields: TField[] = [
    {
        name: PartnerReportsFieldName.ActionName,
        label: 'Действия',
        placeholder: 'Действие',
        emptyTitle: 'Выберите действие...',
        options: [
            {
                title: 'Создать партнерские отчеты',
                value: PartnerReportActionNameValue.CreatePartnerReport,
            },
            {
                title: 'Подтвердить партнерские отчеты',
                value: PartnerReportActionNameValue.ConfirmPartnerReport,
            },
            {
                title: 'Удалить партнерские отчеты',
                value: PartnerReportActionNameValue.DeletePartnerReport,
            },
        ],
    },
    {
        name: PartnerReportsFieldName.ActionType,
        label: 'Тип',
        placeholder: 'Тип',
        emptyTitle: 'Выберите тип...',
        options: [
            { title: 'Тренировки', value: 'BOOKING' },
            { title: 'Абонементы', value: 'MEMBERSHIP' },
        ],
    },
    {
        name: PartnerReportsFieldName.ActionYear,
        label: 'Год',
        placeholder: 'Год',
        emptyTitle: 'Выберите год...',
        options: getYearsOptions(true),
    },
    {
        name: PartnerReportsFieldName.ActionMonth,
        label: 'Месяц',
        placeholder: 'Месяц',
        emptyTitle: 'Выберите месяц...',
        options: MonthsOptions,
    },
];

const requestMatrix: Record<
    PartnerReportActionNameValue,
    (
        args: Record<PartnerReportActionNameValue, string>
    ) => ThunkAction<Promise<void>, ApplicationState, any, ReduxAction<any>>
> = {
    [PartnerReportActionNameValue.CreatePartnerReport]: (args) =>
        generatePartnerReport(
            (args as unknown) as Parameters<typeof generatePartnerReport>[0]
        ),
    [PartnerReportActionNameValue.ConfirmPartnerReport]: (args) =>
        confirmPartnerReport(
            (args as unknown) as Parameters<typeof confirmPartnerReport>[0]
        ),
    [PartnerReportActionNameValue.DeletePartnerReport]: (args) =>
        deletePartnerReport(
            (args as unknown) as Parameters<typeof deletePartnerReport>[0]
        ),
};

const SubmitButton = React.memo(() => {
    const { values } = usePartnersForm<
        Record<PartnerReportsFieldName, string>
    >();

    const dispatch = useDispatch();

    const onSubmit = React.useCallback(() => {
        const { actionName, ...rest } = values as {
            actionName: PartnerReportActionNameValue | undefined;
        } & Record<PartnerReportActionNameValue, string>;

        if (actionName) {
            dispatch(requestMatrix[actionName](rest));
        }
    }, [values]);

    const buttonText = React.useMemo(() => {
        if (
            values.actionName ===
            PartnerReportActionNameValue.CreatePartnerReport
        )
            return 'Создать отчеты';

        if (
            values.actionName ===
            PartnerReportActionNameValue.ConfirmPartnerReport
        )
            return 'Подтвердить отчеты';

        return 'Удалить отчеты';
    }, [values]);

    if (Object.keys(values).length !== 4) {
        return null;
    }

    return (
        <Button type="button" className={styles.btn} onClick={onSubmit}>
            {buttonText}
        </Button>
    );
});

export const ActionsModalReportWrapper = React.memo<{
    actionsModalActive: boolean;
    toggleActionsModalActive: () => void;
}>(({ actionsModalActive, toggleActionsModalActive }) => {
    const [key, rerender] = React.useReducer((i) => i + 1, 0);
    const dispatch = useDispatch();

    const handleMapField = React.useCallback(
        (field: TField) => (
            <Field {...field} required type="text" component={SelectInput} />
        ),
        []
    );

    const handleClose = React.useCallback(() => {
        toggleActionsModalActive();

        Object.values(PartnerReportsFieldName).forEach((fieldName) =>
            dispatch(change(FormName.PartnerActionsForm, fieldName, ''))
        );

        setTimeout(rerender, 250);
    }, []);

    return (
        <ActionsModal
            active={actionsModalActive}
            setActive={handleClose}
            key={key}
        >
            <Form
                form={FormName.PartnerActionsForm}
                className={styles.accountingReportForm}
            >
                {fields.map(handleMapField)}
                <SubmitButton />
            </Form>
        </ActionsModal>
    );
});
