import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import moment from 'moment';
import cogoToast from 'cogo-toast';

import { DATE_TIME_FORMAT } from '@config';
import CouponsForm, {
    CouponsFormData,
} from '@containers/Dashboard/CouponsDetails/Form';
import { AsyncDispatch } from '@redux/types';
import BackButton from '@components/BackButton';
import {
    getCoupon,
    getCouponsAdCampaigns,
} from '@redux/modules/coupons/selectors';
import {
    deleteCoupon,
    fetchCoupon,
    fetchCouponsAdCampaigns,
    updateCoupon,
} from '@redux/modules/coupons/actions';
import { getGymsList } from '@redux/modules/gyms/selectors';
import Loader from '@components/Loader';

import styles from './styles.module.css';

export const CouponsDetails: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const history = useHistory();
    const dispatch = useDispatch<AsyncDispatch>();
    const gyms = useSelector(getGymsList);
    const coupon = useSelector(getCoupon(id || ''));
    const couponsAdCampaigns = useSelector(getCouponsAdCampaigns);

    useEffect(() => {
        if (!id) {
            return;
        }
        dispatch(fetchCoupon(id));
        dispatch(fetchCouponsAdCampaigns());
    }, [dispatch, id]);

    const onSubmit = (values: CouponsFormData) => {
        if (!id) {
            return;
        }
        dispatch(updateCoupon({ id, values }))
            .then(() => {
                cogoToast.success('Промокод обновлен', {
                    position: 'top-right',
                    hideAfter: 5,
                });
                history.push(`/dashboard/coupons`);
            })
            .catch(() => {
                cogoToast.error('Ошибка при обновлении промокода', {
                    position: 'top-right',
                    hideAfter: 4,
                });
            });
    };

    const onDeleteCoupon = useCallback(() => {
        dispatch(deleteCoupon(id))
            .then(() => {
                cogoToast.success('Промокод удален', {
                    position: 'top-right',
                    hideAfter: 5,
                });
                history.push(`/dashboard/coupons`);
            })
            .catch(() => {
                cogoToast.error('Ошибка при удаления промокода', {
                    position: 'top-right',
                    hideAfter: 4,
                });
            });
    }, [dispatch, id]);

    if (!coupon) {
        return null;
    }

    const initialValues = {
        couponId: coupon._id,
        couponName: coupon.couponName,
        couponType: coupon.couponType,
        discount: coupon.discount,
        initialPrice: coupon.initialPrice,
        bookingDuration: coupon.bookingDuration,
        active: coupon.active,
        unlimited: coupon.unlimited,
        limitPerUser: coupon.limitPerUser,
        activatedStart: coupon.activatedStart,
        activatedByUserId: coupon.activatedByUserId,
        activatedCount: coupon.activatedCount,
        actionStart:
            coupon.actionStart &&
            new Date(coupon.actionStart).toISOString().slice(0, 16),
        actionEnd:
            coupon.actionEnd &&
            new Date(coupon.actionEnd).toISOString().slice(0, 16),
        adCampaignId: coupon.adCampaignId,
        activatedUsers: coupon?.activatedUsersId?.length,
        userIds: coupon?.userIds
            ? String(coupon?.userIds).split(',').join(';')
            : '',
        gymIds: coupon.gymIds,
        comment: coupon.comment,
        createdAt: moment(coupon.createdAt).format(DATE_TIME_FORMAT),
    };

    const gymsOptions = gyms
        .filter((gym) => !gym.isHidden)
        .map((gym) => {
            return {
                title: gym.title,
                value: gym._id,
            };
        });

    const couponsAdCampaignsOptions = couponsAdCampaigns.map(
        (couponsAdCampaign) => {
            return {
                title: couponsAdCampaign.name,
                value: couponsAdCampaign._id,
            };
        }
    );

    return (
        <div className={styles.container}>
            <BackButton title="Назад к промокодам" className={styles.backBtn} />
            {initialValues.adCampaignId ||
            initialValues.initialPrice ||
            initialValues.discount ? (
                <div className={styles.box}>
                    <CouponsForm
                        form="couponInfo"
                        onSubmit={onSubmit}
                        initialValues={initialValues}
                        onDeleteCoupon={onDeleteCoupon}
                        gymsOptions={gymsOptions}
                        activatedByUserId={coupon.activatedByUserId}
                        adCampaign={coupon.adCampaignId}
                        couponsAdCampaignsOptions={couponsAdCampaignsOptions}
                        actionStart={coupon.actionStart}
                        actionEnd={coupon.actionEnd}
                    />
                </div>
            ) : (
                <Loader />
            )}
        </div>
    );
};

export default CouponsDetails;
