import * as React from 'react';
import RSSelect from 'react-select';
import classnames from 'classnames';

import { SelectOption } from 'interfaces/SelectOption';
import styles from './styles.module.css';

type SelectProps = React.ComponentProps<typeof RSSelect>;
export const Select = React.memo<
    Omit<SelectProps, 'options' | 'onChange'> & {
        options: SelectOption[];
        onChange?: (
            e: (SelectOption | null) | SelectOption[],
            ...args: unknown[]
        ) => void;
        label?: string;
        selectLabelClassName?: string;
    }
>(({ onChange, label, selectLabelClassName, ...props }) => {
    return (
        <div>
            {label && (
                <div
                    className={classnames(
                        styles.selectLabel,
                        selectLabelClassName
                    )}
                >
                    {label}
                </div>
            )}
            <RSSelect
                styles={{
                    input: (provided) => ({
                        ...provided,
                        minHeight: 40,
                        borderRadius: '0px 0px 20px 20px',
                    }),
                }}
                theme={(theme) => ({
                    ...theme,
                    colors: {
                        ...theme.colors,
                        primary: 'rgb(225, 129, 65)',
                    },
                })}
                onChange={(onChange as unknown) as SelectProps['onChange']}
                {...props}
            />
        </div>
    );
});
