import React, { useState } from 'react';
import { InjectedFormProps, Field, reduxForm } from 'redux-form';
import { useSelector } from 'react-redux';

import { getUser } from '@redux/modules/auth/selectors';
import { UserRole } from '@t/user';
import TextInput from '@components/TextInput';
import Button from '@components/Button';
import CheckBoxInput from '@components/CheckBoxInput';
import Modal from '@components/Modal';
import TextAreaInput from '@components/TextAreaInput';
import LeftArrow from '@components/Icons/LeftArrow';

import styles from './styles.module.css';

export interface AppUserFormData {
    appUserId: string;
    createdAt: string;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    sex: string;
    invitationCode: string;
    usedInvitationCode: string;
    paymentDataId: string;
    bonusPoints: number;
    applicationVersion: string;
    disabled: boolean;
    comment: string;
    deviceRegistrationsCount: string;
    phoneRegistrationsCount: string;
}

export interface Props {
    onDeleteManager: () => void;
    goToAppUserBookings: () => void;
    goToInvaitedUsers: () => void;
    goToAppUserStartBooking: () => void;
}

const AppUserForm: React.FC<
    Props & InjectedFormProps<AppUserFormData, Props>
> = ({
    handleSubmit,
    onDeleteManager,
    goToAppUserBookings,
    goToInvaitedUsers,
    goToAppUserStartBooking,
}) => {
    const user = useSelector(getUser);
    const isSuperAdmin = user?.role === UserRole.SuperAdmin;
    const [modalActive, setModalActive] = useState(false);
    const [disabled, setDisabled] = useState(true);

    return (
        <form onSubmit={handleSubmit} className={styles.form}>
            <Field
                disabled
                name="appUserId"
                label="ID пользователя"
                component={TextInput}
            />

            <Field
                disabled
                name="createdAt"
                label="Дата регистрации"
                component={TextInput}
            />

            <Field
                disabled={disabled}
                name="firstName"
                label="Имя"
                component={TextInput}
            />

            <Field
                disabled={disabled}
                name="lastName"
                label="Фамилия"
                component={TextInput}
            />

            <Field disabled name="email" label="Email" component={TextInput} />

            <Field
                disabled
                name="phoneNumber"
                label="Телефон"
                component={TextInput}
            />

            <Field disabled name="sex" label="Пол" component={TextInput} />

            <Field
                disabled
                name="invitationCode"
                label="Код приглашения"
                component={TextInput}
            />

            <div className={styles.col}>
                <Field
                    disabled
                    name="invitedUsers"
                    label="Приглашенные пользователи"
                    component={TextInput}
                />
                <div
                    onClick={() => goToInvaitedUsers()}
                    title="Перейти к приглашенным пользователям"
                    className={styles.wrap}
                >
                    <LeftArrow className={styles.iconArrow} />
                </div>
            </div>

            <Field
                disabled
                name="usedInvitationCode"
                label="Использованный код приглашения"
                component={TextInput}
            />

            <Field
                disabled
                name="applicationVersion"
                label="Версия приложения"
                component={TextInput}
            />

            <Field
                disabled={disabled}
                name="bonusPoints"
                label="Бонусные баллы"
                component={TextInput}
            />

            <div className={styles.col}>
                <Field
                    disabled
                    name="bookings"
                    label="Тренировок"
                    component={TextInput}
                />
                <div
                    onClick={() => goToAppUserBookings()}
                    title="Перейти к тренировкам пользователя"
                    className={styles.wrap}
                >
                    <LeftArrow className={styles.iconArrow} />
                </div>
            </div>

            <div className={styles.col}>
                <Field
                    disabled
                    name="phoneRegistrationsCount"
                    label="Кол-во регистраций на номер телефона"
                    component={TextInput}
                />
            </div>

            <div className={styles.col}>
                <Field
                    disabled
                    name="deviceRegistrationsCount"
                    label="Кол-во зарегистрированных девайсов"
                    component={TextInput}
                />
            </div>

            <Field
                disabled={disabled}
                name="comment"
                label="Комментарий к пользователю"
                component={TextAreaInput}
            />

            <Field
                disabled={disabled}
                name="disabled"
                label="Заблокирован"
                component={CheckBoxInput}
            />

            {isSuperAdmin && (
                <Button
                    type="button"
                    onClick={() => goToAppUserStartBooking()}
                    className={styles.btn}
                >
                    Создать тренировку
                </Button>
            )}

            {isSuperAdmin && !disabled && (
                <Button type="submit" className={styles.btn}>
                    Обновить
                </Button>
            )}

            {isSuperAdmin && disabled && (
                <Button
                    type="button"
                    onClick={() => setDisabled(false)}
                    className={styles.btn}
                >
                    Изменить
                </Button>
            )}

            <Modal active={modalActive} setActive={setModalActive}>
                <h3>Вы действительно хотите удалить пользователя?</h3>
                {isSuperAdmin && (
                    <Button
                        onClick={onDeleteManager}
                        type="button"
                        className={styles.btnModal}
                    >
                        Удалить
                    </Button>
                )}
            </Modal>

            {isSuperAdmin && (
                <Button
                    onClick={() => setModalActive(true)}
                    type="button"
                    className={styles.btn}
                >
                    Удалить
                </Button>
            )}
        </form>
    );
};

export default reduxForm<AppUserFormData, Props>({ form: 'updateAppUser' })(
    AppUserForm
);
