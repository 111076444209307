import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import cogoToast from 'cogo-toast';

import { DATE_FORMAT, TIME_FORMAT } from '@config';
import ReservationForm, {
    ReservationFormData,
} from '@containers/Dashboard/ReservationDetails/Form';
import { getReservation } from '@redux/modules/reservations/selectors';
import {
    fetchReservation,
    updateReservation,
} from '@redux/modules/reservations/actions';
import { AsyncDispatch } from '@redux/types';
import BackButton from '@components/BackButton';
import { getConfig } from '@redux/modules/config/selectors';
import { fetchConfig } from '@redux/modules/config/actions';
import {
    GroupWorkoutReservationNewType,
    userPaymentsType,
    UserPayments,
} from '@t/reservation';

import styles from './styles.module.css';
import {
    calculateShiftedDate,
    shiftTimeByTimezone,
} from '../ReservationsList/ReservationsListTable';

const getTipMessage = (
    status: string,
    userPayments: UserPayments[],
    createdAt: Date,
    confirmationLimit = 3,
    timezone: string
): string => {
    if (status === GroupWorkoutReservationNewType.CANCELLED_BY_MANAGER) {
        return 'Возврат средств пользователю';
    } else if (status === GroupWorkoutReservationNewType.CANCELLED_BY_USER) {
        const hasRefund = userPayments.some(
            (payment) => payment.type === userPaymentsType.REFUND
        );
        return hasRefund
            ? 'Возврат средств пользователю'
            : 'Средства удержаны с пользователя';
    } else if (status === GroupWorkoutReservationNewType.NEW) {
        const createdAtMoment = moment.utc(createdAt);
        const timezoneOffset = parseInt(timezone.split('UTC')[1], 10);
        const shiftedTime = createdAtMoment.utcOffset(timezoneOffset * 60);
        const expirationTime = moment(shiftedTime).add(
            confirmationLimit,
            'hours'
        );
        const now = moment();
        const timeLeft = expirationTime.diff(now);

        if (timeLeft > 0) {
            const duration = moment.duration(timeLeft);
            const hours = String(Math.floor(duration.asHours())).padStart(
                2,
                '0'
            );
            const minutes = String(duration.minutes()).padStart(2, '0');
            const seconds = String(duration.seconds()).padStart(2, '0');
            const formattedTimeLeft = `${hours}:${minutes}:${seconds}`;
            return `До автоматической отмены осталось ${formattedTimeLeft}`;
        } else {
            return '';
        }
    } else {
        return '';
    }
};

export const ReservationDetails: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const dispatch = useDispatch<AsyncDispatch>();
    const reservation = useSelector(getReservation(id || ''));
    const config = useSelector(getConfig);
    const [disabled, setDisabled] = useState<boolean>(true);

    useEffect(() => {
        if (!id) {
            return;
        }
        dispatch(fetchConfig());
        const promises = [dispatch(fetchReservation(id))];
        Promise.all(promises).catch(() => {
            cogoToast.error('Ошибка при загрузке бронирования', {
                position: 'top-right',
                hideAfter: 4,
            });
        });
    }, [dispatch, id]);

    if (!reservation) {
        return null;
    }

    const onSubmit = (values: ReservationFormData) => {
        if (!id) {
            return;
        }
        let shouldUpdate = false;
        const dateToUpdate: Partial<ReservationFormData> = {};

        if (reservation.status !== values.status) {
            shouldUpdate = true;
            dateToUpdate.status = values.status;
        }

        if (reservation.comment !== values.comment) {
            shouldUpdate = true;
            dateToUpdate.comment = values.comment;
        }

        if (!shouldUpdate && !dateToUpdate.status && !dateToUpdate.comment) {
            return; // Ничего не делать, если значения не изменились
        }

        dispatch(
            updateReservation({
                id,
                status: dateToUpdate.status,
                comment: dateToUpdate.comment,
            })
        )
            .then(() => {
                setDisabled(true);
                cogoToast.success('Бронирования обновлено', {
                    position: 'top-right',
                    hideAfter: 5,
                });
            })
            .catch(() => {
                cogoToast.error('Ошибка при обновлении бронирования', {
                    position: 'top-right',
                    hideAfter: 4,
                });
            });
    };

    if (!reservation?.gymId?.phone && reservation?.gymId?.phone !== '') {
        return null;
    }

    const initialValues = {
        reservationId: reservation._id,
        reservationTitle: reservation.title,
        gymTitle: reservation.gymTitle,
        gymPhone: reservation?.gymId?.phone ? reservation.gymId.phone : '一',
        dateStart: `${moment(reservation.date)
            .utcOffset(0)
            .format(DATE_FORMAT)}, ${moment(reservation.timeSlot.timeStart)
            .utcOffset(0)
            .format(TIME_FORMAT)}`,
        dateFinish: `${moment(
            calculateShiftedDate(
                reservation.timeSlot.timeStart,
                reservation.timeSlot.timeFinish,
                reservation.date
            )
        )
            .utcOffset(0)
            .format(DATE_FORMAT)}, ${moment(reservation.timeSlot.timeFinish)
            .utcOffset(0)
            .format(TIME_FORMAT)}`,
        userName: reservation.userId.firstName,
        userPhone: reservation.userId.phoneNumber,
        reservationTime: shiftTimeByTimezone(
            reservation.createdAt,
            reservation.gymId.timezone
        ),
        status: reservation.status,
        comment: reservation.comment,
    };

    const reservationTip = getTipMessage(
        reservation.status,
        reservation.userPayments,
        reservation.createdAt,
        config.confirmationLimit,
        reservation.gymId.timezone
    );

    return (
        <div className={styles.container}>
            <BackButton
                title="К списку бронирований"
                className={styles.backBtn}
            />
            <div className={styles.box}>
                <ReservationForm
                    form="reservationInfo"
                    onSubmit={onSubmit}
                    initialValues={initialValues}
                    disabled={disabled}
                    setDisabled={setDisabled}
                    config={config}
                    reservationTip={reservationTip}
                    status={initialValues.status}
                />
            </div>
        </div>
    );
};

export default ReservationDetails;
