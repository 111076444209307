import moment from 'moment';

import { SubwayRoute, SpecialPrice, WorkingHours } from '@t/gym';
import { TIME_FORMAT } from '@config';
import { AddGymFormValues } from 'interfaces/AddGymFormValues';
import { IAddGymErrors } from 'interfaces/GymFormData';

export const fieldErrorsMatrix: Record<string, string> = {
    title: 'Название площадки',
    description: 'Описание площадки',
    fixedPrice: 'Стоимость площадки',
    minWorkoutDuration: 'Минимальное время тренировки',
    geo: 'Полный адрес площадки',
    city: 'Город площадки',
    discountFromYclientsPrice: 'Скидка YCLIENTS',
    prepaymentPercentFromYclientsPrice: 'Предоплата YCLIENTS',
    phone: 'Номер телефона площадки',
    prepayment: 'Предоплата площадки',
    shortAddress: 'Короткий адрес площадки',
};

export const isEmptyError = (errors: IAddGymErrors) => {
    for (const key in errors) {
        if (
            Object.prototype.hasOwnProperty.call(errors, key) &&
            Object.keys(errors[key as keyof typeof errors]).length !== 0
        ) {
            return false;
        }
    }
    return true;
};

export const validate = (values: AddGymFormValues) => {
    const errors: IAddGymErrors = {
        title: '',
        description: '',
        fixedPrice: '',
        minWorkoutDuration: '',
        geo: '',
        city: '',
        discountFromYclientsPrice: '',
        prepaymentPercentFromYclientsPrice: '',
        subwayRoutes: {},
        specialPrices: {},
        workingHours: {},
        phone: '',
        prepayment: '',
        shortAddress: '',
    };

    if (!values.title) {
        errors.title = 'Обязательное поле';
    }

    if (!values.title?.replace(/\s/g, '')) {
        errors.title = 'Обязательное поле';
    }

    if (!values.description || !values.description.replace(/\s/g, '')) {
        errors.description = 'Обязательное поле';
    }

    if (
        values.hasPerMinWorkouts &&
        (!Number.isInteger(Number(values.fixedPrice)) ||
            Number(values.fixedPrice) <= 0 ||
            String(values.fixedPrice).replace(/^\d+$/g, '').length)
    ) {
        errors.fixedPrice = 'Должно быть целым числом';
    }

    if (values.hasPerMinWorkouts && !values.fixedPrice) {
        errors.fixedPrice = 'Обязательное поле';
    }

    if (!values.city?.title && !values.city?.id) {
        errors.city = 'Обязательное поле';
    }

    if (!values.geo?.address) {
        errors.geo = 'Обязательное поле';
    }

    if (
        values.hasMinWorkoutDuration &&
        (!Number.isInteger(Number(values.minWorkoutDuration)) ||
            Number(values.minWorkoutDuration) <= 0 ||
            String(values.minWorkoutDuration).replace(/^\d+$/g, '').length)
    ) {
        errors.minWorkoutDuration = 'Должно быть числом';
    }

    if (values.hasMinWorkoutDuration && !values.minWorkoutDuration) {
        errors.minWorkoutDuration = 'Обязательное поле';
    }

    if (
        !!values.yclientsId &&
        (!Number.isInteger(Number(values.discountFromYclientsPrice)) ||
            String(values.discountFromYclientsPrice).replace(/^\d+$/g, '')
                .length)
    ) {
        errors.discountFromYclientsPrice = 'Введите число от 0 до 100';
    }

    if (
        !!values.yclientsId &&
        (!Number.isInteger(Number(values.prepaymentPercentFromYclientsPrice)) ||
            String(values.prepaymentPercentFromYclientsPrice).replace(
                /^\d+$/g,
                ''
            ).length)
    ) {
        errors.prepaymentPercentFromYclientsPrice = 'Введите число от 0 до 100';
    }

    if (!!values.yclientsId && Number(values.discountFromYclientsPrice) > 100) {
        errors.discountFromYclientsPrice = 'Введите число от 0 до 100';
    }

    if (
        !!values.yclientsId &&
        Number(values.prepaymentPercentFromYclientsPrice) < 0
    ) {
        errors.prepaymentPercentFromYclientsPrice = 'Введите число от 0 до 100';
    }

    if (
        !!values.hasGroupWorkouts &&
        (!Number.isInteger(Number(values.prepayment)) ||
            String(values.prepayment).replace(/^\d+$/g, '').length ||
            Number(values.prepayment) < 50 ||
            Number(values.prepayment) > 100)
    ) {
        errors.prepayment = 'Укажите размер предоплаты от 50% до 100%';
    }

    if (values.hasGroupWorkouts && !values.prepayment) {
        errors.prepayment = 'Обязательное поле';
    }

    if (!values.shortAddress) {
        errors.shortAddress = 'Обязательное поле';
    }

    values.subwayRoutes?.forEach((subwayRoute: SubwayRoute, i: number) => {
        if (!subwayRoute.station) {
            errors.subwayRoutes[i] = {
                ...errors.subwayRoutes[i],
                station: 'Обязательное поле',
            };
        }

        if (
            !Number.isInteger(Number(subwayRoute.duration)) ||
            Number(subwayRoute.duration) <= 0 ||
            String(subwayRoute.duration).replace(/^\d+$/g, '').length
        ) {
            errors.subwayRoutes[i] = {
                ...errors.subwayRoutes[i],
                duration: 'Должно быть числом',
            };
        }

        if (!subwayRoute.duration) {
            errors.subwayRoutes[i] = {
                ...errors.subwayRoutes[i],
                duration: 'Обязательное поле',
            };
        }
    });

    values.specialPrices?.forEach((specialPrice: SpecialPrice, i: number) => {
        if (!String(specialPrice.weekDay)) {
            errors.specialPrices[i] = {
                ...errors.specialPrices[i],
                weekDay: 'Обязательное поле',
            };
        }

        if (!specialPrice.timeStart) {
            errors.specialPrices[i] = {
                ...errors.specialPrices[i],
                timeStart: 'Обязательное поле',
            };
        }

        if (!specialPrice.timeFinish) {
            errors.specialPrices[i] = {
                ...errors.specialPrices[i],
                timeFinish: 'Обязательное поле',
            };
        }

        if (
            !Number.isInteger(Number(specialPrice.price)) ||
            Number(specialPrice.price) <= 0 ||
            String(specialPrice.price).replace(/^\d+$/g, '').length
        ) {
            errors.specialPrices[i] = {
                ...errors.specialPrices[i],
                price: 'Должно быть числом',
            };
        }

        if (!specialPrice.price) {
            errors.specialPrices[i] = {
                ...errors.specialPrices[i],
                price: 'Обязательное поле',
            };
        }

        if (
            moment(specialPrice.timeStart).utcOffset(0).format(TIME_FORMAT) ===
                '00:00' &&
            moment(specialPrice.timeFinish).utcOffset(0).format(TIME_FORMAT) ===
                '00:00'
        ) {
            errors.specialPrices[i] = {
                ...errors.specialPrices[i],
                timeStart: 'Обязательное поле',
                timeFinish: 'Обязательное поле',
            };
        }
    });

    values.workingHours?.forEach((workingHour: WorkingHours, i: number) => {
        if (!String(workingHour.weekDay)) {
            errors.workingHours[i] = {
                ...errors.workingHours[i],
                weekDay: 'Обязательное поле',
            };
        }

        if (!workingHour.timeStart) {
            errors.workingHours[i] = {
                ...errors.workingHours[i],
                timeStart: 'Обязательное поле',
            };
        }

        if (!workingHour.timeFinish) {
            errors.workingHours[i] = {
                ...errors.workingHours[i],
                timeFinish: 'Обязательное поле',
            };
        }

        if (
            moment(workingHour.timeStart).utcOffset(0).format(TIME_FORMAT) ===
                '00:00' &&
            moment(workingHour.timeFinish).utcOffset(0).format(TIME_FORMAT) ===
                '00:00'
        ) {
            errors.workingHours[i] = {
                ...errors.workingHours[i],
                timeStart: 'Обязательное поле',
                timeFinish: 'Обязательное поле',
            };
        }
    });

    if (
        (values.phone?.trim().length as number) > 0 &&
        !/[+]7\s[(][0-9]{3}[)]\s[0-9]{3}[-][0-9]{2}[-][0-9]{2}/.test(
            values.phone ?? ''
        )
    ) {
        errors.phone = 'Проверьте корректность номера';
    }

    const isEmpty = isEmptyError(errors);

    return isEmpty ? {} : errors;
};
