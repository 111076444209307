import React, { useEffect, useRef } from 'react';

interface ClickAwayWrapperProps {
    onClickAway: (event: MouseEvent | TouchEvent) => void;
    children: React.ReactNode;
}

export const ClickAwayWrapper: React.FC<ClickAwayWrapperProps> = React.memo(
    ({ onClickAway, children }) => {
        const wrapperRef = useRef<HTMLDivElement>(null);

        useEffect(() => {
            const handleClickOutside = (event: MouseEvent | TouchEvent) => {
                if (
                    wrapperRef.current &&
                    !wrapperRef.current.contains(event.target as Node)
                ) {
                    onClickAway(event);
                }
            };

            document.addEventListener('mousedown', handleClickOutside);
            document.addEventListener('touchstart', handleClickOutside);

            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
                document.removeEventListener('touchstart', handleClickOutside);
            };
        }, [onClickAway]);

        return <div ref={wrapperRef}>{children}</div>;
    }
);
