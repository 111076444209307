import * as React from 'react';
import cn from 'classnames';

import Plus from '@components/Icons/Plus';
import Download from '@components/Icons/Download';

import styles from './styles.module.css';

export interface ButtonProps
    extends Omit<
        React.DetailedHTMLProps<
            React.ButtonHTMLAttributes<HTMLButtonElement>,
            HTMLButtonElement
        >,
        'onClick'
    > {
    children: React.ReactNode;
    appearance: 'qr' | 'plus';
    icon?: 'download' | 'plus' | 'none';
    onClick: (
        e: React.MouseEvent<HTMLButtonElement>,
        ref: HTMLButtonElement | null
    ) => void;
}

export const ButtonNew = React.memo(
    React.forwardRef<HTMLButtonElement, ButtonProps>(
        (
            {
                appearance,
                icon = 'none',
                children,
                className,
                onClick,
                disabled,
                ...props
            },
            ref
        ): JSX.Element => {
            const localRef = React.useRef<HTMLButtonElement | null>(null);

            const handleRef = (_ref: HTMLButtonElement | null) => {
                localRef.current = _ref;

                if (typeof ref === 'function') {
                    ref(_ref);
                } else if (ref) {
                    (ref as React.MutableRefObject<HTMLButtonElement | null>).current = _ref;
                }
            };

            const handleClick = React.useCallback(
                (e: React.MouseEvent<HTMLButtonElement>) => {
                    onClick(e, localRef.current);
                },
                [onClick]
            );

            return (
                <button
                    ref={handleRef}
                    className={cn(styles.button, className, {
                        [styles.qr]: appearance === 'qr',
                        [styles.plus]: appearance === 'plus',
                        [styles.disabled]: !!disabled,
                    })}
                    onClick={handleClick}
                    disabled={disabled}
                    {...props}
                >
                    {icon === 'download' && (
                        <span className={styles.download}>
                            <Download />
                        </span>
                    )}
                    {icon === 'plus' && (
                        <span className={styles.plusIcon}>
                            <Plus />
                        </span>
                    )}
                    {children}
                </button>
            );
        }
    )
);
