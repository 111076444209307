import React, { useState } from 'react';
import { WrappedFieldProps } from 'redux-form';
import { DateRangePicker } from 'react-dates';
import moment from 'moment';

import { FILTER_DATE_FORMAT } from '@config';
import styles from './styles.module.css';

export interface Props {
    label: string;
    name: string;
    placeholder?: string;
    required?: boolean;
}

const DateRangePickerInput: React.FC<Props & WrappedFieldProps> = ({
    input,
    meta,
    name,
    label,
}) => {
    const [focusedInput, setFocusedInput] = useState<
        'startDate' | 'endDate' | null
    >(null);

    const originalValue = input.value || ':';
    const [dateStart, dateEnd] = originalValue.split(':');

    const dateStartMoment = moment(
        dateStart,
        FILTER_DATE_FORMAT,
        true
    ).isValid()
        ? moment(dateStart, FILTER_DATE_FORMAT)
        : null;

    const dateEndMoment = moment(dateEnd, FILTER_DATE_FORMAT, true).isValid()
        ? moment(dateEnd, FILTER_DATE_FORMAT)
        : null;

    const onDatesChange = ({
        startDate,
        endDate,
    }: {
        startDate: moment.Moment | null;
        endDate: moment.Moment | null;
    }) => {
        let newValue = '';
        if (startDate) {
            newValue += startDate.format(FILTER_DATE_FORMAT);
        }
        newValue += ':';
        if (endDate) {
            newValue += endDate.format(FILTER_DATE_FORMAT);
        }
        input.onChange(newValue);
    };

    const onFocusChange = (newValue: 'startDate' | 'endDate' | null) => {
        setFocusedInput(newValue);
    };

    return (
        <div className={styles.wrapper}>
            <label htmlFor={name} className={styles.label}>
                {label}
            </label>
            <DateRangePicker
                hideKeyboardShortcutsPanel
                startDate={dateStartMoment}
                startDateId="dateStart"
                endDate={dateEndMoment}
                endDateId="dateEnd"
                onDatesChange={onDatesChange}
                focusedInput={focusedInput}
                onFocusChange={onFocusChange}
                startDatePlaceholderText="От"
                endDatePlaceholderText="До"
                isOutsideRange={() => false}
            />
            {meta && meta.touched && meta.error && (
                <div className={styles.error}>{meta.error}</div>
            )}
        </div>
    );
};

export default DateRangePickerInput;
