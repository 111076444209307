import { Gym, GroupWorkout, City } from '@t/gym';
import { ApplicationState } from '@redux/types';

export const getGyms = (state: ApplicationState): Gym[] =>
    state.gyms.ids.map((id: string) => state.gyms.byId[id]);

export const getGym = (id: string) => (state: ApplicationState): Gym =>
    state.gyms.byId[id];

export const getNotificationsRequestPending = (
    state: ApplicationState
): boolean => state.gyms.notificationsRequestPending;

export const getHiddenGyms = (state: ApplicationState): Gym[] =>
    state.gyms.hiddenGymsIds.map((id: string) => state.gyms.hiddenGymsById[id]);

export const getHiddenGym = (id: string) => (state: ApplicationState): Gym =>
    state.gyms.hiddenGymsById[id];

export const getFoundGyms = (state: ApplicationState): Gym[] =>
    state.gyms.foundGymsIds.map((id: string) => state.gyms.foundGymsById[id]);

export const getFoundGym = (id: string) => (state: ApplicationState): Gym =>
    state.gyms.foundGymsById[id];

export const getGymsWithSpecialPrice = (state: ApplicationState): Gym[] =>
    state.gyms.withSpecialPriceIds.map(
        (id: string) => state.gyms.withSpecialPriceById[id]
    );

export const getGymWithSpecialPrice = (id: string) => (
    state: ApplicationState
): Gym => state.gyms.withSpecialPriceById[id];

export const getCurrentGym = (state: ApplicationState): Gym | undefined => {
    if (state.gyms.currentGym) {
        return state.gyms.byId[state.gyms.currentGym];
    } else {
        return undefined;
    }
};

export const getGymsList = (state: ApplicationState): Gym[] =>
    state.gyms.gymsList;

export const getCities = (state: ApplicationState): City[] => state.gyms.cities;

export const getGymGroupWorkoutsByGymId = (gymId: string) => (
    state: ApplicationState
): GroupWorkout[] =>
    (state.gyms.byId[gymId]?.groupWorkouts || []).map(
        (id: string) => state.gyms.groupWorkoutsById[id]
    );

export const getGroupWorkoutById = (groupWorkoutId: string) => (
    state: ApplicationState
): GroupWorkout => state.gyms.groupWorkoutsById[groupWorkoutId];

export const getAllGroupWorkouts = (
    state: ApplicationState
): GroupWorkout[] => {
    return state.gyms.groupWorkoutsIds.map(
        (id) => state.gyms.groupWorkoutsById[id]
    );
};

export const getCalendarGroupWorkouts = (
    state: ApplicationState
): GroupWorkout[] => state.gyms.calendarGroupWorkouts;

export const getSelectedGymId = (state: ApplicationState): string => {
    return state.gyms.selectedGymId;
};

export const getReservationsStatuses = (state: ApplicationState) =>
    state.gyms.reservationStatuses;

export const getReservationsOfGroupWorkout = (groupWorkoutId: string) => (
    state: ApplicationState
): GroupWorkout => state.gyms.groupWorkoutsById[groupWorkoutId];
