import axios from 'axios';
import { LegalEntity } from '@t/legal-entity';
import { B2B_API_URL } from '@config';
import { CommonRequestProps } from 'interfaces/CommonRequestProps';
import { User } from '@t/user';

export const getLegalEntitiesList = async ({
    token,
    headers: _headers,
    ...config
}: CommonRequestProps) => {
    const { data } = await axios.get<LegalEntity[]>(
        `${B2B_API_URL}/legal-entities/list`,
        {
            headers: {
                ..._headers,
                Authorization: `Bearer ${token}`,
            },
            ...config,
        }
    );

    return data;
};

export const getLegalEntity = async (
    entityId: string | null,
    { token, headers: _headers, ...config }: CommonRequestProps
) => {
    const { data } = await axios.get<LegalEntity>(
        `${B2B_API_URL}/legal-entities/${entityId}`,
        {
            headers: {
                ..._headers,
                Authorization: `Bearer ${token}`,
            },
            ...config,
        }
    );

    return data;
};

export const createLegalEntity = async ({
    token,
    headers: _headers,
    ...config
}: CommonRequestProps) => {
    const { data } = await axios.post<LegalEntity>(
        `${B2B_API_URL}/legal-entities`,
        {
            ...config,
            headers: {
                ..._headers,
                Authorization: `Bearer ${token}`,
            },
        }
    );

    return data;
};

export const deleteLegalEntity = async (
    entityId: string | null,
    { token, headers: _headers, ...config }: CommonRequestProps
) => {
    const { data } = await axios.delete<LegalEntity>(
        `${B2B_API_URL}/legal-entities/${entityId}`,
        {
            ...config,
            headers: {
                ..._headers,
                Authorization: `Bearer ${token}`,
            },
        }
    );

    return data;
};

export const deleteLegalEntityGym = async (
    entityId: string | null,
    gymId: string | null,
    { token, headers: _headers, ...config }: CommonRequestProps
) => {
    const { data } = await axios.put<LegalEntity>(
        `${B2B_API_URL}/legal-entities/${entityId}`,
        {
            ...config,
            headers: {
                ..._headers,
                Authorization: `Bearer ${token}`,
            },
        }
    );

    return data;
};

export const updateLegalEntity = async (
    entityId: string | null,
    { token, headers: _headers, ...config }: CommonRequestProps
) => {
    const { data } = await axios.put<User>(
        `${B2B_API_URL}/legal-entities/${entityId}`,
        {
            ...config,
            headers: {
                ..._headers,
                Authorization: `Bearer ${token}`,
            },
        }
    );

    return data;
};
