import * as React from 'react';

export const useStateRef = <T>(initialValue: T) => {
    const [state, setState] = React.useState<T>(initialValue);
    const ref = React.useRef<T>(state);

    const setStateRef = React.useCallback(
        (value: T | ((prevState: T) => T)) => {
            const newValue =
                typeof value === 'function'
                    ? (value as (prevState: T) => T)(ref.current)
                    : value;
            ref.current = newValue;
            setState(newValue);
        },
        []
    );

    return [state, setStateRef, ref] as const;
};
