import { combineReducers } from 'redux';
import { reducer as formReducer, FormStateMap } from 'redux-form';

import { authReducer } from '@redux/modules/auth/reducer';
import { gymReducer } from '@redux/modules/gyms/reducer';
import { bookingReducer } from '@redux/modules/bookings/reducer';
import {
    gymMembershipsReducer,
    membershipsReducer,
} from '@redux/modules/memberships/reducer';
import { reservationsReducer } from '@redux/modules/reservations/reducer';
import { filterReducer } from '@redux/modules/dashboard/reducer';
import { groupWorkoutsReducer } from '@redux/modules/group-workouts/reducer';
import { managerReducer } from '@redux/modules/managers/reducer';
import { legalentityReducer } from '@redux/modules/legal-entity/reducer';
import { reportReducer } from '@redux/modules/reports/reducer';
import { appUserReducer } from '@redux/modules/app-users/reducer';
import { couponsReducer } from '@redux/modules/coupons/reducer';
import { contactsReducer } from '@redux/modules/contacts/reducer';
import { tagCategorieReducer, tagReducer } from '@redux/modules/tags/reducer';
import { subscriptionsReducer } from '@redux/modules/subscriptions/reducer';
import { userSubscriptionsReducer } from '@redux/modules/subscriptions/reducer';
import { ApplicationState, FormName } from '@redux/types';
import { configReducer } from '@redux/modules/config/reducer';

export const rootReducer = combineReducers<ApplicationState>({
    auth: authReducer,
    gyms: gymReducer,
    bookings: bookingReducer,
    memberships: membershipsReducer,
    gymMemberships: gymMembershipsReducer,
    reservations: reservationsReducer,
    form: (formReducer as unknown) as (
        state: FormStateMap | undefined,
        action: any
    ) => FormStateMap & Record<FormName, any>,
    dashboard: filterReducer,
    groupWorkouts: groupWorkoutsReducer,
    managers: managerReducer,
    legalentitys: legalentityReducer,
    reports: reportReducer,
    appUsers: appUserReducer,
    coupons: couponsReducer,
    contacts: contactsReducer,
    tags: tagReducer,
    tagCategories: tagCategorieReducer,
    subscriptions: subscriptionsReducer,
    userSubscriptions: userSubscriptionsReducer,
    config: configReducer,
});
