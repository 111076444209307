import React, { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import cogoToast from 'cogo-toast';
import { formValueSelector } from 'redux-form';

import { ApplicationState, AsyncDispatch } from '@redux/types';
import BackButton from '@components/BackButton';
import { getUserSubscription } from '@redux/modules/subscriptions/selectors';
import {
    fetchUserSubscription,
    updateUserSubscription,
    deleteUserSubscription,
} from '@redux/modules/subscriptions/actions';
import { SubscriptionType } from '@t/subscriptions';

import styles from './styles.module.css';
import SubscriptionsForm, { SubscriptionFormData } from './Form';

export const SubscriptionsDetails: React.FC = memo(() => {
    const { id } = useParams<{ id: string }>();
    const dispatch = useDispatch<AsyncDispatch>();
    const history = useHistory();
    const userSubscription = useSelector(getUserSubscription(id || ''));
    const [disabled, setDisabled] = useState<boolean>(true);
    const [modalActive, setModalActive] = useState<boolean>(false);
    const selector = formValueSelector('subscriptionInfo');
    const subscriptionPaymentStatus = (state: ApplicationState) =>
        selector(state, 'paymentStatus');
    const paymentStatus = useSelector(subscriptionPaymentStatus);
    const finalizeSelector = (state: ApplicationState) =>
        selector(state, 'finalize');
    const finalize = useSelector(finalizeSelector);

    useEffect(() => {
        if (!id) {
            return;
        }
        dispatch(fetchUserSubscription(id));
    }, [dispatch, id]);

    const goToAppUserClick = useCallback(() => {
        if (userSubscription?.userId) {
            history.push(`/dashboard/app-users/${userSubscription.userId}`);
        }
    }, [history, userSubscription?.userId]);

    const onSubmit = useCallback(
        (values: SubscriptionFormData) => {
            if (!id) {
                return;
            }
            dispatch(updateUserSubscription({ id, values }))
                .then(() => {
                    cogoToast.success('Подписка обновлена', {
                        position: 'top-right',
                        hideAfter: 5,
                    });
                })
                .catch(() => {
                    cogoToast.error('Ошибка при обновлении подписки', {
                        position: 'top-right',
                        hideAfter: 4,
                    });
                })
                .finally(() => setDisabled(true));
        },
        [id, dispatch]
    );

    const deleteSubscription = useCallback(() => {
        if (!id) {
            return;
        }
        dispatch(deleteUserSubscription(id))
            .then((legalEntity) => {
                if (legalEntity) {
                    cogoToast.success('Подписка удалена', {
                        position: 'top-right',
                        hideAfter: 5,
                    });
                    history.push(`/dashboard/subscriptions`);
                }
            })
            .catch((e) => {
                cogoToast.error(
                    e.response.data.message
                        ? e.response.data.message
                        : 'Ошибка при удаление подписки',
                    {
                        position: 'top-right',
                        hideAfter: 4,
                    }
                );
            });
    }, [dispatch, id]);

    if (!userSubscription) {
        return null;
    }

    const isFinished = paymentStatus === SubscriptionType.CANCELLED;

    const initialValues = {
        userSubscriptionId: userSubscription._id,
        workoutType: userSubscription.workoutType,
        userId: userSubscription.userId,
        subscriptionId: userSubscription.subscriptionId,
        paymentStatus: userSubscription.paymentStatus,
        dateStarted: new Date(userSubscription.dateStarted)
            .toISOString()
            .slice(0, 16),
        nextPaymentDate: new Date(userSubscription.nextPaymentDate)
            .toISOString()
            .slice(0, 16),
        dateFinished: userSubscription.dateFinished
            ? new Date(userSubscription.dateFinished).toISOString().slice(0, 16)
            : '',
        finalize: userSubscription.dateFinished ? true : false,
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <BackButton
                    to="/dashboard/gyms"
                    title="К списку подписок"
                    className={styles.backBtn}
                />
                <div className={styles.box}>
                    <SubscriptionsForm
                        onSubmit={onSubmit}
                        initialValues={initialValues}
                        isFinished={isFinished}
                        disabled={disabled}
                        setDisabled={setDisabled}
                        goToAppUserClick={goToAppUserClick}
                        modalActive={modalActive}
                        setModalActive={setModalActive}
                        deleteSubscription={deleteSubscription}
                        finalize={finalize}
                    />
                </div>
            </div>
        </div>
    );
});

export default SubscriptionsDetails;
