import * as React from 'react';
import { AddGymForm } from './Form';
import { useDispatch } from 'react-redux';
import { fetchManagersList } from '@redux/modules/managers/actions';
import { fetchLegalEntitiesList } from '@redux/modules/legal-entity/actions';
import { fetchTags } from '@redux/modules/tags/actions';

const AddGym = React.memo(() => {
    const dispatch = useDispatch();

    React.useLayoutEffect(() => {
        dispatch(fetchManagersList());
        dispatch(fetchLegalEntitiesList());
        dispatch(fetchTags());
    }, []);

    return <AddGymForm />;
});

export default AddGym;
