import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import cogoToast from 'cogo-toast';
import moment from 'moment';

import BoxButton from '@components/BoxButton';
import { AsyncDispatch } from '@redux/types';
import BackButton from '@components/BackButton';
import {
    fetchGroupWorkoutNew,
    cancelGroupWorkoutSlot,
} from '@redux/modules/group-workouts/actions';
import { getGroupWorkout } from '@redux/modules/group-workouts/selectors';
import { fetchGymsList, selectGym } from '@redux/modules/gyms/actions';
import { fetchTagCategories, fetchTags } from '@redux/modules/tags/actions';
import { getGymsList, getSelectedGymId } from '@redux/modules/gyms/selectors';
import { getTagCategories, getTags } from '@redux/modules/tags/selectors';
import { TIME_FORMAT } from '@config';
import { GroupWorkoutNewData, ReservationsType } from '@t/gym';

import styles from './styles.module.css';
import TimeSlotForm, { TimeSlotFormData } from './Form';

function activeReservations(
    reservations: ReservationsType[]
): ReservationsType[] {
    const currentDate = moment().startOf('day').utc(false);
    return reservations.filter(
        (reservation: { date: string | number | Date }) =>
            moment(moment(reservation.date)).isSameOrAfter(currentDate)
    );
}

const getTimeSlotFromScheduleById = ({
    groupworkoutschedule,
    timeSlotId,
}: {
    groupworkoutschedule: {
        mon: GroupWorkoutNewData['groupworkoutschedule']['mon'];
        tue: GroupWorkoutNewData['groupworkoutschedule']['tue'];
        wed: GroupWorkoutNewData['groupworkoutschedule']['wed'];
        thu: GroupWorkoutNewData['groupworkoutschedule']['thu'];
        fri: GroupWorkoutNewData['groupworkoutschedule']['fri'];
        sat: GroupWorkoutNewData['groupworkoutschedule']['sat'];
        sun: GroupWorkoutNewData['groupworkoutschedule']['sun'];
    };
    timeSlotId: string;
}) => {
    let localTimeSlot = null;

    for (const [, timeSlotsArr] of Object.entries(groupworkoutschedule)) {
        const findById = timeSlotsArr.find((item) => item._id === timeSlotId);

        if (findById) {
            localTimeSlot = findById;
            break;
        }
    }

    return localTimeSlot;
};

export const TimeSlotDetails: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const { timeSlotId } = useParams<{ timeSlotId: string }>();
    const location = useLocation<{ dateStart: Date; screen: string }>();
    const date = moment(location.state?.dateStart)
        .startOf('day')
        .utc(true)
        .toDate();
    const groupWorkoutsScreen = location.state?.screen;
    const dispatch = useDispatch<AsyncDispatch>();
    const history = useHistory();
    const selectedGymId = useSelector(getSelectedGymId);
    const groupWorkout = useSelector(getGroupWorkout(id));
    const gymsList = useSelector(getGymsList);
    const tags = useSelector(getTags);
    const tagCategories = useSelector(getTagCategories);

    const [tagsCategoriesHelperItems, setTagsCategoriesHelperItems] = useState<
        string[]
    >([]);

    useEffect(() => {
        if (!id || !timeSlotId || !date) {
            return;
        }
        dispatch(fetchGymsList());
        dispatch(fetchTagCategories());
        const promises = [dispatch(fetchTags())];
        Promise.all(promises).then(() => {
            dispatch(
                fetchGroupWorkoutNew({
                    id: id,
                    timeSlotId,
                    date,
                })
            );
        });
    }, [dispatch, id, timeSlotId]);

    useEffect(() => {
        if (!groupWorkout || !tags.length || !tagCategories.length) {
            return;
        }
        const tagIds = groupWorkout.tags;
        const filteredTagByCategories = tags?.filter(
            (tag) => tagIds?.includes(tag._id) && tag.categoryIds
        );

        const categoryIds = new Set<string>();

        for (const filteredTag of filteredTagByCategories) {
            filteredTag.categoryIds.forEach((id) => categoryIds.add(id));
        }

        const categories = tagCategories?.reduce<string[]>((acc, category) => {
            if (categoryIds.has(category._id)) {
                acc.push(category.title);
            }
            return acc;
        }, []);

        setTagsCategoriesHelperItems(categories);
    }, [tags, groupWorkout]);

    const goToTimeSlotReservations = useCallback(
        () =>
            history.push(
                `/dashboard/group-workouts/${id}/timeslots/${timeSlotId}/reservations`,
                {
                    dateStart: date,
                }
            ),
        [history]
    );

    const goToGroupWorkout = useCallback(
        () => history.push(`/dashboard/group-workouts/${id}`),
        [history]
    );

    const goToCalendar = useCallback(() => {
        dispatch(selectGym(selectedGymId));
        const path = groupWorkoutsScreen
            ? `/dashboard/group-workouts/calendar`
            : `/dashboard/group-workouts/${id}/calendar`;
        history.push(path);
    }, [dispatch]);

    const onSubmit = (values: TimeSlotFormData) => {
        if (!id) {
            return;
        }

        if (
            groupWorkout.groupworkoutschedule.reservations.length &&
            activeReservations(groupWorkout.groupworkoutschedule.reservations)
                .length
        ) {
            return cogoToast.warn('Есть активные бронирования', {
                position: 'top-right',
                hideAfter: 4,
            });
        }

        dispatch(
            cancelGroupWorkoutSlot({
                id,
                cancelledEvents: groupWorkout?.groupworkoutschedule
                    ?.cancelledEvents?.length
                    ? [
                          ...groupWorkout.groupworkoutschedule.cancelledEvents,
                          {
                              cancelDate: values.date,
                              timeSlot: timeSlotId,
                          },
                      ]
                    : [
                          {
                              cancelDate: values.date,
                              timeSlot: timeSlotId,
                          },
                      ],
            })
        )
            .then(() => {
                cogoToast.success('Слот отменён', {
                    position: 'top-right',
                    hideAfter: 4,
                });
                history.push(`/dashboard/group-workouts/${id}`);
            })
            .catch(() => {
                cogoToast.error('Ошибка при отмене слота', {
                    position: 'top-right',
                    hideAfter: 4,
                });
            });
    };

    if (!groupWorkout) {
        return null;
    }

    const gymOptions = gymsList.reduce<{ label: string; value: string }[]>(
        (acc, gym) => {
            if (gym.hasGroupWorkouts) {
                acc.push({
                    label: gym.title,
                    value: gym._id,
                });
            }
            return acc;
        },
        []
    );

    const gymValue = gymOptions.filter(
        (gymOption) => gymOption.value === groupWorkout.gymId
    );

    const tagOptions = tags.reduce<{ label: string; value: string }[]>(
        (acc, tag) => {
            if (
                tag.tagType.find(
                    (type) => type.tagTypeValue === 'GROUPWORKOUTFILTER'
                )
            ) {
                acc.push({
                    label: tag.tagName,
                    value: tag._id,
                });
            }
            return acc;
        },
        []
    );

    if (!groupWorkout?.tags?.length) {
        return null;
    }

    const tagValue = tagOptions.filter(
        (tagOption: { label: string; value: string }) =>
            groupWorkout?.tags?.includes(tagOption.value)
    );

    if (!gymValue.length) {
        return null;
    }

    const timeSlotObjectById = getTimeSlotFromScheduleById({
        timeSlotId: timeSlotId,
        groupworkoutschedule: {
            mon: groupWorkout.groupworkoutschedule.mon,
            tue: groupWorkout.groupworkoutschedule.tue,
            wed: groupWorkout.groupworkoutschedule.wed,
            thu: groupWorkout.groupworkoutschedule.thu,
            fri: groupWorkout.groupworkoutschedule.fri,
            sat: groupWorkout.groupworkoutschedule.sat,
            sun: groupWorkout.groupworkoutschedule.sun,
        },
    });

    const initialValues = {
        _id: groupWorkout._id,
        title: groupWorkout.title,
        gymId: groupWorkout.gymId,
        description: groupWorkout.description,
        date: moment(date).utcOffset(0).format('YYYY-MM-DD'),
        timeStart: timeSlotObjectById
            ? moment(timeSlotObjectById.timeStart)
                  .utcOffset(0)
                  .format(TIME_FORMAT)
            : moment(),
        duration: timeSlotObjectById
            ? Math.round(
                  moment
                      .duration(
                          moment(timeSlotObjectById?.timeFinish).diff(
                              moment(timeSlotObjectById?.timeStart)
                          )
                      )
                      .asMinutes()
              )
            : 60,
        price: groupWorkout.price,
        limit: groupWorkout.limit,
        genderType: groupWorkout.genderType,
    };

    return (
        <div>
            <BackButton
                title="В календарь"
                className={styles.backBtn}
                onClick={goToCalendar}
            />
            <div className={styles.actions}>
                <div className={styles.nav}>
                    <BoxButton
                        icon=""
                        title="Общая информация"
                        className={styles.actionNav}
                    />
                    <BoxButton
                        icon=""
                        title="Бронирования"
                        onClick={goToTimeSlotReservations}
                        className={styles.actionBtn}
                    />
                </div>
            </div>
            <div className={styles.container}>
                <TimeSlotForm
                    form="timeSlotInfo"
                    onSubmit={onSubmit}
                    initialValues={initialValues}
                    gymOptions={gymOptions}
                    tagOptions={tagOptions}
                    tagValue={tagValue}
                    gymValue={gymValue}
                    tagsCategoriesHelperItems={tagsCategoriesHelperItems}
                    goToGroupWorkout={goToGroupWorkout}
                />
            </div>
        </div>
    );
};

export default TimeSlotDetails;
