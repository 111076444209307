import * as React from 'react';
import ReactDOM from 'react-dom';
import styles from './styles.module.css';

export const Portal = React.memo<{
    target: HTMLElement | null;
    node?: React.ReactNode;
    nodeWrapperStyle?: React.CSSProperties;
}>(({ target, node, nodeWrapperStyle }) => {
    if (target instanceof HTMLElement && node) {
        return ReactDOM.createPortal(
            <div
                className={styles.portal}
                style={nodeWrapperStyle}
                data-test-id="gogym__portal"
            >
                {node}
            </div>,
            target
        );
    }

    return null;
});
