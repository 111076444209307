import { User, UserRole } from '@t/user';

import { AuthState } from './types';

interface State {
    [key: string]: any;
    auth: AuthState;
}

export const getToken = (state: State): string => state.auth.token;
export const getUser = (state: State): User | null | undefined =>
    state.auth.user;
export const getIsUserSuperAdmin = (state: State): boolean =>
    state.auth.user?.role === UserRole.SuperAdmin;
export const getIsUserAdmin = (state: State): boolean =>
    state.auth.user?.role === UserRole.Admin;
export const getDataUrl = (state: State): string => state.auth.dataUrl;
export const getQrVerified = (state: State): boolean => state.auth.qrVerified;
