import * as React from 'react';
import classnames from 'classnames';
import { Field } from 'formik';
import { createFilter, Theme, MultiValue } from 'react-select';
import { useSelector } from 'react-redux';

import { getIsUserSuperAdmin } from '@redux/modules/auth/selectors';
import { SelectOption } from 'interfaces/SelectOption';
import { AddGymFormValues } from 'interfaces/AddGymFormValues';

import styles from './styles.module.css';
import { FormSection, TFormSectionRow } from './FormSection';

interface AddGymSuperAdminHeaderProps {
    values: any;
    setFieldValue: any;
    errors: any;
    allManagersOptions: SelectOption[];
    legalEntitysOptions: SelectOption[];
}

const fieldsMatrix = [
    [
        {
            name: 'authorized',
            label: 'Авторизован',
        },
        {
            name: 'deleted',
            label: 'Скрыт с карты',
        },
        {
            name: 'isHidden',
            label: 'Удалить из списка',
        },
        {
            name: 'test',
            label: 'Тестовый',
        },
    ],
    [
        {
            name: 'withMembershipsOption',
            label: 'Абонементы',
        },
        {
            name: 'withGroupWorkoutsOption',
            label: 'Групповые тренировки',
        },
        {
            name: 'spirit',
            label: 'Spirit',
        },
        {
            name: 'seasonal',
            label: 'Сезонное',
        },
    ],
];

const getCurrentLegalEntityOption = (legalEntitysOptions: SelectOption[]) => (
    legalEntityId: string
) => {
    return legalEntitysOptions.filter(
        (legalEntitysOption) => legalEntitysOption.value === legalEntityId
    );
};

export const AddGymSuperAdminHeader = React.memo<AddGymSuperAdminHeaderProps>(
    ({
        values,
        setFieldValue,
        errors,
        legalEntitysOptions,
        allManagersOptions,
    }) => {
        const [selectedManagerIds, setSelectedManagerIds] = React.useState<
            SelectOption[]
        >([]);
        const isSuperAdmin = useSelector(getIsUserSuperAdmin);

        const selectFields = React.useMemo(() => {
            if (!isSuperAdmin) {
                return [];
            }

            const adjustSelectTheme = (theme: Theme) => ({
                ...theme,
                colors: {
                    ...theme.colors,
                    primary: 'rgb(225, 129, 65)',
                },
            });

            const filterOption = createFilter({
                stringify: (option) => `${option.label}`,
            });

            const rows: TFormSectionRow<AddGymFormValues>[][] = [
                [
                    {
                        type: 'select',
                        name: 'legalSelect',
                        options: legalEntitysOptions,
                        placeholder: 'Выберите из списка',
                        noOptionsMessage: 'Не найдено',
                        label: 'Юридическое лицо',
                        value: getCurrentLegalEntityOption(legalEntitysOptions)(
                            values.legalEntityId
                        ),
                        onSelectChange: (e) => {
                            setFieldValue(
                                'legalEntityId',
                                e && (e as SelectOption).value
                            );
                        },
                        selectProps: {
                            filterOption,
                            styles: {
                                input: (provided) => ({
                                    ...provided,
                                    minHeight: 40,
                                    borderRadius: '0px 0px 20px 20px',
                                }),
                            },
                            theme: adjustSelectTheme,
                        },
                    },
                    {
                        type: 'select',
                        name: 'managersSelect',
                        value: selectedManagerIds,
                        label: 'Менеджер',
                        options: allManagersOptions,
                        placeholder: 'Выберите из списка',
                        onSelectChange: (_e) => {
                            const e = (_e as unknown) as MultiValue<SelectOption>;

                            const selectedManagerIds = e.map((el) => el.value);

                            setSelectedManagerIds(e as SelectOption[]);
                            setFieldValue('managersId', selectedManagerIds);
                        },
                        selectProps: {
                            isMulti: true,
                            filterOption,
                            styles: {
                                input: (provided) => ({
                                    ...provided,
                                    minHeight: 40,
                                    borderRadius: '8px',
                                }),
                            },
                            closeMenuOnSelect: false,
                        },
                    },
                ],
            ];

            return rows;
        }, [
            values.legalEntityId,
            legalEntitysOptions,
            allManagersOptions,
            isSuperAdmin,
            selectedManagerIds,
        ]);

        const mappedFields = React.useMemo(
            () =>
                isSuperAdmin &&
                fieldsMatrix.map((row, index) => (
                    <div
                        key={['field', index].join('_')}
                        className={styles.row}
                    >
                        {row.map(({ name, label }, index) => (
                            <label
                                key={[name, index].join('_')}
                                className={classnames(
                                    styles.checkboxLabel,
                                    styles.checkboxHeader
                                )}
                            >
                                <Field
                                    className={styles.checkbox}
                                    type="checkbox"
                                    name={name}
                                />
                                {label}
                            </label>
                        ))}
                    </div>
                )),
            [isSuperAdmin]
        );

        if (!isSuperAdmin) {
            return null;
        }

        return (
            <div className={styles.headerContainer}>
                <div className={styles.options}>
                    Параметры карточки площадки
                </div>
                <div className={styles.headerCheckboxesContainer}>
                    {mappedFields}
                </div>
                <div className={classnames(styles.row, styles.rowGap)}>
                    <div className={styles.element}>
                        <FormSection
                            setFieldValue={setFieldValue}
                            rows={selectFields}
                            errors={errors}
                        />
                    </div>
                </div>
            </div>
        );
    }
);
