import axios from 'axios';
import qs from 'qs';

import { B2B_API_URL, REPORTS_API_URL } from '@config';
import { AsyncActionCreator } from '@redux/types';
import { Subscription, UserSubscription } from '@t/subscriptions';
import { SubscriptionFormData } from '@containers/Dashboard/SubscriptionsDetails/Form';
import { AddUserSubscriptionFormData } from '@containers/Dashboard/AddSubscription/Form';
import { AddGoGymSubscriptionFormData } from '@containers/Dashboard/AddGoGymSubscription/Form';

import {
    FETCH_SUBSCRIPTIONS_REQUEST,
    FETCH_SUBSCRIPTIONS_SUCCESS,
    FETCH_SUBSCRIPTIONS_ERROR,
    FETCH_SUBSCRIPTION_REQUEST,
    FETCH_SUBSCRIPTION_SUCCESS,
    FETCH_SUBSCRIPTION_ERROR,
    FETCH_USER_SUBSCRIPTIONS_REQUEST,
    FETCH_USER_SUBSCRIPTIONS_SUCCESS,
    FETCH_USER_SUBSCRIPTIONS_ERROR,
    FETCH_USER_SUBSCRIPTION_REQUEST,
    FETCH_USER_SUBSCRIPTION_SUCCESS,
    FETCH_USER_SUBSCRIPTION_ERROR,
    UPDATE_USER_SUBSCRIPTION_REQUEST,
    UPDATE_USER_SUBSCRIPTION_SUCCESS,
    UPDATE_USER_SUBSCRIPTION_ERROR,
    FETCH_USER_SUBSCRIPTIONS_FOR_REPORT_REQUEST,
    FETCH_USER_SUBSCRIPTIONS_FOR_REPORT_SUCCESS,
    FETCH_USER_SUBSCRIPTIONS_FOR_REPORT_ERROR,
    CREATE_USER_SUBSCRIPTION_REQUEST,
    CREATE_USER_SUBSCRIPTION_SUCCESS,
    CREATE_USER_SUBSCRIPTION_ERROR,
    DELETE_USER_SUBSCRIPTION_REQUEST,
    DELETE_USER_SUBSCRIPTION_SUCCESS,
    DELETE_USER_SUBSCRIPTION_ERROR,
    CREATE_GOGYM_SUBSCRIPTION_REQUEST,
    CREATE_GOGYM_SUBSCRIPTION_SUCCESS,
    CREATE_GOGYM_SUBSCRIPTION_ERROR,
    FETCH_GOGYM_SUBSCRIPTION_REQUEST,
    FETCH_GOGYM_SUBSCRIPTION_SUCCESS,
    FETCH_GOGYM_SUBSCRIPTION_ERROR,
    UPDATE_GOGYM_SUBSCRIPTION_REQUEST,
    UPDATE_GOGYM_SUBSCRIPTION_SUCCESS,
    UPDATE_GOGYM_SUBSCRIPTION_ERROR,
} from './types';

export const fetchSubscriptions: AsyncActionCreator<
    string | null | void,
    Subscription[]
> = (selectedGymId?: string | null | void) => async (dispatch, getState) => {
    dispatch({
        type: FETCH_SUBSCRIPTIONS_REQUEST,
    });

    const {
        auth: { token },
    } = getState();
    try {
        const { data: subscriptions } = await axios.get(
            `${B2B_API_URL}/subscriptions`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    selectedGymId,
                    type: 'GOGYM',
                },
                paramsSerializer: (params) => {
                    return qs.stringify(params, { arrayFormat: 'repeat' });
                },
            }
        );

        dispatch({
            type: FETCH_SUBSCRIPTIONS_SUCCESS,
            payload: { subscriptions },
        });

        return subscriptions;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: FETCH_SUBSCRIPTIONS_ERROR,
            });

            throw err;
        }
    }
};

export const fetchSubscription: AsyncActionCreator<string> = (
    id?: string
) => async (dispatch, getState) => {
    dispatch({
        type: FETCH_SUBSCRIPTION_REQUEST,
    });

    const {
        auth: { token },
    } = getState();

    try {
        const { data: subscription } = await axios.get(
            `${B2B_API_URL}/subscriptions/${id}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: { type: 'GOGYM' },
            }
        );

        dispatch({
            type: FETCH_SUBSCRIPTION_SUCCESS,
            payload: subscription,
        });

        return subscription;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: FETCH_SUBSCRIPTION_ERROR,
            });

            throw err;
        }
    }
};

export const fetchUserSubscriptions: AsyncActionCreator<
    string | null | void,
    UserSubscription[]
> = (selectedGymId?: string | null | void) => async (dispatch, getState) => {
    dispatch({
        type: FETCH_USER_SUBSCRIPTIONS_REQUEST,
    });

    const {
        auth: { token },
    } = getState();
    try {
        const { data: userSubscriptions } = await axios.get(
            `${B2B_API_URL}/subscriptions`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    selectedGymId,
                    type: 'USER',
                },
                paramsSerializer: (params) => {
                    return qs.stringify(params, { arrayFormat: 'repeat' });
                },
            }
        );

        dispatch({
            type: FETCH_USER_SUBSCRIPTIONS_SUCCESS,
            payload: { userSubscriptions },
        });

        return userSubscriptions;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: FETCH_USER_SUBSCRIPTIONS_ERROR,
            });

            throw err;
        }
    }
};

export const fetchUserSubscription: AsyncActionCreator<string> = (
    id?: string
) => async (dispatch, getState) => {
    dispatch({
        type: FETCH_USER_SUBSCRIPTION_REQUEST,
    });

    const {
        auth: { token },
    } = getState();

    try {
        const { data: subscription } = await axios.get(
            `${B2B_API_URL}/subscriptions/${id}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: { type: 'USER' },
            }
        );

        dispatch({
            type: FETCH_USER_SUBSCRIPTION_SUCCESS,
            payload: subscription,
        });

        return subscription;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: FETCH_USER_SUBSCRIPTION_ERROR,
            });

            throw err;
        }
    }
};

export const updateUserSubscription: AsyncActionCreator<
    { id: string; values: SubscriptionFormData },
    UserSubscription
> = ({ id, values }) => async (dispatch, getState) => {
    dispatch({
        type: UPDATE_USER_SUBSCRIPTION_REQUEST,
    });

    const {
        auth: { token },
    } = getState();

    const newValues = {
        ...values,
        dateFinished: values.finalize
            ? new Date(
                  new Date(values.dateFinished).getTime() -
                      new Date(values.dateFinished).getTimezoneOffset() * 60000
              )
            : undefined,
        dateStarted: new Date(
            new Date(values.dateStarted).getTime() -
                new Date(values.dateStarted).getTimezoneOffset() * 60000
        ),
        nextPaymentDate: new Date(
            new Date(values.nextPaymentDate).getTime() -
                new Date(values.nextPaymentDate).getTimezoneOffset() * 60000
        ),
    };

    try {
        const { data: subscription } = await axios.put(
            `${B2B_API_URL}/subscriptions/${id}`,
            newValues,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: { type: 'USER' },
            }
        );

        dispatch({
            type: UPDATE_USER_SUBSCRIPTION_SUCCESS,
            payload: subscription,
        });

        return subscription;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: UPDATE_USER_SUBSCRIPTION_ERROR,
            });

            throw err;
        }
    }
};

export const fetchUserSubscriptionForReport: AsyncActionCreator<
    string | null | void,
    UserSubscription[]
> = () => async (dispatch, getState) => {
    dispatch({
        type: FETCH_USER_SUBSCRIPTIONS_FOR_REPORT_REQUEST,
    });

    const {
        auth: { token },
    } = getState();
    try {
        const { data } = await axios.get(
            `${REPORTS_API_URL}/subscriptions/list`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        dispatch({
            type: FETCH_USER_SUBSCRIPTIONS_FOR_REPORT_SUCCESS,
            payload: data,
        });

        return data;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: FETCH_USER_SUBSCRIPTIONS_FOR_REPORT_ERROR,
            });

            throw err;
        }
    }
};

export const searchUserSubscriptions: AsyncActionCreator<
    { value: string },
    UserSubscription[]
> = ({ value }) => async (dispatch, getState) => {
    dispatch({
        type: FETCH_USER_SUBSCRIPTIONS_REQUEST,
    });

    const {
        auth: { token },
    } = getState();

    try {
        const { data: userSubscriptions } = await axios.get(
            `${B2B_API_URL}/subscriptions`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    type: 'USER',
                    value,
                },
            }
        );

        dispatch({
            type: FETCH_USER_SUBSCRIPTIONS_SUCCESS,
            payload: { userSubscriptions },
        });

        return userSubscriptions;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: FETCH_USER_SUBSCRIPTIONS_ERROR,
            });

            throw err;
        }
    }
};

export const createUserSubscriptions: AsyncActionCreator<
    AddUserSubscriptionFormData,
    UserSubscription
> = (values) => async (dispatch, getState) => {
    dispatch({
        type: CREATE_USER_SUBSCRIPTION_REQUEST,
    });

    const {
        auth: { token },
    } = getState();

    const newValues = { ...values };

    try {
        const { data: userSubscription } = await axios.post(
            `${B2B_API_URL}/subscriptions`,
            newValues,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: { type: 'USER' },
            }
        );

        dispatch({
            type: CREATE_USER_SUBSCRIPTION_SUCCESS,
            payload: userSubscription,
        });

        return userSubscription;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: CREATE_USER_SUBSCRIPTION_ERROR,
            });

            throw err;
        }
    }
};

export const deleteUserSubscription: AsyncActionCreator<string> = (
    id
) => async (dispatch, getState) => {
    dispatch({
        type: DELETE_USER_SUBSCRIPTION_REQUEST,
    });

    const {
        auth: { token },
    } = getState();

    try {
        const { data: userSubscription } = await axios.delete(
            `${B2B_API_URL}/subscriptions/${id}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: { type: 'USER' },
            }
        );

        dispatch({
            type: DELETE_USER_SUBSCRIPTION_SUCCESS,
            payload: userSubscription,
        });

        return userSubscription;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: DELETE_USER_SUBSCRIPTION_ERROR,
            });

            throw err;
        }
    }
};

export const fetchGoGymSubscription: AsyncActionCreator<string> = (
    id?: string
) => async (dispatch, getState) => {
    dispatch({
        type: FETCH_GOGYM_SUBSCRIPTION_REQUEST,
    });

    const {
        auth: { token },
    } = getState();

    try {
        const { data: subscription } = await axios.get(
            `${B2B_API_URL}/subscriptions/gogym/${id}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        dispatch({
            type: FETCH_GOGYM_SUBSCRIPTION_SUCCESS,
            payload: subscription,
        });

        return subscription;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: FETCH_GOGYM_SUBSCRIPTION_ERROR,
            });

            throw err;
        }
    }
};

export const createGoGymSubscription: AsyncActionCreator<
    {
        values: AddGoGymSubscriptionFormData;
        iconsFormData: FormData;
    },
    Subscription
> = ({ values, iconsFormData }) => async (dispatch, getState) => {
    dispatch({
        type: CREATE_GOGYM_SUBSCRIPTION_REQUEST,
    });

    const {
        auth: { token },
    } = getState();

    const newValues = { ...values };

    try {
        const { data: subscription } = await axios.post(
            `${B2B_API_URL}/subscriptions/gogym`,
            newValues,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        if (
            iconsFormData.has('icons') ||
            iconsFormData.has('advantagesIcons')
        ) {
            await axios.put(
                `${B2B_API_URL}/subscriptions/gogym/icon-upload/${subscription._id}`,
                iconsFormData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-type': 'multipart/form-data',
                    },
                }
            );
        }

        dispatch({
            type: CREATE_GOGYM_SUBSCRIPTION_SUCCESS,
            payload: subscription,
        });

        return subscription;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: CREATE_GOGYM_SUBSCRIPTION_ERROR,
            });

            throw err;
        }
    }
};

export const updateGoGymSubscription: AsyncActionCreator<
    {
        id: string;
        values: AddGoGymSubscriptionFormData;
        iconsFormData: FormData;
    },
    Subscription
> = ({ id, values, iconsFormData }) => async (dispatch, getState) => {
    dispatch({
        type: UPDATE_GOGYM_SUBSCRIPTION_REQUEST,
    });

    const {
        auth: { token },
    } = getState();

    const newValues = { ...values };

    try {
        const { data: subscription } = await axios.put(
            `${B2B_API_URL}/subscriptions/gogym/${id}`,
            newValues,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        if (
            iconsFormData.has('icons') ||
            iconsFormData.has('advantagesIcons')
        ) {
            await axios.put(
                `${B2B_API_URL}/subscriptions/gogym/icon-upload/${subscription._id}`,
                iconsFormData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-type': 'multipart/form-data',
                    },
                }
            );
        }

        dispatch({
            type: UPDATE_GOGYM_SUBSCRIPTION_SUCCESS,
            payload: subscription,
        });

        return subscription;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: UPDATE_GOGYM_SUBSCRIPTION_ERROR,
            });

            throw err;
        }
    }
};
