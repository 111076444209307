import axios from 'axios';
import { B2B_API_URL } from '@config';
import { CommonRequestProps } from 'interfaces/CommonRequestProps';
import { User } from '@t/user';

export const getManagersList = async ({
    token,
    headers,
    ...config
}: CommonRequestProps) => {
    const { data } = await axios.get<User[]>(`${B2B_API_URL}/managers/list`, {
        headers: {
            Authorization: `Bearer ${token}`,
            ...headers,
        },
        ...config,
    });

    return data;
};
