import axios from 'axios';
import qs from 'qs';

import { CorporateDomains, User } from '@t/user';
import { B2B_API_URL } from '@config';
import { AsyncActionCreator, ReduxAction } from '@redux/types';
import { ManagerFormData } from '@containers/Dashboard/ManagerDetails/Form';

import {
    FETCH_MANAGERS_REQUEST,
    FETCH_MANAGERS_SUCCESS,
    FETCH_MANAGERS_ERROR,
    FETCH_MANAGER_REQUEST,
    FETCH_MANAGER_SUCCESS,
    FETCH_MANAGER_ERROR,
    DELETE_MANAGER_REQUEST,
    DELETE_MANAGER_SUCCESS,
    DELETE_MANAGER_ERROR,
    UPDATE_MANAGER_REQUEST,
    UPDATE_MANAGER_SUCCESS,
    UPDATE_MANAGER_ERROR,
    DELETE_MANAGER_GYM_REQUEST,
    DELETE_MANAGER_GYM_SUCCESS,
    DELETE_MANAGER_GYM_ERROR,
    FETCH_MANAGER_CORPORATE_DOMAINS_REQUEST,
    FETCH_MANAGER_CORPORATE_DOMAINS_SUCCESS,
    FETCH_MANAGER_CORPORATE_DOMAINS_ERROR,
    SELECT_MANAGER,
    FETCH_MANAGERS_LIST_REQUEST,
    FETCH_MANAGERS_LIST_SUCCESS,
    FETCH_MANAGERS_LIST_ERROR,
    FETCH_SCROLL_MANAGERS_REQUEST,
    FETCH_SCROLL_MANAGERS_SUCCESS,
    FETCH_SCROLL_MANAGERS_ERROR,
} from './types';
import { signOut } from '../auth/actions';
import { getManagersList } from '@utils/requests/managers';

export const fetchManagers: AsyncActionCreator<number, User[]> = (
    count?: number
) => async (dispatch, getState) => {
    dispatch({
        type: FETCH_MANAGERS_REQUEST,
    });

    const {
        auth: { token },
    } = getState();
    try {
        const { data: managers } = await axios.get(`${B2B_API_URL}/managers`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: { count },
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: 'repeat' });
            },
        });

        dispatch({
            type: FETCH_MANAGERS_SUCCESS,
            payload: {
                managers,
            },
        });

        return managers;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: FETCH_MANAGERS_ERROR,
            });

            throw err;
        }
    }
};

export const fetchScrollManagers: AsyncActionCreator<number, User[]> = (
    count?: number
) => async (dispatch, getState) => {
    dispatch({
        type: FETCH_SCROLL_MANAGERS_REQUEST,
    });

    const {
        auth: { token },
    } = getState();
    try {
        const { data: managers } = await axios.get(`${B2B_API_URL}/managers`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: { count },
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: 'repeat' });
            },
        });

        dispatch({
            type: FETCH_SCROLL_MANAGERS_SUCCESS,
            payload: {
                managers,
            },
        });

        return managers;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: FETCH_SCROLL_MANAGERS_ERROR,
            });

            throw err;
        }
    }
};

export const searchManagers: AsyncActionCreator<string, User[]> = (
    value?: string
) => async (dispatch, getState) => {
    dispatch({
        type: FETCH_MANAGERS_REQUEST,
    });

    const {
        auth: { token },
    } = getState();
    try {
        const { data: managers } = await axios.get(`${B2B_API_URL}/managers`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: { value },
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: 'repeat' });
            },
        });

        dispatch({
            type: FETCH_MANAGERS_SUCCESS,
            payload: {
                managers,
            },
        });

        return managers;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: FETCH_MANAGERS_ERROR,
            });

            throw err;
        }
    }
};

export const fetchManager: AsyncActionCreator<string, User> = (
    id?: string
) => async (dispatch, getState) => {
    dispatch({
        type: FETCH_MANAGER_REQUEST,
    });

    const {
        auth: { token },
    } = getState();

    try {
        const { data: manager } = await axios.get(
            `${B2B_API_URL}/managers/${id}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        dispatch({
            type: FETCH_MANAGER_SUCCESS,
            payload: manager,
        });

        return manager;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: FETCH_MANAGER_ERROR,
            });

            throw err;
        }
    }
};

export const deleteManager: AsyncActionCreator<string> = (id) => async (
    dispatch,
    getState
) => {
    dispatch({
        type: DELETE_MANAGER_REQUEST,
    });

    const {
        auth: { token },
    } = getState();

    try {
        const { data: manager } = await axios.delete(
            `${B2B_API_URL}/managers/${id}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        dispatch({
            type: DELETE_MANAGER_SUCCESS,
            payload: { manager },
        });

        return manager;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: DELETE_MANAGER_ERROR,
            });

            throw err;
        }
    }
};

export const updateManager: AsyncActionCreator<
    { id: string; values: ManagerFormData; gyms: string[]; phone: string },
    User
> = ({ id, values, gyms, phone }) => async (dispatch, getState) => {
    dispatch({
        type: UPDATE_MANAGER_REQUEST,
    });

    const {
        auth: { token },
    } = getState();

    const newValues = { ...values, gyms, phone };

    try {
        const { data: manager } = await axios.put(
            `${B2B_API_URL}/managers/${id}`,
            newValues,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        dispatch({
            type: UPDATE_MANAGER_SUCCESS,
            payload: manager,
        });

        return manager;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: UPDATE_MANAGER_ERROR,
            });

            throw err;
        }
    }
};

export const deleteManagerGym: AsyncActionCreator<{
    gymId: string;
    id: string;
}> = ({ gymId, id }) => async (dispatch, getState) => {
    dispatch({
        type: DELETE_MANAGER_GYM_REQUEST,
    });

    const {
        auth: { token },
    } = getState();

    try {
        const { data: manager } = await axios.delete(
            `${B2B_API_URL}/managers/${id}/${gymId}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        dispatch({
            type: DELETE_MANAGER_GYM_SUCCESS,
            payload: manager,
        });

        return manager;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: DELETE_MANAGER_GYM_ERROR,
            });

            throw err;
        }
    }
};

export const fetchCorporateDomains: AsyncActionCreator<
    void,
    CorporateDomains[]
> = () => async (dispatch, getState) => {
    dispatch({
        type: FETCH_MANAGER_CORPORATE_DOMAINS_REQUEST,
    });

    const {
        auth: { token },
    } = getState();

    try {
        const { data: corporateDomains } = await axios.get(
            `${B2B_API_URL}/corporate/domains`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        dispatch({
            type: FETCH_MANAGER_CORPORATE_DOMAINS_SUCCESS,
            payload: { corporateDomains },
        });

        return corporateDomains;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: FETCH_MANAGER_CORPORATE_DOMAINS_ERROR,
            });

            throw err;
        }
    }
};

export const fetchManagersList: AsyncActionCreator<void, User[]> = () => async (
    dispatch,
    getState
) => {
    dispatch({
        type: FETCH_MANAGERS_LIST_REQUEST,
    });

    const {
        auth: { token },
    } = getState();

    try {
        const managersList = await getManagersList({ token });

        dispatch({
            type: FETCH_MANAGERS_LIST_SUCCESS,
            payload: { managersList },
        });

        return managersList;
    } catch (err) {
        dispatch(signOut());
        if (err instanceof Error) {
            dispatch({
                type: FETCH_MANAGERS_LIST_ERROR,
            });
        }

        throw err;
    }
};

export const selectManager = (
    values: string | null
): ReduxAction<string | null> => ({
    type: SELECT_MANAGER,
    payload: values,
});
