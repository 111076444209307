import * as React from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState, FormName } from '@redux/types';

export const usePartnersForm = <T>() => {
    const { registeredFields, values } = useSelector(
        (state: ApplicationState) =>
            state.form?.[FormName.PartnerActionsForm] ?? {}
    );

    return {
        registeredFields,
        values: (values || {}) as Partial<T>,
    };
};
