export enum GroupWorkoutReservationType {
    Cancelled = 'CANCELLED',
    Active = 'ACTIVE',
    Completed = 'COMPLETED',
    InProcess = 'IN_PROCESS',
    Aborted = 'ABORTED',
}

export const reservationStatusMap = {
    [GroupWorkoutReservationType.Active]: 'Активно',
    [GroupWorkoutReservationType.Cancelled]: 'Отменено',
    [GroupWorkoutReservationType.Completed]: 'Завершено',
    [GroupWorkoutReservationType.InProcess]: 'В процессе',
    [GroupWorkoutReservationType.Aborted]: 'Прервано',
};

export enum GroupWorkoutReservationNewType {
    NEW = 'NEW',
    CANCELLED_BY_MANAGER = 'CANCELLED_BY_MANAGER',
    CANCELLED_BY_USER = 'CANCELLED_BY_USER',
    COMPLETED = 'COMPLETED',
    SKIPPED = 'SKIPPED',
    CONFIRMED = 'CONFIRMED',
}

export const ReservationNewTypeOptions = [
    { title: 'Ожидание', value: 'NEW' },
    { title: 'Отменено менеджером', value: 'CANCELLED_BY_MANAGER' },
    { title: 'Отменено пользователем', value: 'CANCELLED_BY_USER' },
    { title: 'Пройдено', value: 'COMPLETED' },
    { title: 'Пропущено', value: 'SKIPPED' },
    { title: 'Подтверждено', value: 'CONFIRMED' },
];

export const newReservationStatusMap = {
    [GroupWorkoutReservationNewType.NEW]: 'Ожидание',
    [GroupWorkoutReservationNewType.CANCELLED_BY_MANAGER]: 'Отменено',
    [GroupWorkoutReservationNewType.CANCELLED_BY_USER]: 'Отменено',
    [GroupWorkoutReservationNewType.COMPLETED]: 'Пройдено',
    [GroupWorkoutReservationNewType.SKIPPED]: 'Пропущено',
    [GroupWorkoutReservationNewType.CONFIRMED]: 'Подтверждено',
};

export const reservationTipMap = {
    [GroupWorkoutReservationNewType.NEW]: 'Ожидание',
    [GroupWorkoutReservationNewType.CANCELLED_BY_MANAGER]: 'Отменено',
    [GroupWorkoutReservationNewType.CANCELLED_BY_USER]: 'Отменено',
    [GroupWorkoutReservationNewType.COMPLETED]: 'Пройдено',
    [GroupWorkoutReservationNewType.SKIPPED]: 'Пропущено',
    [GroupWorkoutReservationNewType.CONFIRMED]: 'Подтверждено',
};

export const statusColors: { [i: string]: string } = {
    NEW: '#F07C2D',
    CANCELLED_BY_MANAGER: '#F02D41',
    CANCELLED_BY_USER: '#F02D41',
    COMPLETED: '#414141',
    SKIPPED: '#414141',
    CONFIRMED: '#17C85F',
};

export interface GroupWorkoutReservation {
    _id: string;
    title: string;
    groupWorkoutId: string;
    groupWorkoutReservationId: string;
    groupWorkoutScheduleId: string;
    groupWorkoutDate: Date;
    gymId: string;
    dateStart: Date;
    dateFinish: Date;
    gymTitle: string;
    userId: string;
    cancelled: boolean;
    status: GroupWorkoutReservationType;
    createdAt: Date;
    userName: string;
    phoneNumber: string;
    comment: string;
    aborted: boolean;
    abortReasonMessage: string;
}

export interface IGroupWorkoutReservationNew {
    _id: string;
    groupWorkoutId: string;
    userId: IReservationNewUser;
    gymId: IReservationGymId;
    title: string;
    description: string;
    comment: string;
    timeSlot: IReservationTimeSlot;
    date: Date;
    gymTitle: string;
    status: GroupWorkoutReservationNewType;
    abortReasonMessage?: string;
    yclientsWorkoutId?: number;
    yclientsRecordHash?: string;
    timeStartUtc: Date;
    timeFinishUtc: Date;
    userPayments: UserPayments[];
    createdAt: Date;
}

export interface IReservationNewUser {
    firstName: string;
    lastName: string;
    phoneNumber: string;
}

export interface IReservationTimeSlot {
    timeStart: Date;
    timeFinish: Date;
}

export interface IReservationGymId {
    phone: string;
    timezone: string;
}

export interface UserPayments {
    type: string;
}

export enum userPaymentsType {
    RESERVATION = 'Reservation',
    REFUND = 'Refund',
    BOOKING = 'Booking',
    SUBSCRIPTION_FIRST = 'SubscriptionFirst',
    SUBSCRIPTION_NEXT = 'SubscriptionNext',
    MEMBERSHIP = 'Membership',
    ONLINE_COURSES = 'OnlineCourse',
}
