import { Tag } from '@t/tag';
import { SelectOption } from 'interfaces/SelectOption';
import { TagType } from 'enums/TagType';

export const getMappedTagOptions = (
    tags: Tag[],
    tagTypeValue: TagType
): SelectOption[] =>
    tags.reduce<{ label: string; value: string }[]>((acc, tag) => {
        if (tag.tagType.find((type) => type.tagTypeValue === tagTypeValue)) {
            acc.push({
                label: tag.tagName,
                value: tag._id,
            });
        }
        return acc;
    }, []);
