import React, { useState, useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import cogoToast from 'cogo-toast';

import Button from '@components/Button';
import { AsyncDispatch } from '@redux/types';
import { uploadImage } from '@redux/modules/gyms/actions';

import styles from './styles.module.css';

export interface Props {
    gymId: string;
    gymImagesLength: number;
    disabled?: boolean;
}

const MAX_LENGTH = 5;
const MAX_SIZE = 5000000;

export const UploadImage: React.FC<Props> = ({
    gymId,
    gymImagesLength,
    disabled,
}) => {
    const fileInput = useRef<null>(null);
    const [isLoading, updateIsLoading] = useState(false);
    const dispatch = useDispatch<AsyncDispatch>();

    const handleClick = useCallback(async () => {
        try {
            updateIsLoading(true);
            const files = ((fileInput.current || { files: new FileList() })
                .files as unknown) as File[];

            await dispatch(uploadImage({ files, gymId }));
        } catch (err) {
            cogoToast.error(`Ошибка при загрузке фото`, {
                position: 'top-right',
                hideAfter: 4,
            });
        } finally {
            updateIsLoading(false);
        }
    }, [dispatch]);

    const uploadMultipleFiles = (event: any) => {
        const numOfFiles = Array.from(event.target.files).length;
        if (numOfFiles + gymImagesLength > MAX_LENGTH) {
            event.preventDefault();
            cogoToast.error(`Нельзя загрузить более ${MAX_LENGTH} фото`, {
                position: 'top-right',
                hideAfter: 4,
            });
            event.target.value = null;
            return;
        }
        for (let i = 0; i < numOfFiles; ++i) {
            const validExtensions = ['png', 'jpeg', 'jpg', 'webp'];
            const fileExtension = event.target.files[i].type.split('/')[1];
            const fileSize = event.target.files[i].size;

            if (!validExtensions.includes(fileExtension)) {
                event.preventDefault();
                cogoToast.error(
                    `Можно загрузить файлы с расширением png, jpeg, jpg, webp`,
                    {
                        position: 'top-right',
                        hideAfter: 4,
                    }
                );
                event.target.value = null;
                return;
            }

            if (fileSize > MAX_SIZE) {
                event.preventDefault();
                cogoToast.error(
                    `Нельзя загрузить фото размера более 5MB, фото "${event.target.files[i].name}" слишком большое`,
                    {
                        position: 'top-right',
                        hideAfter: 4,
                    }
                );
                event.target.value = null;
                return;
            }
        }
    };

    return (
        <div className={styles.wrapper}>
            {isLoading && 'Загрузка в процессе...'}
            <form className="upload-image">
                <label className={styles.label}>
                    Загрузить фотографии (не более 5 фото, с расширением
                    png/jpeg/jpg/webp, максимальный размер фото 5MB)
                </label>
                <input
                    disabled={disabled}
                    type="file"
                    ref={fileInput}
                    className={styles.input}
                    onChange={uploadMultipleFiles}
                    accept=".jpg, .png, .jpeg .webp"
                    multiple
                />
                <Button disabled={disabled} type="button" onClick={handleClick}>
                    Загрузить
                </Button>
            </form>
        </div>
    );
};

export default UploadImage;
