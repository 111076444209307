import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import cogoToast from 'cogo-toast';

import BoxButton from '@components/BoxButton';
import { AsyncDispatch } from '@redux/types';
import { getGymsList } from '@redux/modules/gyms/selectors';
import { fetchGymsList, selectGym } from '@redux/modules/gyms/actions';
import BackButton from '@components/BackButton';
import { fetchTagCategories, fetchTags } from '@redux/modules/tags/actions';
import { getTagCategories, getTags } from '@redux/modules/tags/selectors';

import GroupWorkoutNewForm, {
    GroupWorkoutNewFormData,
} from '@containers/Dashboard/GroupWorkoutNewForm';
import {
    createNewGroupWorkout,
    fetchGroupWorkoutNewDetail,
} from '@redux/modules/group-workouts/actions';
import { FormMode } from '@t/utils';

import styles from './styles.module.css';
import { useGroupWorkoutNewFormNormalizeData } from '@containers/Dashboard/GroupWorkoutNewForm/useGroupWorkoutNewFormNormalizeData';
import { GroupWorkoutNew } from '@t/gym';

export const GroupWorkoutNewDetails: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const dispatch = useDispatch<AsyncDispatch>();
    const [groupWorkoutNew, setGroupWorkoutNew] = useState<GroupWorkoutNew>(
        {} as GroupWorkoutNew
    );
    const history = useHistory();

    const gymsList = useSelector(getGymsList);
    const tags = useSelector(getTags);
    const tagCategories = useSelector(getTagCategories);

    const {
        normalizeDuration,
        generateMomentTimeSlotToValidData,
    } = useGroupWorkoutNewFormNormalizeData();

    const [formMode] = useState<FormMode>('readonly');

    useEffect(() => {
        const getData = async () => {
            const result = await dispatch(fetchGroupWorkoutNewDetail({ id }));
            setGroupWorkoutNew(result);
        };

        getData();
    }, [dispatch, id]);

    useEffect(() => {
        dispatch(fetchGymsList());
        dispatch(fetchTags());
        dispatch(fetchTagCategories());
    }, [dispatch]);

    useEffect(() => {
        if (groupWorkoutNew?.gymId) {
            dispatch(selectGym(groupWorkoutNew.gymId));
        }
    }, [groupWorkoutNew?.gymId]);

    const onChangeFormMode = () => {
        history.push(`/dashboard/group-workouts/${id}/edit`);
    };

    const goToCalendar = useCallback(
        () => history.push(`/dashboard/group-workouts/${id}/calendar`),
        [history]
    );

    const goToReservations = useCallback(
        () => history.push(`/dashboard/group-workouts/${id}/reservations`),
        [history]
    );

    const initialValues: any = useMemo(() => {
        if (!groupWorkoutNew || !groupWorkoutNew?._id) {
            return {};
        }

        const values: GroupWorkoutNewFormData = {
            title: groupWorkoutNew.title,
            tags: groupWorkoutNew.tags,
            gymId: groupWorkoutNew.gymId,
            duration: normalizeDuration({
                mon: groupWorkoutNew.groupworkoutschedule.mon,
                tue: groupWorkoutNew.groupworkoutschedule.tue,
                wed: groupWorkoutNew.groupworkoutschedule.wed,
                thu: groupWorkoutNew.groupworkoutschedule.thu,
                fri: groupWorkoutNew.groupworkoutschedule.fri,
                sat: groupWorkoutNew.groupworkoutschedule.sat,
                sun: groupWorkoutNew.groupworkoutschedule.sun,
            }).toString(),
            limit: groupWorkoutNew.limit.toString(),
            price: groupWorkoutNew.price.toString(),
            genderType: groupWorkoutNew.genderType,
            description: groupWorkoutNew.description || '',
            groupworkoutschedule: {
                dateStart:
                    groupWorkoutNew.groupworkoutschedule.dateStart || null,
                dateEnd: groupWorkoutNew.groupworkoutschedule.dateEnd || null,
                reservations: groupWorkoutNew.groupworkoutschedule.reservations,
                mon: generateMomentTimeSlotToValidData(
                    groupWorkoutNew.groupworkoutschedule.mon
                ),
                tue: generateMomentTimeSlotToValidData(
                    groupWorkoutNew.groupworkoutschedule.tue
                ),
                wed: generateMomentTimeSlotToValidData(
                    groupWorkoutNew.groupworkoutschedule.wed
                ),
                thu: generateMomentTimeSlotToValidData(
                    groupWorkoutNew.groupworkoutschedule.thu
                ),
                fri: generateMomentTimeSlotToValidData(
                    groupWorkoutNew.groupworkoutschedule.fri
                ),
                sat: generateMomentTimeSlotToValidData(
                    groupWorkoutNew.groupworkoutschedule.sat
                ),
                sun: generateMomentTimeSlotToValidData(
                    groupWorkoutNew.groupworkoutschedule.sun
                ),
            },
        };
        return values;
    }, [groupWorkoutNew, formMode]);

    const onSubmit = useCallback(
        (values: GroupWorkoutNewFormData) => {
            const {
                title,
                tags,
                gymId,
                limit,
                price,
                genderType,
                description,
                groupworkoutschedule,
            } = values;

            const normalizedData = {
                title,
                gymId,
                limit: Number(limit),
                price: Number(price),
                description,
                ...(tags && { tags }),
                ...(genderType && { genderType }),
                ...(groupworkoutschedule.dateStart && {
                    dateStart: groupworkoutschedule.dateStart,
                }),
                ...(groupworkoutschedule.dateEnd && {
                    dateEnd: groupworkoutschedule.dateEnd,
                }),
                ...(groupworkoutschedule.mon && {
                    mon: groupworkoutschedule.mon,
                }),
                ...(groupworkoutschedule.tue && {
                    tue: groupworkoutschedule.tue,
                }),
                ...(groupworkoutschedule.wed && {
                    wed: groupworkoutschedule.wed,
                }),
                ...(groupworkoutschedule.thu && {
                    thu: groupworkoutschedule.thu,
                }),
                ...(groupworkoutschedule.fri && {
                    fri: groupworkoutschedule.fri,
                }),
                ...(groupworkoutschedule.sat && {
                    sat: groupworkoutschedule.sat,
                }),
                ...(groupworkoutschedule.sun && {
                    sun: groupworkoutschedule.sun,
                }),
            };
            dispatch(createNewGroupWorkout({ values: normalizedData }))
                .then(() => {
                    cogoToast.success('Групповая тренировка создана', {
                        position: 'top-right',
                        hideAfter: 5,
                    });
                    history.push('/dashboard/group-workouts');
                })
                .catch(() => {
                    cogoToast.error(
                        'Ошибка при создании групповой тренировки',
                        {
                            position: 'top-right',
                            hideAfter: 4,
                        }
                    );
                });
        },
        [dispatch]
    );

    const gymOptions = gymsList.reduce<{ label: string; value: string }[]>(
        (acc, gym) => {
            if (gym.hasGroupWorkouts) {
                acc.push({
                    label: gym.title,
                    value: gym._id,
                });
            }
            return acc;
        },
        []
    );

    const tagOptions = tags.reduce<{ label: string; value: string }[]>(
        (acc, tag) => {
            if (
                tag.tagType.find(
                    (type) => type.tagTypeValue === 'GROUPWORKOUTFILTER'
                )
            ) {
                acc.push({
                    label: tag.tagName,
                    value: tag._id,
                });
            }
            return acc;
        },
        []
    );

    if (!groupWorkoutNew || !groupWorkoutNew?._id) {
        return <div className={styles.container}>Загрузка...</div>;
    }

    return (
        <div>
            <BackButton
                title="Все тренировки"
                className={styles.backBtn}
                onClick={() => history.push('/dashboard/group-workouts')}
            />

            <div className={styles.actions}>
                <div className={styles.nav}>
                    <BoxButton
                        icon=""
                        title="Общая информация"
                        className={styles.actionNav}
                    />
                    <BoxButton
                        icon=""
                        title="Календарь"
                        onClick={goToCalendar}
                    />
                    <BoxButton
                        icon=""
                        title="Бронирования"
                        onClick={goToReservations}
                    />
                </div>
            </div>

            <div className={styles.box}>
                <GroupWorkoutNewForm
                    groupWorkoutId={id}
                    formMode={formMode}
                    onChangeFormMode={onChangeFormMode}
                    gymOptions={gymOptions}
                    tagOptions={tagOptions}
                    tags={tags}
                    tagCategories={tagCategories}
                    onSubmit={onSubmit}
                    initialValues={initialValues}
                />
            </div>
        </div>
    );
};

export default GroupWorkoutNewDetails;
