import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import cogoToast from 'cogo-toast';

import { AsyncDispatch } from '@redux/types';
import { fetchGymsList } from '@redux/modules/gyms/actions';
import { getGymsList } from '@redux/modules/gyms/selectors';
import BackButton from '@components/BackButton';
import { fetchTagCategories, fetchTags } from '@redux/modules/tags/actions';

import { getTagCategories, getTags } from '@redux/modules/tags/selectors';
import GroupWorkoutNewForm, {
    GroupWorkoutNewFormData,
} from '@containers/Dashboard/GroupWorkoutNewForm';
import {
    createNewGroupWorkout,
    fetchGroupWorkoutNewDetail,
} from '@redux/modules/group-workouts/actions';
import { FormMode } from '@t/utils';
import { getGroupWorkoutDetail } from '@redux/modules/group-workouts/selectors';

import styles from './styles.module.css';
import { useGroupWorkoutNewFormNormalizeData } from '@containers/Dashboard/GroupWorkoutNewForm/useGroupWorkoutNewFormNormalizeData';

export const GroupWorkoutNewDuplicate: React.FC<{}> = () => {
    const { id } = useParams<{ id: string }>();
    const dispatch = useDispatch<AsyncDispatch>();
    const groupWorkoutNew = useSelector(getGroupWorkoutDetail(id));
    const history = useHistory();

    const gymsList = useSelector(getGymsList);
    const tags = useSelector(getTags);
    const tagCategories = useSelector(getTagCategories);

    const {
        normalizeDuration,
        normalizeScheduleByWeekDay,
    } = useGroupWorkoutNewFormNormalizeData();

    const [formMode, setFormMode] = useState<FormMode>('create');

    useEffect(() => {
        dispatch(fetchGroupWorkoutNewDetail({ id }));
    }, [dispatch, id]);

    useEffect(() => {
        dispatch(fetchGymsList());
        dispatch(fetchTags());
        dispatch(fetchTagCategories());
    }, [dispatch]);

    const onChangeFormMode = useCallback(
        (mode: FormMode) => setFormMode(mode),
        []
    );

    const onSubmit = useCallback(
        (values: GroupWorkoutNewFormData) => {
            const {
                title,
                tags,
                gymId,
                limit,
                price,
                genderType,
                description,
                groupworkoutschedule,
            } = values;

            const normalizedData = {
                title,
                gymId,
                limit: Number(limit),
                price: Number(price),
                description,
                ...(tags && { tags }),
                ...(genderType && { genderType }),
                ...(groupworkoutschedule.dateStart && {
                    dateStart: groupworkoutschedule.dateStart,
                }),
                ...(groupworkoutschedule.dateEnd && {
                    dateEnd: groupworkoutschedule.dateEnd,
                }),
                ...(groupworkoutschedule.mon && {
                    mon: groupworkoutschedule.mon,
                }),
                ...(groupworkoutschedule.tue && {
                    tue: groupworkoutschedule.tue,
                }),
                ...(groupworkoutschedule.wed && {
                    wed: groupworkoutschedule.wed,
                }),
                ...(groupworkoutschedule.thu && {
                    thu: groupworkoutschedule.thu,
                }),
                ...(groupworkoutschedule.fri && {
                    fri: groupworkoutschedule.fri,
                }),
                ...(groupworkoutschedule.sat && {
                    sat: groupworkoutschedule.sat,
                }),
                ...(groupworkoutschedule.sun && {
                    sun: groupworkoutschedule.sun,
                }),
            };
            dispatch(createNewGroupWorkout({ values: normalizedData }))
                .then(() => {
                    cogoToast.success('Групповая тренировка создана', {
                        position: 'top-right',
                        hideAfter: 5,
                    });
                    history.push('/dashboard/group-workouts');
                })
                .catch(() => {
                    cogoToast.error(
                        'Ошибка при создании групповой тренировки',
                        {
                            position: 'top-right',
                            hideAfter: 4,
                        }
                    );
                });
        },
        [dispatch]
    );

    const gymOptions = gymsList.reduce<{ label: string; value: string }[]>(
        (acc, gym) => {
            if (gym.hasGroupWorkouts) {
                acc.push({
                    label: gym.title,
                    value: gym._id,
                });
            }
            return acc;
        },
        []
    );

    const tagOptions = tags.reduce<{ label: string; value: string }[]>(
        (acc, tag) => {
            if (
                tag.tagType.find(
                    (type) => type.tagTypeValue === 'GROUPWORKOUTFILTER'
                )
            ) {
                acc.push({
                    label: tag.tagName,
                    value: tag._id,
                });
            }
            return acc;
        },
        []
    );

    const initialValues: any = useMemo(() => {
        if (!groupWorkoutNew || !groupWorkoutNew?._id) {
            return {};
        }

        const values: GroupWorkoutNewFormData = {
            title: groupWorkoutNew.title,
            tags: groupWorkoutNew.tags,
            gymId: groupWorkoutNew.gymId,
            duration: normalizeDuration({
                mon: groupWorkoutNew.groupworkoutschedule.mon,
                tue: groupWorkoutNew.groupworkoutschedule.tue,
                wed: groupWorkoutNew.groupworkoutschedule.wed,
                thu: groupWorkoutNew.groupworkoutschedule.thu,
                fri: groupWorkoutNew.groupworkoutschedule.fri,
                sat: groupWorkoutNew.groupworkoutschedule.sat,
                sun: groupWorkoutNew.groupworkoutschedule.sun,
            }).toString(),
            limit: groupWorkoutNew.limit.toString(),
            price: groupWorkoutNew.price.toString(),
            genderType: groupWorkoutNew.genderType,
            description: groupWorkoutNew.description || '',
            groupworkoutschedule: {
                reservations: groupWorkoutNew.groupworkoutschedule.reservations,
                dateStart:
                    groupWorkoutNew.groupworkoutschedule.dateStart || null,
                dateEnd: groupWorkoutNew.groupworkoutschedule.dateEnd || null,
                mon: normalizeScheduleByWeekDay(
                    groupWorkoutNew.groupworkoutschedule.mon
                ),
                tue: normalizeScheduleByWeekDay(
                    groupWorkoutNew.groupworkoutschedule.tue
                ),
                wed: normalizeScheduleByWeekDay(
                    groupWorkoutNew.groupworkoutschedule.wed
                ),
                thu: normalizeScheduleByWeekDay(
                    groupWorkoutNew.groupworkoutschedule.thu
                ),
                fri: normalizeScheduleByWeekDay(
                    groupWorkoutNew.groupworkoutschedule.fri
                ),
                sat: normalizeScheduleByWeekDay(
                    groupWorkoutNew.groupworkoutschedule.sat
                ),
                sun: normalizeScheduleByWeekDay(
                    groupWorkoutNew.groupworkoutschedule.sun
                ),
            },
        };
        return values;
    }, [groupWorkoutNew, formMode]);

    return (
        <div className={styles.container}>
            <BackButton
                title="Все тренировки"
                className={styles.backBtn}
                onClick={() => history.push('/dashboard/group-workouts')}
            />

            <h2 className={styles.title}>Новая тренировка</h2>

            <div className={styles.box}>
                <GroupWorkoutNewForm
                    groupWorkoutId={id}
                    formMode={formMode}
                    onChangeFormMode={onChangeFormMode}
                    gymOptions={gymOptions}
                    tagOptions={tagOptions}
                    tags={tags}
                    tagCategories={tagCategories}
                    onSubmit={onSubmit}
                    initialValues={initialValues}
                />
            </div>
        </div>
    );
};

export default GroupWorkoutNewDuplicate;
