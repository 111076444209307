import { useSelector } from 'react-redux';
import { SelectOption } from 'interfaces/SelectOption';
import { IUseSelectOptions } from 'interfaces/IUseSelectOptions';

export const useSelectOptions = <T>({
    selector,
    labelAlias,
    valueAlias,
    determineInitialSelectedOption,
    getCustomLabel,
    customMapper,
}: IUseSelectOptions<T>): {
    entityList: T[];
    options: SelectOption[];
    defaultOptionId: string | null;
} => {
    const entityList = useSelector(selector);
    const result = customMapper
        ? {
              entityList,
              options: customMapper(entityList),
              defaultOptionId: null,
          }
        : entityList.reduce(
              (acc, entity) => {
                  const label =
                      getCustomLabel?.(entity) ??
                      ((entity[
                          labelAlias as keyof typeof entity
                      ] as unknown) as string);

                  if (!label) {
                      throw new Error(`missing key \`labelAlias\` in entity`);
                  }

                  const value = (entity[
                      valueAlias as keyof typeof entity
                  ] as unknown) as string;

                  if (!value) {
                      throw new Error(`missing key \`valueAlias\` in entity`);
                  }

                  const option: SelectOption = {
                      label,
                      value,
                  };

                  acc.options.push(option);

                  if (
                      determineInitialSelectedOption?.(option) &&
                      !acc.defaultOptionId
                  ) {
                      acc.defaultOptionId = option.value;
                  }

                  return acc;
              },
              {
                  options: [] as SelectOption[],
                  defaultOptionId: null as string | null,
              }
          );

    return {
        ...result,
        entityList,
    };
};
