import React, { useState, useRef, useCallback, memo } from 'react';
import {
    InjectedFormProps,
    Field,
    reduxForm,
    FieldArray,
    formValueSelector,
} from 'redux-form';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import cogoToast from 'cogo-toast';
import { createTextMask } from 'redux-form-input-masks';
import moment from 'moment';

import GeoInputYandex from '@components/GeoInputYandex';
import TextInput from '@components/TextInput';
import TextAreaInput from '@components/TextAreaInput';
import Button from '@components/Button';
import CheckBoxInput from '@components/CheckBoxInput';
import SelectInput from '@components/SelectInput';
import MultiSelectInput from '@components/MultiSelectInput';
import TimeInput from '@components/TimeInput';
import {
    SpecialPrice,
    SubwayRoute,
    WeekDay,
    WeekDaysOptions,
    WorkingHours,
} from '@t/gym';
import { ApplicationState, AsyncDispatch } from '@redux/types';
import { getUser } from '@redux/modules/auth/selectors';
import { UserRole } from '@t/user';
import Modal from '@components/Modal';
import Delete from '@components/Icons/Delete';
import Added from '@components/Icons/Added';
import { selectGym } from '@redux/modules/gyms/actions';
import CityInput from '@components/CityInput';
import { TIME_FORMAT } from '@config';

import styles from './styles.module.css';

export interface FormData {
    hasPerMinWorkouts: boolean;
    hasGroupWorkouts: boolean;
    hasSingleWorkouts: boolean;
    withSingleWorkoutsOption: boolean;
    withGroupWorkoutsOption: boolean;
    hasMinWorkoutDuration: boolean;
    title: string;
    authorized: boolean;
    deleted: boolean;
    address: string;
    description: string;
    descriptionLong: string;
    geo: {
        address: string;
        shortAddress: string;
        location: google.maps.LatLngLiteral;
    };
    social: {
        instagram: string;
        vk: string;
        facebook: string;
        telegram: string;
    };
    imagesSrc: Array<string>;
    specialPrices: Array<SpecialPrice>;
    subwayRoutes: Array<SubwayRoute>;
    workingHours: WorkingHours[];
    fixedPrice: number;
    minWorkoutDuration: number;
    singleWorkoutPriceInfo: {
        weekDayStartTime: Date;
        weekDayFinishTime: Date;
        weekDayPrice: number;
        weekDayOffPeakStartTime: Date;
        weekDayOffPeakFinishTime: Date;
        weekDayOffPeakPrice: number;
        weekendStartTime: Date;
        weekendFinishTime: Date;
        weekendPrice: number;
        weekendOffPeakStartTime: Date;
        weekendOffPeakFinishTime: Date;
        weekendOffPeakPrice: number;
    };
    tags: {
        dayAndNight?: boolean;
        drinkingWater?: boolean;
        towels?: boolean;
        pilates?: boolean;
        crossfit?: boolean;
        yoga?: boolean;
        stretching?: boolean;
        combatZone?: boolean;
        finnishSauna?: boolean;
        turkishHammam?: boolean;
        fitnessBar?: boolean;
        swimmingPool?: boolean;
        childrenRoom?: boolean;
        parking?: boolean;
        cardioEquipment?: boolean;
        shower?: boolean;
    };
    services: string[];
    benefits: string[];
    website: string;
    phone: string;
    hasWeekendPrimeTime: boolean;
    hasWeekDayPrimeTime: boolean;
    legalEntityId: string;
    managersId: string[];
    managersIds: string[];
    hasPassport: boolean;
    isHidden: boolean;
    city: { title: string; id: string };
    test: boolean;
    yclientsId: string;
    discountFromYclientsPrice: number;
    prepaymentPercentFromYclientsPrice: number;
    docs: string[];
    seasonal: boolean;
    prepayment: number;
}

export interface Props {
    hasSingleWorkouts: boolean;
    hasGroupWorkouts: boolean;
    hasWeekDayPrimeTime: boolean;
    hasWeekendPrimeTime: boolean;
    hasPerMinWorkouts: boolean;
    hasMinWorkoutDuration: boolean;
    withSingleWorkoutsOption: boolean;
    withGroupWorkoutsOption: boolean;
    onDelete: () => void;
    onUpdateManagerGym: (managersIds: string[]) => void;
    legalEntitysOptions: {
        title: string;
        value: string;
    }[];
    AllManagersOptions: {
        title: string;
        value: string;
    }[];
    managersOptions: {
        title: string;
        value: string;
    }[];
    addManager: boolean;
    setAddManager: (prev: boolean) => void;
    addLegalEntity: boolean;
    setAddLegalEntity: (prev: boolean) => void;
    legalEntitysOption: {
        title: string;
        value: string;
    }[];
    onUpdateLegalEntityGym: (legalEntityId: string) => void;
    setChangedGymForm: (prev: boolean) => void;
    gymId: string;
    changedGymForm: boolean;
}

const validate = (values: FormData) => {
    const errors: {
        title: string;
        description: string;
        fixedPrice: string;
        minWorkoutDuration: string;
        city: string;
        discountFromYclientsPrice: string;
        prepaymentPercentFromYclientsPrice: string;
        phone: string;
        subwayRoutes: any;
        specialPrices: any;
        workingHours: any;
    } = {
        title: '',
        description: '',
        fixedPrice: '',
        minWorkoutDuration: '',
        city: '',
        discountFromYclientsPrice: '',
        prepaymentPercentFromYclientsPrice: '',
        phone: '',
        subwayRoutes: {},
        specialPrices: {},
        workingHours: {},
    };

    if (!values.title) {
        errors.title = 'Обязательное поле';
    }

    if (!values.description) {
        errors.description = 'Обязательное поле';
    }

    if (!values.description?.replace(/\s/g, '')) {
        errors.description = 'Обязательное поле';
    }

    if (!values.fixedPrice) {
        errors.fixedPrice = 'Обязательное поле';
    }

    if (!values.city) {
        errors.city = 'Обязательное поле';
    }

    if (isNaN(Number(values.fixedPrice))) {
        errors.fixedPrice = 'Должно быть числом';
    }

    if (!Number.isInteger(Number(values.fixedPrice))) {
        errors.fixedPrice = 'Должно быть целым числом';
    }

    if (
        values.hasMinWorkoutDuration &&
        (!Number.isInteger(Number(values.minWorkoutDuration)) ||
            Number(values.minWorkoutDuration) <= 0 ||
            String(values.minWorkoutDuration).replace(/^\d+$/g, '').length)
    ) {
        errors.minWorkoutDuration = 'Должно быть числом';
    }

    if (values.hasMinWorkoutDuration && !values.minWorkoutDuration) {
        errors.minWorkoutDuration = 'Обязательное поле';
    }

    if (!Number.isInteger(Number(values.discountFromYclientsPrice))) {
        errors.discountFromYclientsPrice =
            'Введите процент предоплаты от 0 до 100';
    }

    if (!Number.isInteger(Number(values.prepaymentPercentFromYclientsPrice))) {
        errors.prepaymentPercentFromYclientsPrice =
            'Введите процент предоплаты от 0 до 100';
    }

    if (Number(values.discountFromYclientsPrice) > 100) {
        errors.discountFromYclientsPrice =
            'Введите процент предоплаты от 0 до 100';
    }

    if (Number(values.prepaymentPercentFromYclientsPrice) < 0) {
        errors.prepaymentPercentFromYclientsPrice =
            'Введите процент предоплаты от 0 до 100';
    }

    if (
        values?.phone?.length > 0 &&
        !/[+]7\s[(][0-9]{3}[)]\s[0-9]{3}[-][0-9]{2}[-][0-9]{2}/.test(
            values.phone
        )
    ) {
        errors.phone = 'Проверьте корректность номера';
    }

    values.subwayRoutes?.forEach((subwayRoute: SubwayRoute, i: number) => {
        if (!subwayRoute.station) {
            errors.subwayRoutes[i] = {
                ...errors.subwayRoutes[i],
                station: 'Обязательное поле',
            };
        }
        if (
            !Number.isInteger(Number(subwayRoute.duration)) ||
            Number(subwayRoute.duration) <= 0 ||
            String(subwayRoute.duration).replace(/^\d+$/g, '').length
        ) {
            errors.subwayRoutes[i] = {
                ...errors.subwayRoutes[i],
                duration: 'Должно быть числом',
            };
        }
        if (!subwayRoute.duration) {
            errors.subwayRoutes[i] = {
                ...errors.subwayRoutes[i],
                duration: 'Обязательное поле',
            };
        }
    });

    values.specialPrices?.forEach((specialPrice: SpecialPrice, i: number) => {
        if (!specialPrice.weekDay) {
            errors.specialPrices[i] = {
                ...errors.specialPrices[i],
                weekDay: 'Обязательное поле',
            };
        }

        if (!specialPrice.timeStart) {
            errors.specialPrices[i] = {
                ...errors.specialPrices[i],
                timeStart: 'Обязательное поле',
            };
        }

        if (!specialPrice.timeFinish) {
            errors.specialPrices[i] = {
                ...errors.specialPrices[i],
                timeFinish: 'Обязательное поле',
            };
        }

        if (
            moment(specialPrice.timeStart).utcOffset(0).format(TIME_FORMAT) ===
                '00:00' &&
            moment(specialPrice.timeFinish).utcOffset(0).format(TIME_FORMAT) ===
                '00:00'
        ) {
            errors.specialPrices[i] = {
                ...errors.specialPrices[i],
                timeStart: 'Обязательное поле',
                timeFinish: 'Обязательное поле',
            };
        }

        if (
            !Number.isInteger(Number(specialPrice.price)) ||
            Number(specialPrice.price) <= 0 ||
            String(specialPrice.price).replace(/^\d+$/g, '').length
        ) {
            errors.specialPrices[i] = {
                ...errors.specialPrices[i],
                price: 'Должно быть числом',
            };
        }

        if (!specialPrice.price) {
            errors.specialPrices[i] = {
                ...errors.specialPrices[i],
                price: 'Обязательное поле',
            };
        }
    });

    values.workingHours?.forEach((workingHour: WorkingHours, i: number) => {
        if (!workingHour.weekDay) {
            errors.workingHours[i] = {
                ...errors.workingHours[i],
                weekDay: 'Обязательное поле',
            };
        }

        if (!workingHour.timeStart) {
            errors.workingHours[i] = {
                ...errors.workingHours[i],
                timeStart: 'Обязательное поле',
            };
        }

        if (!workingHour.timeFinish) {
            errors.workingHours[i] = {
                ...errors.workingHours[i],
                timeFinish: 'Обязательное поле',
            };
        }

        if (
            moment(workingHour.timeStart).utcOffset(0).format(TIME_FORMAT) ===
                '00:00' &&
            moment(workingHour.timeFinish).utcOffset(0).format(TIME_FORMAT) ===
                '00:00'
        ) {
            errors.workingHours[i] = {
                ...errors.workingHours[i],
                timeStart: 'Обязательное поле',
                timeFinish: 'Обязательное поле',
            };
        }
    });

    return errors;
};

const Form: React.FC<Props & InjectedFormProps<FormData, Props>> = memo(
    ({
        handleSubmit,
        // hasSingleWorkouts,
        hasGroupWorkouts,
        // hasWeekDayPrimeTime,
        // hasWeekendPrimeTime,
        hasPerMinWorkouts,
        hasMinWorkoutDuration,
        // withSingleWorkoutsOption,
        withGroupWorkoutsOption,
        onDelete,
        onUpdateManagerGym,
        legalEntitysOptions,
        AllManagersOptions,
        managersOptions,
        addManager,
        setAddManager,
        addLegalEntity,
        setAddLegalEntity,
        legalEntitysOption,
        onUpdateLegalEntityGym,
        setChangedGymForm,
        gymId,
    }) => {
        const history = useHistory();
        const dispatch = useDispatch<AsyncDispatch>();
        const user = useSelector(getUser);
        const isSuperAdmin = user?.role === UserRole.SuperAdmin;
        const isAdmin = user?.role === UserRole.Admin;
        const [modalActive, setModalActive] = useState(false);
        const selector = formValueSelector('updateGym');
        const managersIdsSelector = (state: ApplicationState) =>
            selector(state, 'managersIds');
        const managersIds = useSelector(managersIdsSelector);
        const [foundManager, setFoundManager] = useState<any>();
        const managerSearchingField = useRef<any>();
        const [tooltips, setTooltips] = useState({
            groupWorkoutsOption: false,
            yclientsId: false,
        });
        const gymSearchingField = useRef<any>();
        const gymLegalEntityIdField = useRef<any>();
        const [foundLegalEntitys, setFoundLegalEntitys] = useState<any>();

        const yClientIdSelector = (state: ApplicationState) =>
            selector(state, 'yclientsId');
        const yClientId = useSelector(yClientIdSelector);
        const yClientsIdValueValid = Boolean(yClientId && yClientId.length);

        const handleSearchManagers = () => {
            setFoundManager(
                AllManagersOptions.filter((managerOption) =>
                    managerOption.title
                        .toLowerCase()
                        .includes(
                            managerSearchingField.current.value.toLowerCase()
                        )
                )
            );
        };

        const handleSearchlegalEntitys = () => {
            setFoundLegalEntitys(
                legalEntitysOptions.filter((legalEntityOption) =>
                    legalEntityOption.title
                        .toLowerCase()
                        .includes(gymSearchingField.current.value.toLowerCase())
                )
            );
        };

        const showNotification = useCallback(() => {
            const { hide } = cogoToast.warn(
                <div>
                    Заполните расписание{' '}
                    <span className={styles.tooltipLink}>
                        групповых тренировок
                    </span>
                </div>,
                {
                    hideAfter: 0,
                    position: 'top-right',
                    onClick: () => {
                        hide && hide();
                        history.push(`/dashboard/group-workouts`);
                        dispatch(selectGym(gymId));
                    },
                }
            );
        }, [hasGroupWorkouts]);

        const renderWorkingHours = useCallback(
            ({ fields }: any) => {
                return (
                    <div>
                        <Button
                            disabled={isAdmin}
                            className={styles.btn}
                            type="button"
                            onClick={() => fields.push({})}
                        >
                            Добавить время работы
                        </Button>
                        <ul>
                            {fields.map(
                                (
                                    workingHours: Array<{
                                        weekDay: WeekDay;
                                        timeStart: Date;
                                        timeFinish: Date;
                                        aroundTheClock: boolean;
                                    } | null>,
                                    index: number
                                ) => (
                                    <li key={index}>
                                        <div className={styles.working}>
                                            <Field
                                                disabled={isAdmin}
                                                name={`${workingHours}.weekDay`}
                                                label="День недели"
                                                placeholder="понедельник"
                                                emptyTitle={
                                                    'Выбрать день/период'
                                                }
                                                options={WeekDaysOptions}
                                                defaulVal={-1}
                                                component={SelectInput}
                                            />
                                            <Field
                                                convertToString
                                                disabled={isAdmin}
                                                name={`${workingHours}.timeStart`}
                                                label="Открытие"
                                                placeholder="10:00"
                                                component={TimeInput}
                                            />
                                            <Field
                                                convertToString
                                                disabled={isAdmin}
                                                name={`${workingHours}.timeFinish`}
                                                label="Закрытие"
                                                placeholder="12:00"
                                                component={TimeInput}
                                            />
                                        </div>
                                        <div>
                                            <Field
                                                disabled={isAdmin}
                                                name={`${workingHours}.aroundTheClock`}
                                                label="Круглосуточно"
                                                component={CheckBoxInput}
                                            />
                                            <Button
                                                disabled={isAdmin}
                                                className={styles.btn}
                                                type="button"
                                                onClick={() =>
                                                    fields.remove(index)
                                                }
                                            >
                                                Удалить
                                            </Button>
                                        </div>
                                    </li>
                                )
                            )}
                        </ul>
                    </div>
                );
            },
            [validate]
        );

        const renderSpecialPrices = useCallback(
            ({ fields }: any) => {
                return (
                    <div>
                        <Button
                            disabled={isAdmin}
                            className={styles.btn}
                            type="button"
                            onClick={() => fields.push({})}
                        >
                            Добавить специальную цену
                        </Button>
                        <ul>
                            {fields.map(
                                (
                                    specialPrice: Array<{
                                        weekDay: WeekDay;
                                        timeStart: Date;
                                        timeFinish: Date;
                                        price: number;
                                    } | null>,
                                    index: number
                                ) => (
                                    <li key={index}>
                                        <div className={styles.special}>
                                            <Field
                                                disabled={isAdmin}
                                                name={`${specialPrice}.weekDay`}
                                                label="День недели"
                                                placeholder="понедельник"
                                                emptyTitle={
                                                    'Выбрать день/период'
                                                }
                                                options={WeekDaysOptions}
                                                defaulVal={-1}
                                                component={SelectInput}
                                            />
                                            <Field
                                                convertToString
                                                disabled={isAdmin}
                                                name={`${specialPrice}.timeStart`}
                                                label="Начало"
                                                placeholder="10:00"
                                                component={TimeInput}
                                            />

                                            <Field
                                                convertToString
                                                disabled={isAdmin}
                                                name={`${specialPrice}.timeFinish`}
                                                label="Конец"
                                                placeholder="12:00"
                                                component={TimeInput}
                                            />

                                            <Field
                                                disabled={isAdmin}
                                                name={`${specialPrice}.price`}
                                                label="Цена"
                                                placeholder="5"
                                                component={TextInput}
                                            />
                                        </div>
                                        <div>
                                            <Button
                                                disabled={isAdmin}
                                                className={styles.btn}
                                                type="button"
                                                onClick={() =>
                                                    fields.remove(index)
                                                }
                                            >
                                                Удалить
                                            </Button>
                                        </div>
                                    </li>
                                )
                            )}
                        </ul>
                    </div>
                );
            },
            [validate]
        );

        const renderSubwayRoutes = useCallback(
            ({ fields }: any) => {
                return (
                    <div>
                        <Button
                            disabled={isAdmin}
                            className={styles.btn}
                            type="button"
                            onClick={() =>
                                fields.push({
                                    station: '',
                                    duration: '',
                                })
                            }
                        >
                            Добавить метро
                        </Button>
                        <ul>
                            {fields.map(
                                (
                                    subwayRoute: Array<{
                                        station: string;
                                        duration: number;
                                    } | null>,
                                    index: number
                                ) => (
                                    <li key={index}>
                                        <div>
                                            <Field
                                                disabled={isAdmin}
                                                name={`${subwayRoute}.station`}
                                                label="Название метро"
                                                placeholder="Алтуфьево"
                                                component={TextInput}
                                            />

                                            <Field
                                                disabled={isAdmin}
                                                name={`${subwayRoute}.duration`}
                                                label="Время от метро до фитнес-площадки (мин)"
                                                placeholder="5"
                                                component={TextInput}
                                            />
                                            <Button
                                                disabled={isAdmin}
                                                className={styles.btn}
                                                type="button"
                                                onClick={() =>
                                                    fields.remove(index)
                                                }
                                            >
                                                Удалить метро
                                            </Button>
                                        </div>
                                    </li>
                                )
                            )}
                        </ul>
                    </div>
                );
            },
            [validate]
        );

        return (
            <form
                onSubmit={handleSubmit}
                className={styles.form}
                onChange={() => {
                    setChangedGymForm(true);
                }}
            >
                <Modal active={modalActive} setActive={setModalActive}>
                    <h3>Вы действительно хотите удалить фитнес-площадку?</h3>
                    {isSuperAdmin && (
                        <Button
                            onClick={onDelete}
                            type="button"
                            className={styles.btnModal}
                        >
                            Удалить
                        </Button>
                    )}
                </Modal>

                {isSuperAdmin && (
                    <>
                        <div className={styles.rowTags}>
                            <Field
                                name="authorized"
                                label="Авторизован"
                                component={CheckBoxInput}
                            />
                            <Field
                                name="deleted"
                                label="Скрыт с карты"
                                component={CheckBoxInput}
                            />
                            <Field
                                name="withGroupWorkoutsOption"
                                label="Разрешить групповые тренировки"
                                component={CheckBoxInput}
                            />
                            <Field
                                name="isHidden"
                                label="Удален из списка"
                                component={CheckBoxInput}
                            />
                            {/* <Field
                            name="withSingleWorkoutsOption"
                            label="Разрешить разовые тренировки"
                            component={CheckBoxInput}
                        /> */}
                        </div>
                        <div className={styles.rowTags2}>
                            <Field
                                name="test"
                                label="Тестовый"
                                component={CheckBoxInput}
                            />
                            <Field
                                name="spirit"
                                label="Spirit"
                                component={CheckBoxInput}
                            />
                            <Field
                                name="seasonal"
                                label="Сезонное"
                                component={CheckBoxInput}
                            />
                        </div>
                    </>
                )}

                <div className={styles.row}>
                    <div className={styles.col}>
                        <Field
                            onFocus={() => {
                                cogoToast.warn(
                                    'Запрещено заполнять название капсом',
                                    {
                                        position: 'top-center',
                                    }
                                );
                            }}
                            disabled={isAdmin}
                            name="title"
                            label="Название"
                            placeholder="Самая лучшая фитнес-площадка"
                            component={TextInput}
                        />
                        <Field
                            disabled={isAdmin}
                            name="hasPassport"
                            label="Нужен паспорт"
                            component={CheckBoxInput}
                        />
                        <Field
                            disabled={isAdmin}
                            name="hasPerMinWorkouts"
                            label="Есть опция поминутных тренировок"
                            component={CheckBoxInput}
                        />
                        {withGroupWorkoutsOption && (
                            <div className={styles.hasGroupWorkouts}>
                                <Field
                                    disabled={isAdmin}
                                    name="hasGroupWorkouts"
                                    label="Есть опция групповых тренировок"
                                    component={CheckBoxInput}
                                    onBlur={() =>
                                        hasGroupWorkouts && showNotification()
                                    }
                                />
                                <span
                                    title="Подсказка про опцию групповых тренировок"
                                    className={styles.info}
                                    onClick={() =>
                                        setTooltips((prevState) => ({
                                            ...prevState,
                                            groupWorkoutsOption: !prevState.groupWorkoutsOption,
                                        }))
                                    }
                                >
                                    ?
                                </span>
                                {tooltips.groupWorkoutsOption && (
                                    <div className={styles.tooltip}>
                                        <p>
                                            Данная функция разблокирует опцию
                                            групповых тренировок в приложении
                                        </p>
                                        <p>
                                            Для того чтобы тренировки появились
                                            нужно заполнить календарь в разделе{' '}
                                            <span
                                                className={styles.tooltipLink}
                                                onClick={() => {
                                                    history.push(
                                                        `/dashboard/group-workouts`
                                                    );
                                                    dispatch(selectGym(gymId));
                                                }}
                                            >
                                                Групповые тренировки
                                            </span>
                                        </p>
                                        <p>
                                            Перед тем как переходить по ссылке
                                            сохраните все изменения на странице
                                        </p>
                                        <div
                                            onClick={() =>
                                                setTooltips((prevState) => ({
                                                    ...prevState,
                                                    groupWorkoutsOption: false,
                                                }))
                                            }
                                        >
                                            <Delete
                                                className={styles.tooltipClose}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                        {/* {withSingleWorkoutsOption && (
                        <Field
                            disabled={isAdmin}
                            name="hasSingleWorkouts"
                            label="Есть опция разовых тренировок"
                            component={CheckBoxInput}
                        />
                    )} */}

                        {withGroupWorkoutsOption && hasGroupWorkouts && (
                            <div className={styles.YClientsSection}>
                                <div className={styles.YClientsField}>
                                    <Field
                                        disabled={isAdmin}
                                        maxLength="30"
                                        name="yclientsId"
                                        placeholder="ID компании в системе YCLIENTS"
                                        label="YCLIENTS ID"
                                        component={TextInput}
                                    />
                                    <span
                                        title="Подсказка про опцию групповых тренировок"
                                        className={styles.info}
                                        onClick={() =>
                                            setTooltips((prevState) => ({
                                                ...prevState,
                                                yclientsId: !prevState.yclientsId,
                                            }))
                                        }
                                    >
                                        ?
                                    </span>
                                    {tooltips.yclientsId && (
                                        <div className={styles.tooltip}>
                                            <p>
                                                Для того, чтобы найти ID
                                                компании, в системе YCLIENTS
                                                выберите в боковом меню кнопку
                                                Обзор и далее Сводка
                                            </p>
                                            <p>
                                                Скопируйте номер, показанный в
                                                поле Идентификатор филиала (ID)
                                                без символа #
                                            </p>
                                            <p>
                                                Если ваша компания не является
                                                партнером YCLIENTS, просто
                                                оставьте данное поле пустым.
                                            </p>
                                            <div
                                                onClick={() =>
                                                    setTooltips(
                                                        (prevState) => ({
                                                            ...prevState,
                                                            yclientsId: false,
                                                        })
                                                    )
                                                }
                                            >
                                                <Delete
                                                    className={
                                                        styles.tooltipClose
                                                    }
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>

                                <Field
                                    disabled={isAdmin || !yClientsIdValueValid}
                                    min="0"
                                    max="100"
                                    name="discountFromYclientsPrice"
                                    placeholder="Скидка"
                                    label="% скидки от цены YCLIENTS"
                                    component={TextInput}
                                />
                                <Field
                                    disabled={isAdmin || !yClientsIdValueValid}
                                    min="0"
                                    max="100"
                                    name="prepaymentPercentFromYclientsPrice"
                                    placeholder="Предоплата"
                                    label="% предоплаты"
                                    component={TextInput}
                                />
                            </div>
                        )}

                        {isSuperAdmin && (
                            <FieldArray
                                name="workingHours"
                                component={renderWorkingHours}
                            />
                        )}
                        {hasPerMinWorkouts && (
                            <div>
                                <Field
                                    disabled={isAdmin}
                                    min="0"
                                    max="100"
                                    name="fixedPrice"
                                    placeholder="10"
                                    label="Цена (поминутная)"
                                    component={TextInput}
                                />

                                <FieldArray
                                    disabled={isAdmin}
                                    name="specialPrices"
                                    component={renderSpecialPrices}
                                />
                                <Field
                                    disabled={isAdmin}
                                    name="hasMinWorkoutDuration"
                                    label="Есть минимальное время поминутной тренировки"
                                    component={CheckBoxInput}
                                />
                                {hasMinWorkoutDuration && (
                                    <div>
                                        <Field
                                            disabled={isAdmin}
                                            min="0"
                                            max="30"
                                            name="minWorkoutDuration"
                                            placeholder="30"
                                            label="Минимальное время тренироки (в минутах)"
                                            component={TextInput}
                                        />
                                    </div>
                                )}
                            </div>
                        )}
                        {/* {hasSingleWorkouts && (
                        <div>
                            <div>
                                <h3>Будние дни:</h3>
                                <h4>Обычная цена:</h4>
                                <div className={styles.inputListWrapper}>
                                    <Field
                                        required
                                        disabled={isAdmin}
                                        convertToString
                                        name="singleWorkoutPriceInfo.weekDayStartTime"
                                        label="Начало"
                                        placeholder="10:00"
                                        className={styles.inputWrapper}
                                        component={TimeInput}
                                    />
                                    <Field
                                        required
                                        disabled={isAdmin}
                                        convertToString
                                        name="singleWorkoutPriceInfo.weekDayFinishTime"
                                        label="Конец"
                                        placeholder="11:00"
                                        className={styles.inputWrapper}
                                        component={TimeInput}
                                    />
                                    <Field
                                        required
                                        disabled={isAdmin}
                                        name="singleWorkoutPriceInfo.weekDayPrice"
                                        placeholder="2000"
                                        label="Цена"
                                        className={styles.inputWrapper}
                                        component={TextInput}
                                    />
                                </div>
                                <Field
                                    disabled={isAdmin}
                                    name="hasWeekDayPrimeTime"
                                    label="Во время низкой нагрузки цена отличается?"
                                    component={CheckBoxInput}
                                />
                                {hasWeekDayPrimeTime && (
                                    <div className={styles.inputListWrapper}>
                                        <Field
                                            required
                                            disabled={isAdmin}
                                            convertToString
                                            name="singleWorkoutPriceInfo.weekDayOffPeakStartTime"
                                            label="Начало"
                                            placeholder="10:00"
                                            className={styles.inputWrapper}
                                            component={TimeInput}
                                        />
                                        <Field
                                            required
                                            disabled={isAdmin}
                                            convertToString
                                            name="singleWorkoutPriceInfo.weekDayOffPeakFinishTime"
                                            label="Конец"
                                            placeholder="11:00"
                                            className={styles.inputWrapper}
                                            component={TimeInput}
                                        />
                                        <Field
                                            required
                                            disabled={isAdmin}
                                            name="singleWorkoutPriceInfo.weekDayOffPeakPrice"
                                            placeholder="1500"
                                            label="Цена"
                                            className={styles.inputWrapper}
                                            component={TextInput}
                                        />
                                    </div>
                                )}
                            </div>
                            <div>
                                <h3>Выходные дни:</h3>
                                <h4>Обычная цена:</h4>
                                <div className={styles.inputListWrapper}>
                                    <Field
                                        required
                                        convertToString
                                        disabled={isAdmin}
                                        name="singleWorkoutPriceInfo.weekendStartTime"
                                        label="Начало"
                                        placeholder="10:00"
                                        className={styles.inputWrapper}
                                        component={TimeInput}
                                    />
                                    <Field
                                        required
                                        convertToString
                                        disabled={isAdmin}
                                        name="singleWorkoutPriceInfo.weekendFinishTime"
                                        label="Конец"
                                        placeholder="11:00"
                                        className={styles.inputWrapper}
                                        component={TimeInput}
                                    />
                                    <Field
                                        required
                                        disabled={isAdmin}
                                        name="singleWorkoutPriceInfo.weekendPrice"
                                        placeholder="2000"
                                        label="Цена"
                                        className={styles.inputWrapper}
                                        component={TextInput}
                                    />
                                </div>

                                <Field
                                    disabled={isAdmin}
                                    name="hasWeekendPrimeTime"
                                    label="Во время низкой нагрузки цена отличается?"
                                    component={CheckBoxInput}
                                />
                                {hasWeekendPrimeTime && (
                                    <div className={styles.inputListWrapper}>
                                        <Field
                                            required
                                            convertToString
                                            disabled={isAdmin}
                                            name="singleWorkoutPriceInfo.weekendOffPeakStartTime"
                                            label="Начало"
                                            placeholder="10:00"
                                            className={styles.inputWrapper}
                                            component={TimeInput}
                                        />
                                        <Field
                                            required
                                            convertToString
                                            disabled={isAdmin}
                                            name="singleWorkoutPriceInfo.weekendOffPeakFinishTime"
                                            label="Конец"
                                            placeholder="11:00"
                                            className={styles.inputWrapper}
                                            component={TimeInput}
                                        />
                                        <Field
                                            required
                                            disabled={isAdmin}
                                            name="singleWorkoutPriceInfo.weekendOffPeakPrice"
                                            placeholder="1500"
                                            label="Цена"
                                            className={styles.inputWrapper}
                                            component={TextInput}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    )} */}
                        <Field
                            onFocus={() => {
                                cogoToast.warn(
                                    'Запрещено заполнять описание капсом',
                                    {
                                        position: 'top-center',
                                    }
                                );
                            }}
                            disabled={isAdmin}
                            name="description"
                            label="Описание"
                            placeholder="Лучшее, что было с вашим телом"
                            component={TextAreaInput}
                        />
                        {/* <Field
                        name="descriptionLong"
                        label="Услуги"
                        placeholder="Лучшее, что было с вашим телом. Действительно, это лучшее, что было с вашим телом. Наверное, это лучшее, что случилось в вашей жизни. Самый лучший зал открывает для вас свои двери. Тренируйтесь как в последний раз. До конца, до упора. С нами вы забудете, что такое спокойная тренировка. Самый лучший зал не только открывает двери, но и размывает границы болевого порога для всех желающих."
                        component={TextAreaInput}
                    /> */}
                        <FieldArray
                            name="subwayRoutes"
                            component={renderSubwayRoutes}
                        />
                    </div>

                    <div className={styles.col}>
                        <div>
                            <div className={styles.tags}>
                                <Field
                                    disabled={isAdmin}
                                    name="tags.crossfit"
                                    label="Кроссфит"
                                    component={CheckBoxInput}
                                />
                                <Field
                                    disabled={isAdmin}
                                    name="tags.yoga"
                                    label="Йога"
                                    component={CheckBoxInput}
                                />
                                <Field
                                    disabled={isAdmin}
                                    name="tags.pilates"
                                    label="Пилатес"
                                    component={CheckBoxInput}
                                />
                            </div>
                            <div className={styles.tags}>
                                <Field
                                    disabled={isAdmin}
                                    name="tags.combatZone"
                                    label="Зона единоборств/Бокс"
                                    component={CheckBoxInput}
                                />
                                <Field
                                    disabled={isAdmin}
                                    name="tags.stretching"
                                    label="Стретчинг"
                                    component={CheckBoxInput}
                                />
                                <Field
                                    disabled={isAdmin}
                                    name="tags.finnishSauna"
                                    label="Финская сауна"
                                    component={CheckBoxInput}
                                />
                            </div>

                            <div className={styles.tags}>
                                <Field
                                    disabled={isAdmin}
                                    name="tags.turkishHammam"
                                    label="Турецкий хаммам"
                                    component={CheckBoxInput}
                                />
                                <Field
                                    disabled={isAdmin}
                                    name="tags.fitnessBar"
                                    label="Фитнес-бар"
                                    component={CheckBoxInput}
                                />
                                <Field
                                    disabled={isAdmin}
                                    name="tags.swimmingPool"
                                    label="Бассейн"
                                    component={CheckBoxInput}
                                />
                            </div>
                            <div className={styles.tags}>
                                <Field
                                    disabled={isAdmin}
                                    name="tags.parking"
                                    label="Парковка"
                                    component={CheckBoxInput}
                                />
                                <Field
                                    disabled={isAdmin}
                                    name="tags.childrenRoom"
                                    label="Детская комната"
                                    component={CheckBoxInput}
                                />
                                <Field
                                    disabled={isAdmin}
                                    name="tags.towels"
                                    label="Полотенца"
                                    component={CheckBoxInput}
                                />
                            </div>
                            <div className={styles.tags}>
                                <Field
                                    disabled={isAdmin}
                                    name="tags.drinkingWater"
                                    label="Питьевая вода"
                                    component={CheckBoxInput}
                                />
                                <Field
                                    disabled={isAdmin}
                                    name="tags.cardioEquipment"
                                    label="Кардиотренажеры"
                                    component={CheckBoxInput}
                                />
                                <Field
                                    disabled={isAdmin}
                                    name="tags.shower"
                                    label="Душ"
                                    component={CheckBoxInput}
                                />
                            </div>

                            <Field
                                required
                                disabled={isAdmin}
                                name="city"
                                label="Город"
                                placeholder="Город"
                                component={CityInput}
                            />

                            <Field
                                required
                                disabled={isAdmin}
                                name="geo"
                                label="Адрес"
                                placeholder="Адрес"
                                component={GeoInputYandex}
                            />

                            <Field
                                disabled={isAdmin}
                                name="social.telegram"
                                label="Telegram (только ID)"
                                placeholder="gogym_msk"
                                component={TextInput}
                            />

                            <Field
                                disabled={isAdmin}
                                name="social.vk"
                                label="VK (только ID)"
                                placeholder="gogym.fit"
                                component={TextInput}
                            />

                            <Field
                                disabled={isAdmin}
                                name="website"
                                label="Ссылка на сайт"
                                placeholder="https://website.ru"
                                component={TextInput}
                            />

                            <Field
                                disabled={isAdmin}
                                name="phone"
                                label="Телефон площадки"
                                placeholder="+7"
                                type="tel"
                                {...(createTextMask({
                                    pattern: '+7 (999) 999-99-99',
                                    allowEmpty: true,
                                    guide: false,
                                    stripMask: false,
                                }) as any)}
                                component={TextInput}
                            />

                            {isSuperAdmin && (
                                <>
                                    <div className={styles.coll}>
                                        <Field
                                            name="legalEntityId"
                                            label="Юридическое лицо"
                                            component={SelectInput}
                                            options={legalEntitysOption}
                                        />
                                        {addLegalEntity ? (
                                            <div
                                                title="Удалить юридическое лицо"
                                                className={styles.wrap}
                                                onClick={() =>
                                                    setAddLegalEntity(false)
                                                }
                                            >
                                                <Delete
                                                    className={styles.iconDel}
                                                />
                                            </div>
                                        ) : (
                                            <div
                                                title="Добавить юридическое лицо"
                                                className={styles.wrap}
                                                onClick={() =>
                                                    setAddLegalEntity(true)
                                                }
                                            >
                                                <Added
                                                    className={styles.icon}
                                                />
                                            </div>
                                        )}
                                    </div>

                                    {addLegalEntity && isSuperAdmin && (
                                        <form>
                                            <label className={styles.label}>
                                                Юридические лица:
                                            </label>

                                            <select
                                                className={styles.select}
                                                ref={gymLegalEntityIdField}
                                            >
                                                <option value="">
                                                    Выберите юридическое лицо
                                                </option>
                                                {foundLegalEntitys
                                                    ? foundLegalEntitys.map(
                                                          (
                                                              legalEntity: {
                                                                  title: string;
                                                                  value: string;
                                                              },
                                                              i: number
                                                          ) => (
                                                              <option
                                                                  key={i}
                                                                  value={
                                                                      legalEntity.value
                                                                  }
                                                              >
                                                                  {
                                                                      legalEntity.title
                                                                  }
                                                              </option>
                                                          )
                                                      )
                                                    : legalEntitysOptions.map(
                                                          (legalEntity, i) => (
                                                              <option
                                                                  key={i}
                                                                  value={
                                                                      legalEntity.value
                                                                  }
                                                              >
                                                                  {
                                                                      legalEntity.title
                                                                  }
                                                              </option>
                                                          )
                                                      )}
                                            </select>

                                            <input
                                                className={styles.input}
                                                type="search"
                                                placeholder="Поиск по названию юридического лица"
                                                ref={gymSearchingField}
                                                onChange={
                                                    handleSearchlegalEntitys
                                                }
                                            />
                                            <Button
                                                onClick={() =>
                                                    onUpdateLegalEntityGym(
                                                        gymLegalEntityIdField
                                                            .current.value
                                                    )
                                                }
                                                type="button"
                                                className={styles.btnManager}
                                            >
                                                Добавить
                                            </Button>
                                        </form>
                                    )}
                                </>
                            )}
                            {isSuperAdmin && managersOptions && (
                                <div className={styles.coll}>
                                    <Field
                                        name="managersId"
                                        label="Менеджер"
                                        component={SelectInput}
                                        options={managersOptions}
                                        emptyTitle={'Все менеджеры...'}
                                    />
                                    {addManager ? (
                                        <div
                                            title="Удалить менеджера"
                                            className={styles.wrap}
                                            onClick={() => setAddManager(false)}
                                        >
                                            <Delete
                                                className={styles.iconDel}
                                            />
                                        </div>
                                    ) : (
                                        <div
                                            title="Добавить менеджера"
                                            className={styles.wrap}
                                            onClick={() => setAddManager(true)}
                                        >
                                            <Added className={styles.icon} />
                                        </div>
                                    )}
                                </div>
                            )}
                            {isSuperAdmin && addManager && (
                                <>
                                    {foundManager ? (
                                        <Field
                                            name="managersIds"
                                            label="Все менеджеры"
                                            component={MultiSelectInput}
                                            options={foundManager}
                                            defaultValue={''}
                                        />
                                    ) : (
                                        <Field
                                            name="managersIds"
                                            label="Все менеджеры"
                                            component={MultiSelectInput}
                                            options={AllManagersOptions}
                                            defaultValue={''}
                                        />
                                    )}
                                    <>
                                        <input
                                            className={styles.input}
                                            type="search"
                                            placeholder="Поиск по имени и email"
                                            ref={managerSearchingField}
                                            onChange={handleSearchManagers}
                                        />
                                    </>
                                    <Button
                                        onClick={() =>
                                            onUpdateManagerGym(managersIds)
                                        }
                                        type="button"
                                        className={styles.btnManager}
                                    >
                                        Добавить
                                    </Button>
                                </>
                            )}
                        </div>
                    </div>
                </div>
                <div className={styles.row}>
                    <Button type="submit" className={styles.btn}>
                        Сохранить
                    </Button>

                    {isSuperAdmin && (
                        <Button
                            onClick={() => setModalActive(true)}
                            type="button"
                            className={styles.btn}
                        >
                            Удалить
                        </Button>
                    )}
                </div>
            </form>
        );
    }
);

export default reduxForm<FormData, Props>({
    form: 'updateGym',
    validate,
})(Form);
