import {
    FormikProps,
    ErrorMessage,
    Field,
    FieldArrayRenderProps,
    FieldArray,
} from 'formik';
import * as React from 'react';
import classnames from 'classnames';
import RSSelect from 'react-select';

import { SelectOption } from 'interfaces/SelectOption';
import { AddGymFormValues } from 'interfaces/AddGymFormValues';

import styles from './styles.module.css';
import { Select } from '@components/Select';

type FieldProps = React.ComponentProps<typeof Field>;
type SelectProps = React.ComponentProps<typeof RSSelect>;

type TFieldsArrayRender<T> = (
    arrayHelpers: FieldArrayRenderProps
) => JSX.Element | null;

type TOnSelectChange = (
    e: (SelectOption | null) | SelectOption[],
    ...args: unknown[]
) => void;
type SelectPropsOmittalbe =
    | 'onChange'
    | 'options'
    | 'placeholder'
    | 'value'
    | 'disabled';
export interface FormSectionRowBaseProps<T> {
    name: string;
    label: string;
    disabled?: boolean;
    placeholder?: string;
    isError?: boolean;
    className?: string;
    gutterBottom?: boolean;
    rightAdornment?: React.ReactNode;
    rightAdornmentContainerStyle?: React.CSSProperties;
    type: 'checkbox' | 'input' | 'select' | 'fieldArray';
    value?: any;
    onSelectChange?: TOnSelectChange;
    style?: React.CSSProperties;
    renderErrorNode?: (errors: TFormikErrors<T>) => JSX.Element | void;
    options?: SelectProps['options'];
    noOptionsMessage?: string;
    selectProps?: Omit<SelectProps, SelectPropsOmittalbe>;
    otherProps?: Record<string, unknown>;
    render?: TFieldsArrayRender<T>;
}
export interface FormSectionRowSelectProps<T>
    extends FormSectionRowBaseProps<T> {
    type: 'select';
    options: SelectProps['options'];
    value: SelectProps['value'];
    noOptionsMessage: string;
    onSelectChange: TOnSelectChange;
    selectProps: Omit<SelectProps, SelectPropsOmittalbe>;
    render?: never;
}
export interface FormSectionRowFieldArrayProps<T>
    extends Pick<
        FormSectionRowBaseProps<T>,
        | 'name'
        | 'render'
        | 'style'
        | 'rightAdornment'
        | 'rightAdornmentContainerStyle'
        | 'gutterBottom'
        | 'isError'
    > {
    type: 'fieldArray';
    render: TFieldsArrayRender<T>;
    label?: never;
    disabled?: never;
    placeholder?: never;
    className?: never;
    value?: never;
    options?: never;
    noOptionsMessage?: never;
    onSelectChange?: never;
    selectProps?: never;
    otherProps?: never;
    renderErrorNode?: never;
}
type FormSectionRowProps<T> =
    | FormSectionRowSelectProps<T>
    | FormSectionRowFieldArrayProps<T>
    | FormSectionRowBaseProps<T>;
export type TFormSectionRow<T> =
    | FormSectionRowProps<T>
    | boolean
    | {
          node: React.ReactNode;
      };

export type TFormikSetFieldValue<T> = FormikProps<T>['setFieldValue'];
export interface GetRowsConfigProps {
    values: AddGymFormValues;
    errors: TFormikErrors<AddGymFormValues>;
    setFieldValue: TFormikSetFieldValue<AddGymFormValues>;
}
export type TFormikErrors<T> = FormikProps<T>['errors'];
type TFormSectionRowProps<T> = TFormSectionRow<T>[];
export interface FormSectionProps<T> {
    title?: string;
    subtitle?: React.ReactNode | string;
    subtitleStyle?: React.CSSProperties;
    containerStyle?: React.CSSProperties;
    withDivider?: boolean;
    rows: TFormSectionRowProps<T>[];
    rowStyles?: Record<string, React.CSSProperties>;
    setFieldValue: TFormikSetFieldValue<T>;
    errors: TFormikErrors<T>;
    handleRef?: (ref: HTMLDivElement) => void;
}

const handleRef = (cb?: (ref: HTMLDivElement) => void) => (
    ref: HTMLDivElement
) => {
    if (ref) {
        cb?.(ref);
    }
};
const _FormSection = <T,>({
    title,
    subtitle,
    subtitleStyle,
    containerStyle,
    rows,
    rowStyles,
    withDivider,
    setFieldValue,
    errors,
    handleRef: _handleRef,
}: FormSectionProps<T>) => {
    return (
        <div
            ref={handleRef(_handleRef)}
            className={classnames({
                [styles.withRowDivider]: withDivider,
            })}
            style={containerStyle}
        >
            {title && <h3 className={styles.formSectionTitle}>{title}</h3>}
            {subtitle && (
                <div
                    className={styles.formSectionSubtitle}
                    style={subtitleStyle}
                >
                    {subtitle}
                </div>
            )}
            {rows.filter(Boolean).map((row, index) => (
                <div
                    key={['row', index].join('_')}
                    className={styles.sectionRow}
                    style={rowStyles?.[index]}
                >
                    {row.filter(Boolean).map((element, index) => {
                        const elementAsCustomNode = (element as unknown) as {
                            node: React.ReactNode;
                        };

                        if (elementAsCustomNode?.node) {
                            return elementAsCustomNode.node;
                        }

                        const {
                            name,
                            label,
                            disabled,
                            placeholder,
                            type = 'input',
                            className,
                            gutterBottom,
                            value,
                            style,
                            options,
                            noOptionsMessage,
                            onSelectChange,
                            selectProps,
                            render,
                            rightAdornment = null,
                            rightAdornmentContainerStyle,
                            isError,
                            otherProps = {},
                            renderErrorNode = () => null,
                        } = (element as unknown) as FormSectionRowProps<T>;

                        return (
                            <div
                                key={['column', index].join('_')}
                                className={classnames(styles.formRowElement, {
                                    [styles.gutterBottom]: gutterBottom,
                                })}
                                style={style}
                            >
                                <label
                                    htmlFor={name}
                                    className={classnames(
                                        {
                                            [styles.gymLabel]: type === 'input',
                                            [styles.checkboxLabel]:
                                                type === 'checkbox',
                                            [styles.selectLabel]:
                                                type === 'select',
                                        },
                                        className
                                    )}
                                >
                                    {['input'].includes(type) && label && label}
                                    {['input', 'checkbox'].includes(type) ? (
                                        <Field
                                            disabled={disabled}
                                            className={classnames({
                                                [styles.gymTitle]:
                                                    type === 'input',
                                                [styles.checkbox]:
                                                    type === 'checkbox',
                                            })}
                                            id={name}
                                            name={name}
                                            placeholder={placeholder}
                                            type={type}
                                            setFieldValue={setFieldValue}
                                            {...otherProps}
                                        />
                                    ) : type === 'fieldArray' ? (
                                        <FieldArray
                                            name={name}
                                            render={render}
                                        />
                                    ) : (
                                        <Select
                                            isDisabled={disabled}
                                            value={value}
                                            options={
                                                (options as unknown) as SelectOption[]
                                            }
                                            label={label}
                                            onChange={onSelectChange}
                                            noOptionsMessage={() =>
                                                noOptionsMessage
                                            }
                                            placeholder={placeholder}
                                            {...selectProps}
                                            {...otherProps}
                                        />
                                    )}
                                    {type === 'checkbox' && label}
                                    {renderErrorNode(errors) ?? null}
                                    {isError && (
                                        <ErrorMessage
                                            className={styles.error}
                                            name={name}
                                            component="div"
                                        />
                                    )}
                                </label>
                                {rightAdornment && (
                                    <div
                                        className={styles.rightAdornment}
                                        style={rightAdornmentContainerStyle}
                                    >
                                        {rightAdornment}
                                    </div>
                                )}
                            </div>
                        );
                    })}
                </div>
            ))}
        </div>
    );
};

export const FormSection = React.memo(_FormSection) as <T>(
    props: FormSectionProps<T>
) => JSX.Element;
