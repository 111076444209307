import React, { useCallback, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import cogoToast from 'cogo-toast';

import { AsyncDispatch } from '@redux/types';
import { handleCreateLegalEntity } from '@redux/modules/legal-entity/actions';
import BackButton from '@components/BackButton';

import Form, { FormData } from './Form';
import styles from './styles.module.css';

const MAX_LENGTH = 3;
const MAX_SIZE = 20000000;

export const AddLegalEntity: React.FC = () => {
    const dispatch = useDispatch<AsyncDispatch>();
    const history = useHistory();
    const fileInput = useRef<null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const onSubmit = useCallback(
        (values: FormData) => {
            const files = (fileInput.current || { files: new FileList() })
                .files;
            setIsLoading(true);
            dispatch(handleCreateLegalEntity({ values, files }))
                .then((legalEntity) => {
                    if (legalEntity) {
                        cogoToast.success('Юридическое лицо создано', {
                            position: 'top-right',
                            hideAfter: 5,
                        });
                        history.push('/dashboard/legalentity');
                    }
                })
                .catch(() => {
                    cogoToast.error('Ошибка при создании юридического лица', {
                        position: 'top-right',
                        hideAfter: 4,
                    });
                })
                .finally(() => setIsLoading(false));
        },
        [dispatch, history]
    );

    const uploadMultipleFiles = (event: any) => {
        const numOfFiles = Array.from(event.target.files).length;
        if (!numOfFiles) {
            event.preventDefault();
            cogoToast.error(`Приложите файлы для загрузки`, {
                position: 'top-right',
                hideAfter: 4,
            });
            event.target.value = null;
            return;
        }
        if (numOfFiles > MAX_LENGTH) {
            event.preventDefault();
            cogoToast.error(`Нельзя загрузить более ${MAX_LENGTH} файлов`, {
                position: 'top-right',
                hideAfter: 4,
            });
            event.target.value = null;
            return;
        }
        for (let i = 0; i < numOfFiles; ++i) {
            const validExtensions = ['pdf'];
            const fileExtension = event.target.files[i].type.split('/')[1];
            const fileSize = event.target.files[i].size;

            if (!validExtensions.includes(fileExtension)) {
                event.preventDefault();
                cogoToast.error(`Можно загрузить файлы с расширением pdf`, {
                    position: 'top-right',
                    hideAfter: 4,
                });
                event.target.value = null;
                return;
            }

            if (fileSize > MAX_SIZE) {
                event.preventDefault();
                cogoToast.error(
                    `Нельзя загрузить файл размера более 20MB, файл "${event.target.files[i].name}" слишком большой`,
                    {
                        position: 'top-right',
                        hideAfter: 4,
                    }
                );
                event.target.value = null;
                return;
            }
        }
    };

    return (
        <div className={styles.container}>
            {isLoading ? (
                <div>Загрузка...</div>
            ) : (
                <>
                    <h1>Добавить новое юридическое лицо</h1>

                    <BackButton
                        title="К списку юридических лиц"
                        className={styles.backBtn}
                    />

                    <Form
                        onSubmit={onSubmit}
                        fileInput={fileInput}
                        uploadMultipleFiles={uploadMultipleFiles}
                    />
                </>
            )}
        </div>
    );
};

export default AddLegalEntity;
