import axios from 'axios';
import qs from 'qs';

import { User } from '@t/user';
import { AdCampaign, Coupon, CouponType } from '@t/coupon';
import { B2B_API_URL } from '@config';
import { AsyncActionCreator } from '@redux/types';
import { CouponsFormData } from '@containers/Dashboard/CouponsDetails/Form';

import {
    FETCH_COUPONS_REQUEST,
    FETCH_COUPONS_SUCCESS,
    FETCH_COUPONS_ERROR,
    FETCH_COUPON_REQUEST,
    FETCH_COUPON_SUCCESS,
    FETCH_COUPON_ERROR,
    CREATE_COUPON_REQUEST,
    CREATE_COUPON_SUCCESS,
    CREATE_COUPON_ERROR,
    DELETE_COUPON_REQUEST,
    DELETE_COUPON_SUCCESS,
    DELETE_COUPON_ERROR,
    UPDATE_COUPON_REQUEST,
    UPDATE_COUPON_SUCCESS,
    UPDATE_COUPON_ERROR,
    FETCH_COUPONS_AD_CAMPAIGNS_REQUEST,
    FETCH_COUPONS_AD_CAMPAIGNS_SUCCESS,
    FETCH_COUPONS_AD_CAMPAIGNS_ERROR,
    FETCH_SCROLL_COUPONS_REQUEST,
    FETCH_SCROLL_COUPONS_SUCCESS,
    FETCH_SCROLL_COUPONS_ERROR,
} from './types';

export const fetchCoupons: AsyncActionCreator<
    {
        adCampaignId: string;
        couponCount: number;
    },
    Coupon[]
> = ({ adCampaignId, couponCount }) => async (dispatch, getState) => {
    dispatch({
        type: FETCH_COUPONS_REQUEST,
    });

    const {
        auth: { token },
    } = getState();
    try {
        const { data: coupons } = await axios.get(`${B2B_API_URL}/coupons`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {
                adCampaignId,
                couponCount,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: 'repeat' });
            },
        });

        dispatch({
            type: FETCH_COUPONS_SUCCESS,
            payload: coupons,
        });

        return coupons;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: FETCH_COUPONS_ERROR,
            });

            throw err;
        }
    }
};

export const fetchScrollCoupons: AsyncActionCreator<
    {
        adCampaignId: string;
        couponCount: number;
    },
    Coupon[]
> = ({ adCampaignId, couponCount }) => async (dispatch, getState) => {
    dispatch({
        type: FETCH_SCROLL_COUPONS_REQUEST,
    });

    const {
        auth: { token },
    } = getState();
    try {
        const { data: coupons } = await axios.get(`${B2B_API_URL}/coupons`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {
                adCampaignId,
                couponCount,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: 'repeat' });
            },
        });

        dispatch({
            type: FETCH_SCROLL_COUPONS_SUCCESS,
            payload: coupons,
        });

        return coupons;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: FETCH_SCROLL_COUPONS_ERROR,
            });

            throw err;
        }
    }
};

export const fetchCoupon: AsyncActionCreator<string> = (id?: string) => async (
    dispatch,
    getState
) => {
    dispatch({
        type: FETCH_COUPON_REQUEST,
    });

    const {
        auth: { token },
    } = getState();

    try {
        const { data: coupon } = await axios.get(
            `${B2B_API_URL}/coupons/${id}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        dispatch({
            type: FETCH_COUPON_SUCCESS,
            payload: coupon,
        });

        return coupon;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: FETCH_COUPON_ERROR,
            });

            throw err;
        }
    }
};

export const createCoupon: AsyncActionCreator<CouponsFormData, Coupon> = (
    values: CouponsFormData
) => async (dispatch, getState) => {
    dispatch({
        type: CREATE_COUPON_REQUEST,
    });

    const {
        auth: { token },
    } = getState();

    const newValues = {
        ...values,
        discount: +values.discount,
        activatedCount: +values.activatedCount,
        limitPerUser: +values.limitPerUser,
        initialPrice: +values.initialPrice,
    };

    try {
        const { data: coupon } = await axios.post(
            `${B2B_API_URL}/coupons`,
            newValues,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        dispatch({
            type: CREATE_COUPON_SUCCESS,
            payload: coupon,
        });

        return coupon;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: CREATE_COUPON_ERROR,
            });

            throw err;
        }
    }
};

export const createCouponForGroupWorkouts: AsyncActionCreator<
    CouponsFormData,
    Coupon
> = (values: CouponsFormData) => async (dispatch, getState) => {
    dispatch({
        type: CREATE_COUPON_REQUEST,
    });

    const {
        auth: { token },
    } = getState();

    const newValues = {
        ...values,
        discount: +values.discount,
        activatedCount: +values.activatedCount,
        limitPerUser: +values.limitPerUser,
        initialPrice: +values.initialPrice,
    };
    if (values.couponType === CouponType.amount && values.initialPrice) {
        newValues.discount = +values.initialPrice;
    }
    if (!newValues.unlimited) {
        newValues.unlimitedPerUser = false;
        newValues.limitPerUser = 1;
        newValues.unlimitedUsersCount = false;
        newValues.activatedCount = 1;
    }
    if (newValues.unlimited) {
        newValues.unlimitedPerUser = false;
        newValues.unlimitedUsersCount = false;
    }

    try {
        const { data: coupon } = await axios.post(
            `${B2B_API_URL}/coupons-for-groupworkouts`,
            newValues,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        dispatch({
            type: CREATE_COUPON_SUCCESS,
            payload: coupon,
        });

        return coupon;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: CREATE_COUPON_ERROR,
            });

            throw err;
        }
    }
};

export const createCoupons: AsyncActionCreator<CouponsFormData, Coupon> = (
    values: CouponsFormData
) => async (dispatch, getState) => {
    dispatch({
        type: CREATE_COUPON_REQUEST,
    });

    const {
        auth: { token },
    } = getState();

    const newValues = {
        ...values,
        discount: +values.discount,
        activatedCount: +values.activatedCount,
        limitPerUser: +values.limitPerUser,
        initialPrice: +values.initialPrice,
    };
    if (values.couponType === CouponType.amount && values.initialPrice) {
        newValues.discount = +values.initialPrice;
    }
    if (!newValues.unlimited) {
        newValues.unlimitedPerUser = false;
        newValues.limitPerUser = 1;
        newValues.unlimitedUsersCount = false;
        newValues.activatedCount = 1;
    }
    if (newValues.unlimited) {
        newValues.unlimitedPerUser = true;
        newValues.unlimitedUsersCount = true;
    }

    try {
        const { data: coupons } = await axios.post(
            `${B2B_API_URL}/coupons/add-coupons`,
            newValues,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        return coupons;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: CREATE_COUPON_ERROR,
            });

            throw err;
        }
    }
};

export const deleteCoupon: AsyncActionCreator<string> = (id: string) => async (
    dispatch,
    getState
) => {
    dispatch({
        type: DELETE_COUPON_REQUEST,
    });

    const {
        auth: { token },
    } = getState();

    try {
        const { data: coupon } = await axios.delete(
            `${B2B_API_URL}/coupons/${id}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        dispatch({
            type: DELETE_COUPON_SUCCESS,
            payload: coupon,
        });

        return coupon;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: DELETE_COUPON_ERROR,
            });

            throw err;
        }
    }
};

export const updateCoupon: AsyncActionCreator<
    { id: string; values: CouponsFormData },
    User
> = ({ id, values }) => async (dispatch, getState) => {
    dispatch({
        type: UPDATE_COUPON_REQUEST,
    });

    const {
        auth: { token },
    } = getState();

    const newValues = { ...values };

    try {
        const { data: coupon } = await axios.put(
            `${B2B_API_URL}/coupons/${id}`,
            newValues,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        dispatch({
            type: UPDATE_COUPON_SUCCESS,
            payload: coupon,
        });

        return coupon;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: UPDATE_COUPON_ERROR,
            });

            throw err;
        }
    }
};

export const fetchCouponsAdCampaigns: AsyncActionCreator<
    string | null | void,
    AdCampaign[]
> = () => async (dispatch, getState) => {
    dispatch({
        type: FETCH_COUPONS_AD_CAMPAIGNS_REQUEST,
    });

    const {
        auth: { token },
    } = getState();
    try {
        const { data: couponsAdCampaigns } = await axios.get(
            `${B2B_API_URL}/coupons/ad-campaigns`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        dispatch({
            type: FETCH_COUPONS_AD_CAMPAIGNS_SUCCESS,
            payload: couponsAdCampaigns,
        });

        return couponsAdCampaigns;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: FETCH_COUPONS_AD_CAMPAIGNS_ERROR,
            });

            throw err;
        }
    }
};

export const searchCoupons: AsyncActionCreator<
    { couponName: string },
    Coupon[]
> = ({ couponName }) => async (dispatch, getState) => {
    dispatch({
        type: FETCH_COUPONS_REQUEST,
    });

    const {
        auth: { token },
    } = getState();
    try {
        const { data: coupons } = await axios.get(`${B2B_API_URL}/coupons`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {
                couponName,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: 'repeat' });
            },
        });

        dispatch({
            type: FETCH_COUPONS_SUCCESS,
            payload: coupons,
        });

        return coupons;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: FETCH_COUPONS_ERROR,
            });

            throw err;
        }
    }
};
