import * as React from 'react';
import classnames from 'classnames';
import { Field as RFField } from 'redux-form';

import styles from './styles.module.css';

export const Field = React.memo<
    React.HTMLAttributes<HTMLInputElement> & Record<string, unknown>
>(({ className, ...props }) => {
    return (
        <RFField className={classnames(styles.field, className)} {...props} />
    );
});
